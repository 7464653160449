import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'usehooks-ts'
import type { FilterableColumn } from '../../../components/table-support/types'
import {
    deriveRenderPropertyFromFilterTexts,
    deriveSorterPropertyFromFilterTexts,
    hideNonSelectedColumns
} from '../../../components/table-support/util'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import type { FilteringState } from '../../schedule/components/FilterInput/types'
import type { SubjectRow } from './SubjectsTable/types'

export enum SubjectsColumnKey {
    Name = 0,
    Code = 1
}

export const useSubjectColumns = (filtering: FilteringState) => {
    const [visibleColumns, setVisibleColumns] = useLocalStorage('visibleSubjectColumns', [
        SubjectsColumnKey.Name,
        SubjectsColumnKey.Code
    ])
    const { t } = useTranslation()
    const schedule = useLocalSchedule()
    const columns: FilterableColumn<SubjectRow>[] = [
        {
            title: t('Name'),
            key: SubjectsColumnKey.Name,
            filterTexts: ({ subject }) => [subject.getName()]
        },
        {
            title: t('Code'),
            key: SubjectsColumnKey.Code,
            filterTexts: ({ subject }) => [subject.getCode()]
        }
    ]

    hideNonSelectedColumns(columns, visibleColumns)
    deriveRenderPropertyFromFilterTexts(filtering, columns)
    deriveSorterPropertyFromFilterTexts(columns)

    return { columns, setVisibleColumns }
}
