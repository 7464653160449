import Icon, { HighlightFilled, SearchOutlined } from '@ant-design/icons'
import { Input, Space, Tooltip } from 'antd'
import SvgAa from '../../../../icons/SvgAa'
import SvgSkip from '../../../../icons/SvgSkip'
import { toTranslate } from '../../../../utils/miscUtil'
import type { FilterInputProps } from './types'

const enabledDisabledColor = (isEnabled: boolean) => (isEnabled ? '#777' : '#ccc')

export const FilterInput = ({
    filterHighlighting,
    setFilterHighlighting,
    caseSensitive,
    setCaseSensitive,
    subseq,
    setSubseq,
    filterText,
    setFilterText,
    disabled = false
}: FilterInputProps & { disabled?: boolean }) => (
    <Input
        allowClear
        disabled={disabled}
        autoFocus
        prefix={<SearchOutlined style={{ color: '#ccc' }} />}
        placeholder={toTranslate('Filtrera')}
        value={filterText}
        suffix={
            <Space>
                {disabled ? (
                    <HighlightFilled style={{ color: enabledDisabledColor(false) }} />
                ) : (
                    <Tooltip title={toTranslate('Markera söktext')}>
                        <HighlightFilled
                            style={{ color: enabledDisabledColor(filterHighlighting) }}
                            onClick={() => setFilterHighlighting(!filterHighlighting)}
                        />
                    </Tooltip>
                )}
                {disabled ? (
                    <Icon component={SvgAa} style={{ color: enabledDisabledColor(false) }} />
                ) : (
                    <Tooltip title={toTranslate('Skilj på stora / små bokstäver')}>
                        <Icon
                            component={SvgAa}
                            style={{ color: enabledDisabledColor(caseSensitive) }}
                            onClick={() => setCaseSensitive(!caseSensitive)}
                        />
                    </Tooltip>
                )}
                {disabled ? (
                    <Icon component={SvgSkip} style={{ color: enabledDisabledColor(false) }} />
                ) : (
                    <Tooltip title={toTranslate('Tillåt överhopp i matchning')}>
                        <Icon
                            component={SvgSkip}
                            style={{ color: enabledDisabledColor(subseq) }}
                            onClick={() => setSubseq(!subseq)}
                        />
                    </Tooltip>
                )}
            </Space>
        }
        onChange={(event) => setFilterText(event.target.value)}
    />
)
