import { Space } from 'antd'
import { CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { IconDropdownMenu } from '../../../IconDropdownMenu'
import { toTranslate } from '../../../../utils/miscUtil'
import { RoomAssignmentMenuProps } from './types'

export const RoomAssignmentMenuDropdown = ({ setShowRequiredAttributesInput, onDelete }: RoomAssignmentMenuProps) => {
    return (
        <IconDropdownMenu
            menuItems={[
                {
                    label: (
                        <Space>
                            <CheckCircleOutlined />
                            <span>{toTranslate('Lägg till nödvändiga salsattribut')}</span>
                        </Space>
                    ),
                    onClick: () => setShowRequiredAttributesInput(true)
                },
                {
                    label: (
                        <Space>
                            <DeleteOutlined />
                            <span>{toTranslate('Ta bort sal')}</span>
                        </Space>
                    ),
                    onClick: onDelete,
                    danger: true
                }
            ]}
        />
    )
}
