import { CourseRoundListTooltipProps } from './types'
import { toTranslate } from '../../../utils/miscUtil'
import { EntityListTooltip } from '../../MultiRoomAttributeSelector/RoomsListTooltip/EntityListTooltip'

export const CourseRoundListTooltip = ({ title, courseRounds }: CourseRoundListTooltipProps) => (
    <EntityListTooltip
        title={title}
        entities={courseRounds}
        entityLabelFn={(cr) => cr.getDisplayName()}
        entityKeyFn={(cr) => cr.getCourseRoundId()}
        singularEntityLabel={toTranslate('kursomgång')}
        pluralEntityLabel={toTranslate('kursomgångar')}
    />
)
