import type { SVGProps } from 'react'
import { toTranslate } from '../utils/miscUtil'

const SvgLunch = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" className="lunch_svg__icon" viewBox="0 0 1024 1024" {...props}>
        <title>{toTranslate('lunch')}</title>
        <path
            d="M888.37 374.469h57.243a6.514 6.514 0 0 1 6.529 6.528v385.1a6.514 6.514 0 0 1-6.529 6.529H888.37a6.514 6.514 0 0 1-6.528-6.529v-385.1a6.514 6.514 0 0 1 6.528-6.528z"
            style={{
                opacity: 1,
                vectorEffect: 'none',
                fill: '#000',
                fillOpacity: 1,
                stroke: 'none',
                strokeWidth: 41.46432877,
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                strokeMiterlimit: 4,
                strokeDasharray: 'none',
                strokeDashoffset: 0,
                strokeOpacity: 1
            }}
        />
        <path
            d="M768.07 527.34c0 130.379-105.692 236.07-236.07 236.07S295.93 657.72 295.93 527.34c0-130.377 105.692-236.07 236.07-236.07s236.07 105.693 236.07 236.07z"
            style={{
                opacity: 1,
                vectorEffect: 'none',
                fill: 'none',
                fillOpacity: 1,
                stroke: '#000',
                strokeWidth: 66.19158936,
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                strokeMiterlimit: 4,
                strokeDasharray: 'none',
                strokeDashoffset: 0,
                strokeOpacity: 1
            }}
        />
        <path
            d="M133.807 264.979c-4.432 0-8 3.568-8 8v51.24h-14.498v-49.62c0-4.431-3.568-8-8-8H73.41c-4.432 0-8 3.569-8 8v79.18c0 .486.05.959.133 1.42a35.801 35.801 0 0 0-.36 5.002v9.973c0 19.934 16.391 35.982 36.75 35.982h12.954V746.46c0 4.432 3.568 8 8 8h54.3c4.433 0 8-3.568 8-8V406.156h11.811c20.36 0 36.75-16.048 36.75-35.982V360.2c0-1.478-.1-2.931-.275-4.361a8.046 8.046 0 0 0 .275-2.06V274.6c0-4.432-3.568-8-8-8h-29.9c-4.432 0-8 3.568-8 8v49.619h-16.143v-51.24c0-4.432-3.568-8-8-8h-29.898z"
            style={{
                opacity: 1,
                vectorEffect: 'none',
                fill: '#000',
                fillOpacity: 1,
                stroke: 'none',
                strokeWidth: 45.90000153,
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                strokeMiterlimit: 4,
                strokeDasharray: 'none',
                strokeDashoffset: 0,
                strokeOpacity: 1
            }}
        />
        <path
            d="M952.142 354.834a54.33 136.193 0 0 1-54.33 136.193 54.33 136.193 0 0 1-54.33-136.193 54.33 136.193 0 0 1 54.33-136.192 54.33 136.193 0 0 1 54.33 136.192Z"
            style={{
                opacity: 1,
                vectorEffect: 'none',
                fill: '#000',
                fillOpacity: 1,
                stroke: 'none',
                strokeWidth: 147.96426392,
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                strokeMiterlimit: 4,
                strokeDasharray: 'none',
                strokeDashoffset: 0,
                strokeOpacity: 1
            }}
        />
    </svg>
)
export default SvgLunch
