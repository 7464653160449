import type { FilterDropdownProps } from 'antd/es/table/interface'
import { FilterableFilterDropdown } from '../../../../components/FilterableFilterDropdown'
import { useSchedule } from '../../../../store/schedule/hooks'
import { toTranslate } from '../../../../utils/miscUtil'

export const RoomAttributeFilterDropdown = (props: FilterDropdownProps) => {
    const schedule = useSchedule()
    const allAttributes = schedule.getRoomAttributes().map((attrib) => ({ value: attrib, text: attrib }))
    return (
        <FilterableFilterDropdown
            {...props}
            allFilters={allAttributes}
            noKeysAvailableDescription={toTranslate('Inga salsattribut')}
        />
    )
}
