import { TeacherActionsPanelProps } from './types'
import { Space } from 'antd'
import { DeleteTeachersButton } from './DeleteTeacherButton'

export const TeacherActionsPanel = (props: TeacherActionsPanelProps) => {
    return (
        <Space direction="vertical">
            <DeleteTeachersButton teacherIds={props.teacherIds} onDelete={props.onDelete} />
        </Space>
    )
}
