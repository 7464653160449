import { Flex } from 'antd'
import styled from '../../../../../utils/styled'
import { ScreenshotImageProps } from './types'

export const ScreenshotImage = (props: ScreenshotImageProps) => (
    <Flex justify="space-around">
        <StyledImage src={props.src} />
    </Flex>
)

const StyledImage = styled.img`
    max-width: 90%;
    box-shadow: 0.2em 0.2em 0.8em #999;
    margin: 2em 0;
`
