import { Space, message } from 'antd'
import { IScheduleTransform } from 'common-api'
import { useDispatch } from 'react-redux'
import type { RoomId } from '../../../commonTypes'
import Button from '../../../components/Button'
import type { RoomAccessor } from '../../../schedule-access/scheduleAccessWrappers'
import { locallyTriggeredScheduleTransform } from '../../../store/schedule/actions'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { toTranslate } from '../../../utils/miscUtil'

type RoomActionsPanelProps = {
    rooms: RoomAccessor[]
    onDelete: () => void
}

export const RoomActionsPanel = (props: RoomActionsPanelProps) => {
    const schedule = useLocalSchedule()
    const dispatch = useDispatch()

    const getLecturesReferencingRoom = (roomId: RoomId) =>
        schedule.getLectures().filter((l) => l.getAssignedRooms().some((r) => r.getRoomId() === roomId))

    const handleDeleteRoom = () => {
        const problematicLectures = props.rooms.map((r) => r.getRoomId()).flatMap(getLecturesReferencingRoom)
        if (problematicLectures.length > 0) {
            message.error(toTranslate(`${problematicLectures.length} lektion(er) refererar till valda salar`))
            return
        }
        props.onDelete()
        dispatch(
            locallyTriggeredScheduleTransform(
                IScheduleTransform.bulkTransform(
                    props.rooms.map((r) =>
                        IScheduleTransform.roomDeleteTransform({
                            roomId: r.getRoomId()
                        })
                    )
                )
            )
        )
        message.success(toTranslate('Sal borttagen'))
    }

    return (
        <Space direction="vertical">
            <Button variant="secondary" onClick={handleDeleteRoom}>
                {toTranslate(props.rooms.length === 1 ? 'Ta bort sal' : 'Ta bort salar')}
            </Button>
        </Space>
    )
}
