import type { IScheduleSettings, IWeekSelectionPreset } from 'common-api'
import { uniq } from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'
import { FIRST_SCHOOL_YEAR_WEEK } from './constants'
import type { Cell } from './types'

/**
 * Generates the cells for a single day. This function is used in the useGenerateCells hook to generate the cells for
 * each day. The moment object is only cloned once per day, and the cells are pushed to the cells array in a single
 * operation
 */
const generateCellsForDay = (date: moment.Moment, presets: IWeekSelectionPreset[], openIsoWeeks: number[]) => {
    const cells: Cell[] = []

    if (date.day() === 1) {
        cells.push({ type: 'MONTH', month: date.month(), rowSpan: 1 }, { type: 'WEEK', week: date.isoWeek() })
    }

    cells.push({ type: 'DAY', date: date.clone() })

    if (date.day() === 0) {
        cells.push(
            { type: 'GAP' },
            ...presets.map(
                (preset) =>
                    ({
                        type: 'WSP_WEEK',
                        wspId: preset.weekSelectionPresetId,
                        week: date.isoWeek(),
                        enabled: openIsoWeeks.includes(date.isoWeek())
                    }) satisfies Cell
            )
        )
    }

    return cells
}

const useGenerateCells = (presets: IWeekSelectionPreset[], settings: IScheduleSettings) => {
    const cells = useMemo(() => {
        const startDate = moment().year(settings.schoolYear).isoWeek(FIRST_SCHOOL_YEAR_WEEK).startOf('isoWeek')
        const endDate = startDate.clone().add(1, 'year').endOf('isoWeek')
        const totalDays = endDate.diff(startDate, 'days')

        const openIsoWeeks = uniq(settings.schoolDays.map((sd) => sd.isoWeek))

        /**
         * Using "map" is more functional and doesn't mutate cells, but might be less efficient because it creates an
         * intermediate array and uses flat, which has a time complexity of O(n). The current code with a for loop and
         * push is more efficient because it only creates the final cells array and has a time complexity of O(1) for
         * each push operation.
         */
        const cells: Cell[] = []
        for (let i = 0; i <= totalDays; i++) {
            const currentDate = startDate.add(i === 0 ? 0 : 1, 'days') // avoid cloning
            cells.push(...generateCellsForDay(currentDate, presets, openIsoWeeks))
        }

        return cells
    }, [presets, settings])

    return cells
}

export { useGenerateCells }
