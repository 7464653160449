import { PlusOutlined } from '@ant-design/icons'
import { IScheduleTransform } from 'common-api'
import { useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'
import { locallyTriggeredScheduleTransform } from '../../../../store/schedule/actions'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { generateFreshName, toTranslate } from '../../../../utils/miscUtil'
import { Button } from 'antd'

export const AddTeacherButton = (props: AddNewTeacherButtonProps) => {
    const dispatch = useDispatch()
    const schedule = useLocalSchedule()

    const freshTeacherSignature = () =>
        generateFreshName(
            'Ny lärare',
            schedule.getTeachers().map((t) => t.getTeacherSchoolId())
        )

    const handleNewTeacher = () => {
        const newTeacherId = uuid()
        dispatch(
            locallyTriggeredScheduleTransform(
                IScheduleTransform.teacherTransform({
                    newTeacher: {
                        teacherId: newTeacherId,
                        teacherSchoolId: freshTeacherSignature(),
                        firstName: '',
                        lastName: '',
                        workPercentage: 100,
                        qualifications: []
                    }
                })
            )
        )
        props.onTeacherCreated(newTeacherId)
    }

    return (
        <Button type="primary" onClick={handleNewTeacher} icon={<PlusOutlined />}>
            {toTranslate('Lägg till lärare')}
        </Button>
    )
}
