import {
    FloatingFocusManager,
    FloatingOverlay,
    FloatingPortal,
    useDismiss,
    useFloating,
    useInteractions,
    useRole,
    useTransitionStatus
} from '@floating-ui/react'
import classNames from 'classnames'
import type { ReactNode } from 'react'
import ModalContent from './ModalContent'
import ModalContext from './context'
import classes from './style.module.css'
import { DURATION } from './utils'

type ModalProps = {
    open: boolean
    onClose: () => void
    className?: string
    overlayClassName?: string
    'aria-label': string
    'aria-description'?: string
    children: ReactNode
}

const Modal = ({
    open,
    onClose,
    className,
    overlayClassName,
    'aria-label': ariaLabel,
    'aria-description': ariaDescription,
    children
}: ModalProps) => {
    const { refs, context } = useFloating({
        open,
        onOpenChange: onClose
    })

    const dismiss = useDismiss(context, { outsidePressEvent: 'click' })

    const role = useRole(context)
    const { getFloatingProps } = useInteractions([dismiss, role])

    const { isMounted, status } = useTransitionStatus(context, {
        duration: DURATION
    })

    if (!isMounted) {
        return null
    }

    return (
        <ModalContext.Provider value={{ onClose }}>
            <FloatingPortal>
                <FloatingOverlay
                    lockScroll
                    className={classNames(classes.overlay, overlayClassName, {
                        [classes['overlay--open']]: status === 'open'
                    })}
                >
                    <FloatingFocusManager context={context}>
                        <div
                            ref={refs.setFloating}
                            className={classNames(classes.modal, className, {
                                [classes['modal--open']]: status === 'open'
                            })}
                            aria-label={ariaLabel}
                            aria-describedby={ariaDescription}
                            {...getFloatingProps()}
                        >
                            {children}
                        </div>
                    </FloatingFocusManager>
                </FloatingOverlay>
            </FloatingPortal>
        </ModalContext.Provider>
    )
}

export default Object.assign(Modal, {
    Content: ModalContent
})
