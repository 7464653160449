import { PlusOutlined } from '@ant-design/icons'
import { IScheduleTransform } from 'common-api'
import { useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'
import type { SubjectId } from '../../../../commonTypes'
import Button from '../../../../components/Button'
import { locallyTriggeredScheduleTransform } from '../../../../store/schedule/actions'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { generateFreshName, toTranslate } from '../../../../utils/miscUtil'

type NewSubjectButtonProps = {
    onNewSubject: (newSubjectId: SubjectId) => void
}

export const NewSubjectButton = (props: NewSubjectButtonProps) => {
    const schedule = useLocalSchedule()
    const dispatch = useDispatch()

    const freshSubjectName = () =>
        generateFreshName(
            'Nytt ämne',
            schedule.getSubjects().map((s) => s.getName())
        )

    const freshSubjectCode = () =>
        generateFreshName(
            'NYTTÄMNE',
            schedule.getSubjects().map((s) => s.getCode())
        )

    const handleNewSubject = () => {
        const newSubjectId = uuid()
        dispatch(
            locallyTriggeredScheduleTransform(
                IScheduleTransform.subjectTransform({
                    newSubject: {
                        subjectId: newSubjectId,
                        code: freshSubjectCode(),
                        name: freshSubjectName()
                    }
                })
            )
        )
        props.onNewSubject(newSubjectId)
    }

    return (
        <Button variant="primary" size="sm" startIcon={<PlusOutlined />} onClick={handleNewSubject}>
            {toTranslate('Lägg till ämne')}
        </Button>
    )
}
