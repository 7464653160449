import Icon, { DownOutlined, ScissorOutlined } from '@ant-design/icons'
import { Dropdown, Slider, Space } from 'antd'
import { IScheduleTransform } from 'common-api'
import React, { cloneElement, isValidElement, useState } from 'react'
import { useDispatch } from 'react-redux'
import { locallyTriggeredScheduleTransform } from '../../../store/schedule/actions'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { toTranslate } from '../../../utils/miscUtil'
import Button from '../../Button'
import type { SplitLectureButtonProps } from './types'
import { duplicateLecture } from '../utils'
import { SvgButcherKnife } from '../../../icons/SvgButcherKnife'

export const SplitLectureButton = ({ lectureId, disabled = false }: SplitLectureButtonProps) => {
    const dispatch = useDispatch()
    const schedule = useLocalSchedule()
    const [isMenuOpen, setMenuOpen] = useState(false)

    const lecture = schedule.findLecture(lectureId)
    const halfSplit = Math.round(lecture.getDurationInMinutes() / 10) * 5

    const [firstLectureLength, setFirstLectureLength] = useState(halfSplit)
    const secondLectureLength = lecture.getDurationInMinutes() - firstLectureLength

    const handleSplit = () => {
        const splitTransforms = duplicateLecture(lecture, firstLectureLength, secondLectureLength, firstLectureLength)
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(splitTransforms)))
        setMenuOpen(false)
    }

    const contentStyle: React.CSSProperties = {
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow:
            '0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05)',
        padding: '12px',
        width: '12em'
    }

    return (
        <Dropdown.Button
            buttonsRender={([left, right]) => [
                isValidElement(left) ? cloneElement(left, { ...left.props, style: { flex: 1 } }) : left,
                right
            ]}
            disabled={disabled}
            icon={<DownOutlined />}
            onClick={handleSplit}
            open={isMenuOpen}
            onOpenChange={(open) => setMenuOpen(open)}
            trigger={['click']}
            dropdownRender={() => (
                <div style={contentStyle}>
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <div style={{ display: 'flex', gap: 5, width: '100%' }}>
                            <div style={{ width: '40%', textAlign: 'right' }}>{firstLectureLength} min</div>
                            <div style={{ width: '20%', textAlign: 'center' }}>+</div>
                            <div style={{ width: '40%', textAlign: 'left' }}>{secondLectureLength} min</div>
                        </div>
                        <Slider
                            min={5}
                            max={lecture.getDurationInMinutes() - 5}
                            value={firstLectureLength}
                            onChange={setFirstLectureLength}
                            step={5}
                            tooltip={{ open: false }}
                        />
                        <Button
                            variant="primary"
                            startIcon={<Icon component={SvgButcherKnife} />}
                            onClick={handleSplit}
                        >
                            {toTranslate('Dela')}
                        </Button>
                    </Space>
                </div>
            )}
        >
            <Space>
                <Icon component={SvgButcherKnife} />
                {toTranslate('Dela lektion')}
            </Space>
        </Dropdown.Button>
    )
}
