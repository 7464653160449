import { Flex } from 'antd'
import { ChecklistItemProps } from './types'
import { ChecklistStateIcon } from './ChecklistStateIcon'
import { useCompletionStateToggler } from '../../hooks'
import { celebrateClick, isCelebrationStep } from '../../util'

export const ChecklistItem = (props: ChecklistItemProps) => {
    const toggleState = useCompletionStateToggler(props.step)

    const onClick = (e: React.MouseEvent<HTMLSpanElement>) => {
        toggleState()
        if (isCelebrationStep(props.step) && !props.isMarkedAsCompleted) {
            celebrateClick(e)
        }
    }

    return (
        <Flex align="center" gap="1em" style={props.style}>
            <ChecklistStateIcon
                isStartDetected={props.isStartDetected}
                isMarkedAsCompleted={props.isMarkedAsCompleted}
                onClick={onClick}
                stepNumber={props.stepNumber}
                size={props.small ? '1.7rem' : '3rem'}
            />
            <div>
                <div style={{ fontSize: props.small ? '100%' : '150%' }} {...(props.small ? { onClick } : {})}>
                    {props.title}
                </div>
                <div style={{ color: '#999' }}>{props.desc}</div>
            </div>
        </Flex>
    )
}
