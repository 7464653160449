import { AddMoreRowsPanelProps } from './types'
import { useState } from 'react'
import { Button, Flex, Select } from 'antd'
import { toTranslate } from '../../../../../../../utils/miscUtil'

export const AddMoreRowsPanel = (props: AddMoreRowsPanelProps) => {
    const options = [10, 20, 30, 40, 50]
    const [selectedNumberOfRows, setSelectedNumberOfRows] = useState(options[0])

    return (
        <Flex justify="center" gap={8} align="center" style={{ height: '4.5em' }}>
            <span>{toTranslate('Nya rader:')}</span>
            <Select
                size="small"
                value={selectedNumberOfRows}
                onChange={setSelectedNumberOfRows}
                options={options.map((numRowsOption) => ({ value: numRowsOption }))}
            />
            <Button size="small" onClick={() => props.onAddMoreRows(selectedNumberOfRows)}>
                {toTranslate('Lägg till')}
            </Button>
        </Flex>
    )
}
