import { PlusOutlined } from '@ant-design/icons'
import { List, Space } from 'antd'
import { uniq, without } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { StudentGroupId } from '../../../../commonTypes'
import Button from '../../../../components/Button'
import type { StudentGroupAccessor } from '../../../../schedule-access/scheduleAccessWrappers'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { multimapKeysWithValue, transformMapValues } from '../../../../utils/collections'
import AddOverlapDialog from './AddOverlapDialog'
import { NoOverlapsIcon } from './NoOverlapsIcon'
import { OverlapMode } from './OverlapMode'
import { StudentGroupListTooltip } from '../StudentGroupListTooltip'

type GroupOverlapMultiInputProps = {
    value?: Map<StudentGroupId, StudentGroupId[]>
    onChange?: (newStudentGroupIds: Map<StudentGroupId, StudentGroupId[]>) => void
    mode: OverlapMode
}

export const GroupOverlapMultiInput = ({
    value = new Map<StudentGroupId, StudentGroupId[]>(),
    onChange = () => {},
    mode
}: GroupOverlapMultiInputProps) => {
    const [isAddOverlapDialogVisible, setIsOverlapDialogVisible] = useState(false)
    const [showAll, setShowAll] = useState(false)
    const schedule = useLocalSchedule()
    const { t } = useTranslation()

    const allOverlappingGroupIds = uniq([...value.values()].flat())
    const allOverlappingGroups = allOverlappingGroupIds.map((sgId) => schedule.findStudentGroup(sgId))

    const overlappingGroupsToShow = showAll ? allOverlappingGroups : allOverlappingGroups.slice(0, 10)

    const handleAddOverlaps = (addedSgIds: StudentGroupId[]) => {
        const newValue = transformMapValues(value, (currentOverlaps) => uniq([...currentOverlaps, ...addedSgIds]))
        onChange(newValue)
        setIsOverlapDialogVisible(false)
    }

    const handleRemoveOverlap = (removedSgId: StudentGroupId) => {
        const newValue = transformMapValues(value, (currentOverlaps) => without(currentOverlaps, removedSgId))
        onChange(newValue)
    }

    const handleCancel = () => {
        setIsOverlapDialogVisible(false)
    }

    return (
        <>
            <List<StudentGroupAccessor>
                style={{ backgroundColor: 'white' }}
                dataSource={overlappingGroupsToShow}
                pagination={false}
                bordered
                footer={
                    overlappingGroupsToShow.length < allOverlappingGroups.length && (
                        <Space size="middle" style={{ width: '100%', justifyContent: 'center' }}>
                            <div>
                                Visar {overlappingGroupsToShow.length} av {allOverlappingGroups.length}
                            </div>
                            <Button onClick={() => setShowAll(true)} variant="primary">
                                Visa alla
                            </Button>
                        </Space>
                    )
                }
                locale={{ emptyText: <NoOverlapsIcon /> }}
                renderItem={(sg, index) => (
                    <List.Item
                        actions={[
                            <a key={index} onClick={() => handleRemoveOverlap(sg.getStudentGroupId())}>
                                ta bort
                            </a>
                        ]}
                    >
                        <List.Item.Meta
                            title={
                                <>
                                    {sg.getDisplayName()}
                                    {multimapKeysWithValue(value, sg.getStudentGroupId()).length < value.size && (
                                        <>
                                            {' '}
                                            <StudentGroupListTooltip
                                                title={t('ThisGroupOnlyOverlapsWithTheFollowingGroups')}
                                                studentGroups={multimapKeysWithValue(value, sg.getStudentGroupId()).map(
                                                    (sgId) => schedule.findStudentGroup(sgId)
                                                )}
                                            />
                                        </>
                                    )}
                                </>
                            }
                        />
                    </List.Item>
                )}
            />
            <div style={{ marginTop: '1em' }}>
                <a href="#" onClick={() => setIsOverlapDialogVisible(true)}>
                    <PlusOutlined /> {t('Add')} {mode === OverlapMode.OVERLAP ? '' : t('NonHypen')}{' '}
                    {t('OverlapingGroup')}
                </a>
                <AddOverlapDialog
                    open={isAddOverlapDialogVisible}
                    studentGroupIds={[...value.keys()]}
                    mode={mode}
                    handleAdd={handleAddOverlaps}
                    handleCancel={handleCancel}
                />
            </div>
        </>
    )
}
