import { Flex, Select } from 'antd'
import { isSubsequenceIgnoreCase } from '../../../../pages/schedule/components/ScheduleSearchSelector/searchUtil'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { OverrideToggleButton } from './OverrideToggleButton'
import type { TeacherOverrideInputProps } from './types'
import { comparing, optionCmp } from '../../../../utils/compareUtil'

export const TeacherOverrideInput = ({
    value,
    onChange = () => {},
    inheritedTeacherIds
}: TeacherOverrideInputProps) => {
    const schedule = useLocalSchedule()

    const options = schedule
        .getTeachers()
        .map((t) => ({
            value: t.getTeacherId(),
            label: t.getTeacherSchoolId()
        }))
        .sort(optionCmp)

    return (
        <Flex gap={8}>
            <Select
                disabled={value === undefined}
                allowClear
                mode="multiple"
                onChange={(newTeacherIds) => onChange(newTeacherIds)}
                showSearch
                filterOption={(str, opt) => isSubsequenceIgnoreCase(str, opt?.label || '')}
                maxTagCount={10}
                value={value !== undefined ? value : inheritedTeacherIds}
                options={options}
            />
            <OverrideToggleButton
                value={value !== undefined}
                onChange={(newIsOverriding) => onChange(newIsOverriding ? inheritedTeacherIds : undefined)}
            />
        </Flex>
    )
}
