import { TableEditMode, TableEditModeSwitchProps } from './types'
import { Flex, Switch, Typography } from 'antd'
import React, { useId } from 'react'
import { toTranslate } from '../../../../utils/miscUtil'
import classes from './style.module.css'

export const TableEditModeSwitch = ({ disabled = false, mode, setMode }: TableEditModeSwitchProps) => {
    const id = useId()

    return (
        <Flex gap=".5em" className={classes.modeSwitch}>
            <Switch
                id={id}
                value={mode == TableEditMode.EDIT_MODE}
                disabled={disabled}
                onChange={(isChecked) => setMode(isChecked ? TableEditMode.EDIT_MODE : TableEditMode.VIEW_MODE)}
            />
            <label htmlFor={id}>
                <Typography.Text strong={false}>{toTranslate('Tabellredigering')}</Typography.Text>
            </label>
        </Flex>
    )
}
