import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'usehooks-ts'
import type { FilterableColumn } from '../../../../components/table-support/types'
import {
    deriveRenderPropertyFromFilterTexts,
    deriveSorterPropertyFromFilterTexts,
    hideNonSelectedColumns
} from '../../../../components/table-support/util'
import { comparing } from '../../../../utils/compareUtil'
import { toTranslate } from '../../../../utils/miscUtil'
import type { FilteringState } from '../../../schedule/components/FilterInput/types'
import type { TeacherRow } from './types'
import { TeacherQualificationFilterDropdown } from './TeacherQualificationFilterDropdown'

export enum TeachersColumnKey {
    TeacherSchoolId = 0,
    FirstName = 1,
    LastName = 2,
    Qualifications = 3,
    EmploymentFraction = 4
}

export const useTeacherColumns = (filtering: FilteringState) => {
    const [visibleColumns, setVisibleColumns] = useLocalStorage('visibleTeacherColumns', [
        TeachersColumnKey.TeacherSchoolId,
        TeachersColumnKey.FirstName,
        TeachersColumnKey.LastName,
        TeachersColumnKey.Qualifications,
        TeachersColumnKey.EmploymentFraction
    ])

    const { t } = useTranslation()
    const columns: FilterableColumn<TeacherRow>[] = [
        {
            title: t('Signature'),
            key: TeachersColumnKey.TeacherSchoolId,
            filterTexts: ({ teacher }) => [teacher.getTeacherSchoolId()]
        },
        {
            title: t('FirstName'),
            key: TeachersColumnKey.FirstName,
            filterTexts: ({ teacher }) => [teacher.getFirstName()]
        },
        {
            title: t('LastName'),
            key: TeachersColumnKey.LastName,
            filterTexts: ({ teacher }) => [teacher.getLastName()]
        },
        {
            title: toTranslate('Behörigheter'),
            key: TeachersColumnKey.Qualifications,
            filterTexts: ({ teacher }) => teacher.getQualifications().map((s) => s.getName()),
            filterDropdown: TeacherQualificationFilterDropdown,
            onFilter: (subjectId, { teacher }) =>
                teacher
                    .getQualifications()
                    .map((s) => s.getSubjectId())
                    .includes(subjectId as string)
        },
        {
            title: toTranslate('Tjänstegrad'),
            key: TeachersColumnKey.EmploymentFraction,
            sorter: comparing(({ teacher }) => teacher.getWorkPercentage()),
            render: (_, { teacher }) => `${teacher.getWorkPercentage()}%`
        }
    ]

    hideNonSelectedColumns(columns, visibleColumns)
    deriveRenderPropertyFromFilterTexts(filtering, columns)
    deriveSorterPropertyFromFilterTexts(columns)

    return { columns, setVisibleColumns }
}
