import {
    AUTHENTICATED_WITHOUT_ORGANIZATION,
    AUTHENTICATED_WITHOUT_SCHEDULER_ROLE,
    UNAUTHENTICATED,
    USER_KICKED_OUT
} from './constants'

export enum AuthActionTypes {
    INIT_LOGIN = 'auth/INIT_LOGIN',
    USER_AUTHENTICATED = 'auth/USER_AUTHENTICATED',
    USER_AUTHENTICATED_WITHOUT_ORGANIZATION = 'auth/USER_AUTHENTICATED_WITHOUT_ORGANIZATION',
    USER_AUTHENTICATED_WITHOUT_SCHEDULER_ROLE = 'auth/USER_AUTHENTICATED_WITHOUT_SCHEDULER_ROLE',
    UNAUTHORIZED_API_REQUEST = 'auth/UNAUTHORIZED_API_REQUESTUSER_AUTHENTICATED_WITHOUT_SCHEDULER_ROLE'
}

export type Authenticated = {
    activeOrgId: string
}

export type AuthState =
    | typeof UNAUTHENTICATED
    | typeof AUTHENTICATED_WITHOUT_ORGANIZATION
    | typeof AUTHENTICATED_WITHOUT_SCHEDULER_ROLE
    | Authenticated
    | typeof USER_KICKED_OUT

export function isUnauthenticated(state: AuthState): state is typeof UNAUTHENTICATED {
    return state === UNAUTHENTICATED
}

export function isAuthenticatedWithoutOrganization(
    state: AuthState
): state is typeof AUTHENTICATED_WITHOUT_ORGANIZATION {
    return state === AUTHENTICATED_WITHOUT_ORGANIZATION
}

export function isAuthenticatedWithoutSchedulerRole(
    state: AuthState
): state is typeof AUTHENTICATED_WITHOUT_SCHEDULER_ROLE {
    return state === AUTHENTICATED_WITHOUT_SCHEDULER_ROLE
}

export function isAuthenticated(state: AuthState): state is Authenticated {
    return (state as any).activeOrgId !== undefined
}

export function isUserKickedOut(state: AuthState): state is typeof UNAUTHENTICATED {
    return state === USER_KICKED_OUT
}
