const brandColors = {
    black: '#000000',
    gray75: '#2e2e2c',
    gray60: '#666666',
    gray50: '#7f7f7f',
    gray40: '#999999',
    gray30: '#b2b2b2',
    gray20: '#cccccc',
    gray10: '#e5e5e5',
    gray5: '#ebebea',
    gray2: '#f2f4f5',
    white: '#fefefe',
    yellow: '#f3df49',
    red: '#eb5558',
    green: '#9ee79a',
    blocker: '#ff4d4f',
    warning: '#ffc53d'
}

export default brandColors
