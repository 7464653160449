export enum DevInfoActionTypes {
    ACTIVATE_DEV_MODE = 'devmode/ACTIVATE',
    DEACTIVATE_DEV_MODE = 'devmode/DEACTIVATE',
    SCHEDULE_SSE_CONNECTION_STATE_UPDATE = 'devmode/SCHEDULE_SSE_CONNECTION_STATE_UPDATE',
    AUTO_SCHEDULER_SSE_CONNECTION_STATE_UPDATE = 'devmode/AUTO_SCHEDULER_SSE_CONNECTION_STATE_UPDATE'
}

export enum SseConnectionState {
    OPEN,
    CLOSED,
    ERROR
}

export type DevInfoState = {
    devMode: boolean
    scheduleSseConnectionState: SseConnectionState
    autoSchedulerSseConnectionState: SseConnectionState
}
