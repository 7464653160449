import { Card, Empty, Space } from 'antd'
import { TeacherDiffCardProps } from './types'
import classes from './style.module.css'

export const TeacherDiffCard = <T,>(props: TeacherDiffCardProps<T>) => (
    <Card
        title={
            <Space>
                {props.icon}
                {props.title}
            </Space>
        }
        style={{ flex: 1 }}
    >
        {props.changes.length > 0 ? (
            <ul className={classes.topLevelTeacherList}>{props.changes.map(props.renderChange)}</ul>
        ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={props.noChangesText} />
        )}
    </Card>
)
