import { Select } from 'antd'
import { WeekExceptionSelectProps } from './types'

const weekOption = (w: number) => ({ label: `${w}`, value: w })

export const WeekExceptionSelect = (props: WeekExceptionSelectProps) => (
    <Select
        style={{ minWidth: '7em' }}
        value={props.selectedWeeks}
        mode="multiple"
        allowClear
        options={props.weekOptions.map(weekOption)}
        onChange={props.onChange}
    />
)
