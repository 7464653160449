import type { FilterDropdownProps } from 'antd/es/table/interface'
import { toTranslate } from '../../../utils/miscUtil'
import { EnumFilterDropdown } from '../../YesNoFilterDropdown'

export const TimeslotPinnedFilterDropdown = (props: FilterDropdownProps) => {
    return (
        <EnumFilterDropdown
            {...props}
            allFilters={[
                { value: 'true', text: toTranslate('Ja') },
                { value: 'false', text: toTranslate('Nej') }
            ]}
        />
    )
}
