import { useDispatch, useSelector } from 'react-redux'
import type { ScheduleAccessor } from '../../schedule-access/scheduleAccessWrappers'
import { applyAllTransforms } from '../../utils/scheduleTransforms'
import type { ApplicationState } from '../index'
import { IScheduleTransform } from 'common-api'
import { useEffect } from 'react'
import { subscribe, unsubscribe } from './actions'
import { ScheduleId } from '../../commonTypes'
import { useActiveOrgId } from '../auth/hooks'

export const useLocalSchedule = (): ScheduleAccessor => {
    const schedule = useSchedule()
    const transforms = useSelector<ApplicationState, IScheduleTransform[]>(({ schedule }) => schedule.pendingTransforms)
    return applyAllTransforms(schedule, transforms)
}

export const useScheduleSubscription = (scheduleId: ScheduleId) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(subscribe(scheduleId))
        return () => {
            dispatch(unsubscribe(scheduleId))
        }
    }, [dispatch, scheduleId])
}

export const useSchedule = (): ScheduleAccessor => {
    const scheduleId = useActiveOrgId()
    useScheduleSubscription(scheduleId)
    return useSelector((s: ApplicationState) => s.schedule.schedule)
}

export const useIsDummySchedule = (): boolean =>
    useSelector<ApplicationState, boolean>((state) => state.schedule.isDummy)
