import { Button, Form, Input, message } from 'antd'
import { useForm } from 'antd/es/form/Form'
import type { ILectureDurationThresholds, IMinBreakThresholds } from 'common-api'
import { ICourseOrSubjectId, IScheduleTransform, Terms } from 'common-api'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import type {
    CourseRoundId,
    OptionalLectureDurationThresholds,
    OptionalMinBreakThresholds,
    StudentGroupId,
    TeacherId
} from '../../commonTypes'
import type { CourseAccessor, CourseRoundAccessor, SubjectAccessor } from '../../schedule-access/scheduleAccessWrappers'
import { isCourseAccessor } from '../../schedule-access/scheduleAccessWrappers'
import { locallyTriggeredScheduleTransform } from '../../store/schedule/actions'
import { useLocalSchedule } from '../../store/schedule/hooks'
import { comparing } from '../../utils/compareUtil'
import { toTranslate } from '../../utils/miscUtil'
import BulkGeneralInput from '../BulkGeneralInput'
import BulkInput from '../BulkInput'
import BulkInputNumber from '../BulkInputNumber'
import BulkSelect from '../BulkSelect'
import { BulkSingleSelect } from '../BulkSingleSelect'
import { LectureDurationThresholdsInput } from '../LectureDurationThresholdsInput'
import { CourseOrSubjectSelector } from './CourseOrSubjectSelector'
import { CourseRoundTeacherSelector } from './CourseRoundTeacherSelector'
import { MinBreakThresholdsInput } from './MinBreakThresholdsInput'

type CourseRoundDetailsProps = {
    courseRounds: CourseRoundAccessor[]
}

type CourseRoundFormValues = {
    courseRoundIds: CourseRoundId[]
    displayNames: string[]
    teacherIds: Map<CourseRoundId, TeacherId[]>
    courseOrSubjectIds: ICourseOrSubjectId[]
    studentGroupIds: StudentGroupId[]
    terms: Terms[]
    totalHours: number[]
    minBreakThresholds: OptionalMinBreakThresholds[]
    lectureDurationThresholds: OptionalLectureDurationThresholds[]
}

const courseRoundTeacherEntry = (cr: CourseRoundAccessor): [CourseRoundId, TeacherId[]] => [
    cr.getCourseRoundId(),
    cr.getTeachers().map((t) => t.getTeacherId())
]

const courseOrSubjectIdFromAccessor = (cos: CourseAccessor | SubjectAccessor) =>
    isCourseAccessor(cos)
        ? ICourseOrSubjectId.courseId(cos.getCourseId())
        : ICourseOrSubjectId.subjectId(cos.getSubjectId())

const formValuesFromAccessors = (courseRounds: CourseRoundAccessor[]): CourseRoundFormValues => ({
    courseRoundIds: courseRounds.map((cr) => cr.getCourseRoundId()),
    displayNames: courseRounds.map((cr) => cr.getDisplayName()),
    teacherIds: new Map(courseRounds.map(courseRoundTeacherEntry)), // fill in below
    courseOrSubjectIds: courseRounds.map((cr) => courseOrSubjectIdFromAccessor(cr.getCourseOrSubject())),
    studentGroupIds: courseRounds.map((cr) => cr.getStudentGroup().getStudentGroupId()),
    terms: courseRounds.map((cr) => cr.getTerms()),
    totalHours: courseRounds.map((cr) => cr.getTotalHours()),
    minBreakThresholds: courseRounds.map((cr) => cr.getMinBreakThresholds()),
    lectureDurationThresholds: courseRounds.map((cr) => cr.getLectureDurationThresholds())
})

export const CourseRoundDetails = ({ courseRounds }: CourseRoundDetailsProps) => {
    const dispatch = useDispatch()
    const [form] = useForm()
    const schedule = useLocalSchedule()
    const { t } = useTranslation()

    courseRounds.sort(comparing((cr) => cr.getDisplayName()))

    const saveCourseRounds = (formValues: CourseRoundFormValues) => {
        const crTransforms = formValues.courseRoundIds.map((crId, index) =>
            IScheduleTransform.courseRoundTransform({
                newCourseRound: {
                    courseRoundId: crId,
                    displayName: formValues.displayNames[index],
                    teacherIds: formValues.teacherIds.get(crId)!,
                    courseOrSubjectId: formValues.courseOrSubjectIds[index],
                    studentGroupId: formValues.studentGroupIds[index],
                    terms: formValues.terms[index],
                    totalHours: formValues.totalHours[index],
                    minBreakThresholds: formValues.minBreakThresholds[index],
                    lectureDurationThresholds: formValues.lectureDurationThresholds[index]
                }
            })
        )
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(crTransforms)))
        message.success(toTranslate(courseRounds.length === 1 ? 'Kursomgång sparad' : 'Kursomgångar sparade'))
    }

    return (
        <div style={{ overflow: 'auto', maxHeight: '100%', padding: '20px' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Form
                    form={form}
                    name="courseRoundForm"
                    initialValues={formValuesFromAccessors(courseRounds)}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: '700px', width: '100%' }}
                    onFinish={saveCourseRounds}
                    labelWrap
                    colon={false}
                >
                    <Form.List name="courseRoundIds">
                        {(fields) =>
                            fields.map((field) => (
                                // biome-ignore lint/correctness/useJsxKeyInIterable: can be removed when we switch form components
                                <Form.Item hidden {...field}>
                                    <Input />
                                </Form.Item>
                            ))
                        }
                    </Form.List>
                    <Form.Item name="displayNames" label={t('Designation')}>
                        <BulkInput
                            multiplicityMessage={toTranslate('Välj en enskild kursomgång för att redigera namnet.')}
                        />
                    </Form.Item>
                    <Form.Item name="teacherIds" label={t('Teacher')}>
                        <CourseRoundTeacherSelector />
                    </Form.Item>
                    <Form.Item name="courseOrSubjectIds" label={toTranslate('Ämne / kurs')}>
                        <BulkGeneralInput<ICourseOrSubjectId>
                            multiplicityMessage={toTranslate('Välj en enskild kursomgång för att ändra ämne/kurs.')}
                            renderInput={(value, onChange) => (
                                <CourseOrSubjectSelector value={value} onChange={onChange} />
                            )}
                        />
                    </Form.Item>
                    <Form.Item name="studentGroupIds" label={t('StudentGroup')}>
                        <BulkSelect
                            multiplicityMessage={toTranslate('Välj en enksild kursomgång för att ändra elevgrupp.')}
                            options={schedule
                                .getStudentGroups()
                                .map((sg) => ({
                                    value: sg.getStudentGroupId(),
                                    label: sg.getDisplayName()
                                }))
                                .toSorted(comparing((opt) => opt.label))}
                        />
                    </Form.Item>
                    <Form.Item name="terms" label={toTranslate('Terminer')}>
                        <BulkSingleSelect
                            originalValue={courseRounds.map((cr) => cr.getTerms())}
                            options={[
                                { value: Terms.YEAR, label: toTranslate('Läsår') },
                                { value: Terms.FALL, label: toTranslate('HT') },
                                { value: Terms.SPRING, label: toTranslate('VT') }
                            ]}
                        />
                    </Form.Item>
                    <Form.Item name="totalHours" label={t('Hours')}>
                        <BulkInputNumber
                            multiplicityMessage={toTranslate('Välj en enskild kursomgång för att ändra timmar.')}
                        />
                    </Form.Item>
                    <Form.Item name="minBreakThresholds" label={t('BreakSettings')}>
                        <BulkGeneralInput<IMinBreakThresholds | undefined>
                            renderInput={(value, onChange) => (
                                <MinBreakThresholdsInput value={value} onChange={onChange} />
                            )}
                            multiplicityMessage={toTranslate('Välj en enskild kursomgång för att ändra rastvillkor.')}
                        />
                    </Form.Item>
                    <Form.Item name="lectureDurationThresholds" label={t('LectureDuration')}>
                        <BulkGeneralInput<ILectureDurationThresholds | undefined>
                            renderInput={(value, onChange) => (
                                <LectureDurationThresholdsInput
                                    value={value}
                                    onChange={onChange}
                                    inheritLabels={{
                                        inheritEntityTypeName: 'kurs',
                                        currentEntityTypeName: 'kursomgång'
                                    }}
                                    inheritedValues={courseRounds[0].getLectureDurationThresholds(true)}
                                />
                            )}
                            multiplicityMessage={toTranslate(
                                'Välj en enskild kursomgång för att ändra lektionslängder.'
                            )}
                        />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8 }}>
                        <Button type="primary" htmlType="submit">
                            {t('Save')}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}
