import { ScrollGradientMaskWrapperProps } from './types'
import classNames from 'classnames'
import classes from './style.module.css'
import { useEffect, useRef, useState } from 'react'
import { opacityAtScrollPosition } from './util'

export const ScrollGradientMaskWrapper = (props: ScrollGradientMaskWrapperProps) => {
    const [topOpacity, setTopOpacity] = useState(0)
    const [bottomOpacity, setBottomOpacity] = useState(1)

    const scrollableDivRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleScroll = () => {
            if (scrollableDivRef.current) {
                // Compute opacity of top gradient
                const scrollTop = scrollableDivRef.current.scrollTop
                setTopOpacity(opacityAtScrollPosition(scrollTop, 0))

                const scrollHeight = scrollableDivRef.current.scrollHeight - scrollableDivRef.current.clientHeight
                setBottomOpacity(opacityAtScrollPosition(scrollTop, scrollHeight))
            }
        }

        // Initialize values based on initial scroll position and component height
        handleScroll()

        const div = scrollableDivRef.current
        div?.addEventListener('scroll', handleScroll)
        return () => {
            div?.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <div {...props} className={classNames(classes.wrapper, props.className)} ref={scrollableDivRef}>
            <div className={classNames(classes.gradientOverlayWrapper, classes.topGradientOverlayWrapper)}>
                <div className={classes.topGradientOverlay} style={{ opacity: topOpacity }} />
            </div>
            {props.children}
            <div className={classNames(classes.gradientOverlayWrapper, classes.bottomGradientOverlayWrapper)}>
                <div className={classes.bottomGradientOverlay} style={{ opacity: bottomOpacity }} />
            </div>
        </div>
    )
}
