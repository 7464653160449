export const TeacherImportHelpPanel = () => (
    <>
        <h2>Importera Personal</h2>
        <p>
            Klicka på Personal i menyn och klicka på import-knappen längst upp till höger. I tabellen som visas klistrar
            du in datan för alla lärare.
        </p>
        <p>
            Beskrivning av kolumnerna:
            <ul>
                <li>
                    <p>
                        <strong>Signatur</strong> - En unik signatur för läraren. Kan till exempel vara en förkortning
                        av lärarens namn. Lärarens signatur kommer synas på lektionerna i schemat så et är bra om
                        signaturen är kort då det kan vara ont om plats.
                    </p>
                    <p>
                        <strong>Exempel:</strong> En lärare som heter Andreas Lundblad skulle kunna ha signaturen{' '}
                        <code>ALU</code> (förutsatt att ingen annan lärare har den signaturen).
                    </p>
                </li>
                <li>
                    <strong>Förnamn</strong> - Lärarens förnamn. Får inte lämnas tomt.
                </li>
                <li>
                    <strong>Efternamn</strong> - Lärarens efternamn. Får inte lämnas tomt.
                </li>
                <li>
                    <strong>Tjänstegrad</strong> - Detta fält används om läraren är anställd på deltid. Om en lärare
                    till exempel är anställd på halvtid fyller du i värdet 50%. Tabellen accepterar också decimalformat,
                    så som 0,5.
                </li>
            </ul>
        </p>
    </>
)
