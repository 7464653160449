import { TeachingAssignments } from '../../components/teaching-assignments/TeachingAssignments'
import styled from '../../utils/styled'

export const TeachingAssignmentIndexPage = () => {
    return (
        <div style={{ height: '100vh', width: '100%' }}>
            <TeachingAssignments />
        </div>
    )
}

const PageContent = styled('article')`
    display: flex;
    column-gap: 20px;
`
