import { Button, Flex } from 'antd'
import { ExportOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router'
import { SettingsHelpPanelProps } from './types'
import scheduleModeImage from './schedule-mode.png'
import openingHoursImage from './opening-hours.png'
import breakConstraintsImage from './break-parameters.png'
import lunchConstraintsImage from './lunch-constraints.png'
import lectureLengthsImage from './lecture-lengths.png'
import { ScreenshotImage } from '../ScreenshotImage'
import { HelpPanelDivider } from '../HelpPanelDivider'

export const SettingsHelpPanel = (props: SettingsHelpPanelProps) => {
    const navigate = useNavigate()

    return (
        <div>
            <p>
                Gå igenom inställningarna och ställ in allt så det passar för er skola. Öppna inställningarna i menyn
                till vänster, eller genom att klicka på knappen nedan. Alla inställningar går att ändra i efterhand, så
                om det är någon inställning du inte är säker på så kan du lämna det som det är och återkomma till den
                vid ett senare tillfälle.
            </p>
            <Flex justify="space-around" style={{ marginTop: '2em' }}>
                <Button
                    icon={<ExportOutlined />}
                    type="primary"
                    onClick={() => {
                        props.onNavigateAway()
                        navigate('/schedule-parameters')
                    }}
                >
                    Gå till inställningar
                </Button>
            </Flex>
            <HelpPanelDivider />
            <ScreenshotImage src={scheduleModeImage} />
            <p>
                Denna inställning bestämmer ifall menyer och tabeller för kurser ska visas.
                <ul>
                    <li>
                        <strong>Grundskoleläge</strong> - Kurser döljs och lektioner är knutna till ämnen.
                    </li>
                    <li>
                        <strong>Gymnasieläge</strong> - Lektioner kan knytas till kurser (men kan också knytas till
                        ämnen i fall skolan erbjuder grundskolekurser).
                    </li>
                </ul>
            </p>
            <HelpPanelDivider />
            <ScreenshotImage src={openingHoursImage} />
            <p>
                Denna inställning bestämmer start och slut tid på schemat. Inga lektioner kan ligga utanför
                öppettiderna.
            </p>
            <HelpPanelDivider />
            <ScreenshotImage src={breakConstraintsImage} />
            <p>
                Om rastvillkoren inte är uppfyllda kommer du få en varning av programmet, och om du använder den
                automatiska schemaläggaren så kommer den respektera rastvillkoren.
            </p>
            <p>
                Rastvillkoren som ställs in här gäller för hela skolan om inget annat anges. Mer specifika
                rastinställningar går att ställa in separat för ämnen som till exempel idrott.
            </p>
            <HelpPanelDivider />
            <ScreenshotImage src={lunchConstraintsImage} />
            <p>
                Om lunchvillkoret inte är uppfyllt kommer du få en varning av programmet, och om du använder den
                automatiska schemaläggaren så kommer den respektera lunchvillkoren.
            </p>
            <HelpPanelDivider />
            <ScreenshotImage src={lectureLengthsImage} />
            <p>
                Om villkoren för lektionslängder inte är uppfyllda kommer du få en varning av programmet. Om du använder
                funktionen för att generera lektioner så kommer programmet skapa lektioner inom gränserna som är angivna
                här.
            </p>
        </div>
    )
}
