import { Select } from 'antd'
import classNames from 'classnames'
import { IScheduleTransform } from 'common-api'
import _, { max } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useDrop } from 'react-dnd'
import { useDispatch } from 'react-redux'
import type { CourseRoundAccessor, TeacherAccessor } from '../../schedule-access/scheduleAccessWrappers'
import { locallyTriggeredScheduleTransform } from '../../store/schedule/actions'
import { useLocalSchedule } from '../../store/schedule/hooks'
import TypeScale from '../../styles/TypeScale'
import { DndItemTypes } from '../../utils/DndItemTypes'
import { comparing } from '../../utils/compareUtil'
import { toTranslate } from '../../utils/miscUtil'
import { isClassStudentGroup } from '../../utils/studentGroupUtil'
import { DraggableCourseRound } from './CourseRound'
import { DragLayer } from './DragLayer'
import { Positioned } from './Positioned'
import SelectedCourseRoundPanel from './SelectedCourseRoundPanel'
import classes from './style.module.css'
import { hoursToWidth, indexToHeight, mousePositionToRow, TEACHER_FULL_TIME_HOURS } from './util'
import { TaAutoSchedulerControls } from '../TaAutoSchedulerControls'
import { useDevModeState } from '../../store/devmode/hooks'
import { ProblemCorner } from '../../pages/schedule/components/ProblemCorner'
import { toProblemListProblem } from '../../pages/schedule/components/ProblemsList/utils'
import { useTranslation } from 'react-i18next'
import { isProblemRelatedToCourseRound, isProblemRelatedToTeacher } from '../../utils/problems'

export const TeachingAssignments = () => {
    const dispatch = useDispatch()
    const schedule = useLocalSchedule()
    const devMode = useDevModeState()
    const dropWrapper = useRef<HTMLDivElement>(null)
    const { t } = useTranslation()
    const teachers = schedule.getTeachers().sort(comparing((t) => t.getTeacherSchoolId()))

    const teacherOptions = teachers.map((t) => ({
        value: t.getTeacherId(),
        label: `${t.getFirstName()} ${t.getLastName()} (${t.getTeacherSchoolId()})`
    }))
    const courseRoundOptions = schedule
        .getCourseRounds()
        .map((cr) => ({ value: cr.getCourseRoundId(), label: cr.getDisplayName() }))
    const courseOptions = schedule.getCourses().map((c) => ({ value: c.getCourseId(), label: c.getName() }))
    const studentGroups = schedule
        .getStudentGroups()
        .filter((sg) => isClassStudentGroup(sg))
        .map((sg) => ({ value: sg.getStudentGroupId(), label: sg.getDisplayName() }))

    const allCourseOptions = [
        {
            label: toTranslate('Kursomgångar'),
            options: courseRoundOptions
        },
        {
            label: toTranslate('Kurser'),
            options: courseOptions
        }
    ]

    const [selectedTeachers, setSelectedTeachers] = useState<string[]>([])
    const [filteredCourseRounds, setFilteredCourseRounds] = useState<string[]>([])
    const [selectedStudentGroups, setSelectedStudentGroups] = useState<string[]>([])

    const filteredTeachers = teachers.filter((t) => {
        if (selectedTeachers.length === 0) {
            return true
        }

        return selectedTeachers.includes(t.getTeacherId())
    })

    const [selectedCourseRounds, setSelectedCourseRounds] = useState<CourseRoundAccessor[]>([])

    const maxVisibleHours =
        1.15 *
        Math.max(
            TEACHER_FULL_TIME_HOURS,
            _.max(
                filteredTeachers.map(teacherCourseRoundsLayout).map((crs) => _.sumBy(crs, (crs) => crs.totalHours))
            ) || 0
        )

    const courseRoundsWithoutTeachers = schedule.getCourseRounds().filter((cr) => cr.getTeachers().length === 0)

    const positionedCrsWithoutTeachers = courseRoundsWithoutTeachers.reduce(
        (acc, cr) => {
            const startHour = acc.length > 0 ? acc[acc.length - 1].startHour + acc[acc.length - 1].totalHours : 0
            const totalHours = hoursToWidth(cr.getTotalHours())

            return [...acc, { cr, startHour, totalHours }]
        },
        [] as { cr: CourseRoundAccessor; startHour: number; totalHours: number }[]
    )

    const [hasOverflow, setHasOverflow] = useState(false)
    const dropboxContentRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const checkOverflow = () => {
            if (dropboxContentRef.current) {
                const hasHorizontalOverflow =
                    dropboxContentRef.current.scrollWidth > dropboxContentRef.current.clientWidth
                setHasOverflow(hasHorizontalOverflow)
            }
        }

        checkOverflow()
        // Add resize observer to check for overflow when container size changes
        const resizeObserver = new ResizeObserver(checkOverflow)
        if (dropboxContentRef.current) {
            resizeObserver.observe(dropboxContentRef.current)
        }

        return () => resizeObserver.disconnect()
    }, [positionedCrsWithoutTeachers])

    function teacherCourseRoundsLayout(
        teacher: TeacherAccessor
    ): { cr: CourseRoundAccessor; startHour: number; totalHours: number }[] {
        const courseRound = schedule
            .getCourseRounds()
            .filter((cr) => cr.getTeachers().some((t) => t.getTeacherId() === teacher.getTeacherId()))
            .sort((a, b) => a.getDisplayName().localeCompare(b.getDisplayName()))
            .filter((item) => {
                if (selectedStudentGroups.length === 0) {
                    return true
                }

                const hasStudentGroup = selectedStudentGroups.some((sgId) => {
                    const findSg = schedule.findStudentGroup(sgId)
                    return findSg.doesOverlapWith(item.getStudentGroup())
                })

                return hasStudentGroup
            })
        let start = 0
        return courseRound.map((cr) => {
            const crStart = start
            const crWidth = hoursToWidth(cr.getTotalHours())
            start += hoursToWidth(cr.getTotalHours())

            return { cr, startHour: crStart, totalHours: crWidth }
        })
    }

    const crtws = filteredTeachers.map((teacher) => teacherCourseRoundsLayout(teacher))
    const maxByRow = crtws.map((crsInRow) => max(crsInRow.map((crws) => crws.startHour + crws.totalHours)) || 0)

    const [, drop] = useDrop(
        () => ({
            accept: DndItemTypes.EVENT,
            drop: (item: any, monitor) => {
                const currentOffset = monitor.getClientOffset()
                const row = mousePositionToRow(currentOffset, dropWrapper.current?.getBoundingClientRect())

                if (row !== undefined && row >= 0 && row < filteredTeachers.length) {
                    const transform = IScheduleTransform.courseRoundTransform({
                        newCourseRound: {
                            ...schedule.findCourseRound(item.courseRoundId).getConjureObject(),
                            teacherIds: [filteredTeachers[row].getTeacherId()]
                        }
                    })
                    dispatch(locallyTriggeredScheduleTransform(transform))
                }
            }
        }),
        [filteredTeachers]
    )

    const [{ isOver }, dropbox] = useDrop(
        () => ({
            accept: DndItemTypes.EVENT,
            collect: (monitor) => ({
                isOver: Boolean(monitor.isOver())
            }),
            drop: (item: any) => {
                const transform = IScheduleTransform.courseRoundTransform({
                    newCourseRound: {
                        ...schedule.findCourseRound(item.courseRoundId).getConjureObject(),
                        teacherIds: []
                    }
                })
                dispatch(locallyTriggeredScheduleTransform(transform))
            }
        }),
        [schedule]
    )

    const sortedCourseRounds = crtws
        .flatMap((crsInRow, row) => crsInRow.map((crws) => ({ row, crws })))
        .sort(comparing((crr) => crr.crws.cr.getCourseRoundId()))

    function getWorkHours(workPercentage: number) {
        return (TEACHER_FULL_TIME_HOURS * workPercentage) / 100
    }

    function handleOnCourseRoundClick(isShiftClick: boolean, courseRound: CourseRoundAccessor) {
        if (isShiftClick) {
            setSelectedCourseRounds((prev) => {
                if (prev.some((cr) => cr.getCourseRoundId() === courseRound.getCourseRoundId())) {
                    return prev.filter((cr) => cr.getCourseRoundId() !== courseRound.getCourseRoundId())
                }

                return [...prev, courseRound]
            })

            return
        }

        setSelectedCourseRounds((prev) => {
            if (prev.some((cr) => cr.getCourseRoundId() === courseRound.getCourseRoundId())) {
                return []
            }

            return [courseRound]
        })
    }

    function hasSelectedCourseRounds(courseRoundId: string) {
        return selectedCourseRounds.some((cr) => cr.getCourseRoundId() === courseRoundId)
    }

    function hasFilteredCourseRound(courseRoundId: string) {
        if (filteredCourseRounds.length === 0) {
            return true
        }

        return filteredCourseRounds.includes(courseRoundId)
    }

    return (
        <div className={classes.flexBox}>
            <div
                className={classNames(classes.outerWrapper, {
                    [classes['wrapper--hasSelectedCourseRounds']]: selectedCourseRounds.length > 0
                })}
            >
                <div className={classes.header}>
                    <div className={classes.filtersWrapper}>
                        <div>
                            <Select
                                mode="multiple"
                                value={selectedTeachers}
                                options={teacherOptions}
                                placeholder={toTranslate('Filtrera på lärare')}
                                allowClear
                                optionFilterProp="label"
                                style={{
                                    width: '300px'
                                }}
                                maxTagCount={1}
                                onChange={setSelectedTeachers}
                            />
                        </div>
                        <div>
                            <Select
                                mode="multiple"
                                value={filteredCourseRounds}
                                optionFilterProp="label"
                                options={allCourseOptions}
                                placeholder={toTranslate('Filtrera på kurser/kursomgångar')}
                                allowClear
                                style={{
                                    width: '300px'
                                }}
                                maxTagCount={1}
                                onChange={setFilteredCourseRounds}
                            />
                        </div>
                        <div>
                            <Select
                                mode="multiple"
                                value={selectedStudentGroups}
                                options={studentGroups}
                                placeholder={toTranslate('Filtrera på klasser')}
                                allowClear
                                optionFilterProp="label"
                                style={{
                                    width: '300px'
                                }}
                                maxTagCount={1}
                                onChange={setSelectedStudentGroups}
                            />
                        </div>
                    </div>
                    {devMode && <TaAutoSchedulerControls />}
                </div>
                <div className={classes.wrapper}>
                    <div className={classes.innerWrapper} ref={drop}>
                        {filteredTeachers.map((teacher, index) => (
                            <div
                                className={classNames(classes.rowWrapper, {
                                    [classes['rowWrapper--even']]: index % 2 === 0,
                                    [classes['rowWrapper--odd']]: index % 2 !== 0
                                })}
                                key={teacher.getTeacherId()}
                                style={{ top: `${indexToHeight(index)}px` }}
                            >
                                <div className={classes.teacherName}>
                                    <div className={classes.teacherNameBackground}>
                                        <div>
                                            <ProblemCorner
                                                problems={schedule
                                                    .getSchedulingProblems()
                                                    .filter((p) =>
                                                        isProblemRelatedToTeacher(
                                                            schedule,
                                                            p.problem,
                                                            teacher.getTeacherId()
                                                        )
                                                    )
                                                    .map((p) => toProblemListProblem(schedule, p, t))}
                                            />
                                            {teacher.getTeacherSchoolId()}
                                        </div>
                                        <div style={{ marginTop: '.3em' }}>
                                            {sortedCourseRounds
                                                .filter((cr) => cr.row === index)
                                                .map((cr) => cr.crws.totalHours)
                                                .reduce((prev, current) => prev + current, 0)}
                                            h &nbsp;(
                                            {Math.round(
                                                (100 *
                                                    sortedCourseRounds
                                                        .filter((cr) => cr.row === index)
                                                        .map((cr) => cr.crws.totalHours)
                                                        .reduce((prev, current) => prev + current, 0)) /
                                                    TEACHER_FULL_TIME_HOURS
                                            )}
                                            %)
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.hoursWrapper}>
                                    <div
                                        className={classes.blocked}
                                        style={{
                                            left: `${(100 * getWorkHours(teacher.getWorkPercentage())) / maxVisibleHours}%`,
                                            width: `${100 * (1 - getWorkHours(teacher.getWorkPercentage()) / maxVisibleHours)}%`
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                        <div className={classes.courseRounds}>
                            {sortedCourseRounds.map(({ crws, row }) => {
                                const courseId = crws.cr.getCourse()?.getCourseId() ?? ''

                                const isInFilter =
                                    hasFilteredCourseRound(crws.cr.getCourseRoundId()) ||
                                    hasFilteredCourseRound(courseId)
                                return (
                                    <Positioned
                                        key={crws.cr.getCourseRoundId()}
                                        row={row}
                                        startHour={crws.startHour}
                                        totalHours={crws.totalHours}
                                        maxVisibleHours={maxVisibleHours}
                                    >
                                        <ProblemCorner
                                            problems={schedule
                                                .getSchedulingProblems()
                                                .filter((pws) =>
                                                    isProblemRelatedToCourseRound(
                                                        pws.problem,
                                                        crws.cr.getCourseRoundId()
                                                    )
                                                )
                                                .map((pws) => toProblemListProblem(schedule, pws, t))}
                                        />
                                        <DraggableCourseRound
                                            onClick={(event) => {
                                                handleOnCourseRoundClick(event.shiftKey, crws.cr)
                                            }}
                                            courseRoundId={crws.cr.getCourseRoundId()}
                                            row={row}
                                            selected={hasSelectedCourseRounds(crws.cr.getCourseRoundId())}
                                            isInFilter={isInFilter}
                                        />
                                    </Positioned>
                                )
                            })}
                            <div className={classes.dragLayerWrapper} ref={dropWrapper}>
                                <DragLayer maxes={maxByRow} maxVisibleHours={maxVisibleHours} />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={classNames(classes.dropbox, {
                        [classes['dropbox--isOver']]: isOver
                    })}
                    ref={dropbox}
                >
                    <div className={classes.dropbox__inner}>
                        <h2 className={TypeScale.Heading_MD}>{toTranslate('Kursomgångar utan lärare')}</h2>
                        <div className={classes.dropboxContent} ref={dropboxContentRef}>
                            {positionedCrsWithoutTeachers.length > 0 ? (
                                positionedCrsWithoutTeachers.map((cr) => (
                                    <Positioned
                                        key={cr.cr.getCourseRoundId()}
                                        startHour={cr.startHour}
                                        totalHours={cr.totalHours}
                                        maxVisibleHours={maxVisibleHours}
                                    >
                                        <DraggableCourseRound
                                            onClick={(event) => {
                                                handleOnCourseRoundClick(event.shiftKey, cr.cr)
                                            }}
                                            courseRoundId={cr.cr.getCourseRoundId()}
                                            isInFilter={hasFilteredCourseRound(cr.cr.getCourseRoundId())}
                                            selected={hasSelectedCourseRounds(cr.cr.getCourseRoundId())}
                                        />
                                    </Positioned>
                                ))
                            ) : (
                                <p className={TypeScale.Paragraph_SM_Regular}>
                                    {toTranslate('Alla kursomgångar har en lärare kopplat till sig.')}
                                </p>
                            )}
                        </div>
                    </div>

                    {hasOverflow && (
                        <div className={classes.gradientRightWrapper}>
                            <div className={classes.gradientRight} />
                        </div>
                    )}
                </div>
            </div>
            <SelectedCourseRoundPanel
                onRemove={() => {
                    setSelectedCourseRounds([])
                }}
                selectedCourseRounds={selectedCourseRounds}
            />
        </div>
    )
}
