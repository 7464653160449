import { SaveOutlined } from '@ant-design/icons'
import { SaveTableContentButtonProps } from './types'
import { Button } from 'antd'
import { toTranslate } from '../../../../../../../../utils/miscUtil'

export const SaveTableContentButton = ({ enabled, onSave }: SaveTableContentButtonProps) => {
    return (
        <Button icon={<SaveOutlined />} type="primary" onClick={onSave} disabled={!enabled}>
            {toTranslate('Spara')}
        </Button>
    )
}
