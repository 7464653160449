import { StudentGroupListTooltipProps } from './types'
import { EntityListTooltip } from '../../../../components/MultiRoomAttributeSelector/RoomsListTooltip/EntityListTooltip'
import { toTranslate } from '../../../../utils/miscUtil'

export const StudentGroupListTooltip = ({ title, studentGroups }: StudentGroupListTooltipProps) => (
    <EntityListTooltip
        title={title}
        entities={studentGroups}
        entityLabelFn={(sg) => sg.getDisplayName()}
        entityKeyFn={(sg) => sg.getStudentGroupId()}
        singularEntityLabel={toTranslate('elevgrupp')}
        pluralEntityLabel={toTranslate('elevgrupper')}
    />
)
