import type { LectureAccessor } from '../../schedule-access/scheduleAccessWrappers'
import { courseRoundDisplayName } from '../../utils/scheduleUtils'

export function notUndefined<T>(val: T | undefined): val is T {
    return val !== undefined
}

export const displayNameForLecture = (lecture: LectureAccessor) => {
    const crDisplayName = courseRoundDisplayName(lecture.getCourseRound(), false)
    const courseLectures = lecture.getCourseRound().getLectures()
    const thisLectureIndex = courseLectures.findIndex((l) => l.getLectureId() === lecture.getLectureId())
    return `${crDisplayName} [${thisLectureIndex + 1}/${courseLectures.length}]`
}
