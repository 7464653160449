import { PushpinFilled } from '@ant-design/icons'
import styled from '@emotion/styled'
import type { IDayAndTime } from 'common-api'
import { formatHHMM } from '../../../utils/DayAndTimeUtil'

type CornerPos = 'topleft' | 'bottomright'

export const TimestampCorner = ({
    pos,
    dayAndTime,
    bgColor,
    pinned = false
}: {
    dayAndTime: IDayAndTime
    pos: CornerPos
    bgColor: string
    pinned?: boolean
}) => (
    <Corner {...{ pos, bgColor }}>
        <CornerContent style={{ display: 'flex', gap: '3px', alignItems: 'center', paddingTop: '.2em' }}>
            <div>{formatHHMM(dayAndTime)}</div>
            {pinned ? <PushpinFilled style={{ fontSize: '80%' }} /> : null}
        </CornerContent>
    </Corner>
)

const Corner = styled.div<{ pos: CornerPos; bgColor: string }>`
    position: absolute;
    background: ${(props) => props.bgColor};
    color: white;
    border-radius: ${({ pos }) => (pos === 'bottomright' ? 4 : 0)}px 0px ${({ pos }) => (pos === 'topleft' ? 4 : 0)}px
        0px;
    font-size: 90%;
    height: 1.3em;
    padding-right: 0.3em;
    padding-left: 0.3em;
    top: ${({ pos }) => (pos === 'topleft' ? 0 : 'initial')};
    left: ${({ pos }) => (pos === 'topleft' ? 0 : 'initial')};
    bottom: ${({ pos }) => (pos === 'bottomright' ? 0 : 'initial')};
    right: ${({ pos }) => (pos === 'bottomright' ? 0 : 'initial')};
`

const CornerContent = styled.span`
    position: relative;
    top: -0.15em;
`
