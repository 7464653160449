import type { ITeacher } from 'common-api'
import type { IScheduleServiceTeacher } from 'meitner-api'
import { Endpoints } from '../../services/Endpoints'
import { emptyToDefault } from './export-util'

export const listDstTeachers = async (targetScheduleId: string) =>
    Endpoints.meitnerApi
        .scheduleServiceListTeachers({
            schedule_id: targetScheduleId,
            pagination: { page: 0, size: 0 }
        })
        .then((response) => response.schedule_teachers)

const commonFields = (srcTeacher: ITeacher) => ({
    first_name: emptyToDefault(srcTeacher.firstName, '-'),
    last_name: emptyToDefault(srcTeacher.lastName, '-'),
    signature: srcTeacher.teacherSchoolId,
    external_id: srcTeacher.teacherId
})

export const createDstTeacher = async (targetScheduleId: string, srcTeacher: ITeacher) =>
    Endpoints.meitnerApi
        .scheduleServiceCreateTeacher({
            ...commonFields(srcTeacher),
            schedule_id: targetScheduleId,
            identity_number: '',
            meitner_id: null
        })
        .then((response) => response.created.id!)

export const deleteDstTeacher = async (teacherId: string) =>
    Endpoints.meitnerApi.scheduleServiceDeleteTeacher({
        id: teacherId
    })

export const updateDstTeacher = async (srcTeacher: ITeacher, dstTeacher: IScheduleServiceTeacher) =>
    Endpoints.meitnerApi
        .scheduleServiceUpdateTeacher({
            ...dstTeacher,
            ...commonFields(srcTeacher)
        })
        .then((response) => response.updated.id!)
