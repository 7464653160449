import { TeacherBulkUpdatePreviewProps } from './types'
import { conjureTeacherFromTeachers, sortedTeachersFromSchedule } from '../TeacherGrid/hooks'
import { diff, teacherCmp } from '../TeacherGrid/diff'
import { parseTeacherRows } from '../TeacherGrid/teacherData'
import { TeacherListDiff } from './TeacherListDiff'
import { Alert, Flex } from 'antd'
import { SimpleProblemList } from './SimpleProblemList'

import { toTranslate } from '../../../../../../utils/miscUtil'
import { useLocalSchedule } from '../../../../../../store/schedule/hooks'

export const TeacherBulkUpdatePreview = ({ teacherGridData, validationErrors }: TeacherBulkUpdatePreviewProps) => {
    const schedule = useLocalSchedule()
    const sortedTeachers = sortedTeachersFromSchedule(schedule)
    const currentTeachers = conjureTeacherFromTeachers(sortedTeachers)
    const currentSubjects = schedule.getSubjects().map((s) => s.getConjureObject())

    const newTeachers = parseTeacherRows(currentTeachers, currentSubjects, teacherGridData)
    const diffToPreview = diff(currentTeachers, newTeachers, (t) => t.teacherId, teacherCmp)

    return (
        <Flex vertical gap={8}>
            <TeacherListDiff diff={diffToPreview} />
            {validationErrors.length > 0 && (
                <Alert
                    showIcon
                    type="error"
                    message={toTranslate('Följande fel måste åtgärdas')}
                    description={<SimpleProblemList problems={validationErrors} />}
                />
            )}
        </Flex>
    )
}
