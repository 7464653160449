import { Button, Flex } from 'antd'
import { useNavigate } from 'react-router'
import openDaysImage from './open-days.png'
import periodsImage from './periods.png'
import { ScreenshotImage } from '../ScreenshotImage'
import { HelpPanelDivider } from '../HelpPanelDivider'
import { PeriodsHelpPanelProps } from './types'
import { ExportOutlined } from '@ant-design/icons'

export const PeriodsHelpPanel = (props: PeriodsHelpPanelProps) => {
    const navigate = useNavigate()

    return (
        <div>
            <p>
                För att programmet ska veta vilka dagar som lektioner får förekomma på måste du ange vilka dagar skolan
                har öppet. För att lätt kunna ange vilka veckor en lektion ska äga rum i så anväder du perioder.
                Inställningar för dagar och perioder hittar du under Perioder.
            </p>
            <Flex justify="space-around" style={{ marginTop: '2em' }}>
                <Button
                    icon={<ExportOutlined />}
                    type="primary"
                    onClick={() => {
                        props.onNavigateAway()
                        navigate('/periods')
                    }}
                >
                    Gå till perioder
                </Button>
            </Flex>
            <HelpPanelDivider />
            <p>Vilka dagar skolan har öppet anges i kalendern till vänster. Blå dag betyder att skolan har öppet.</p>
            <ScreenshotImage src={openDaysImage} />
            <HelpPanelDivider />
            <p>
                Perioder kan beskrivas som "förval av veckor" och används för att ange vilka veckor som en lektion ska
                äga rum i.
            </p>
            <p>
                Visas veckor finns skapade i förväg, men om din skola till exempel delar upp läsåret i fyra perioder
                (två på höstterminen och två på vårterminen) så kan du skapa perioderna P1, P2, P3 och P4 själv.
            </p>
            <ScreenshotImage src={periodsImage} />
            <p>
                <strong>Exempel:</strong> En lektion i träslöjd ska gå alla veckor på höstterminen, utom vecka 44. Du
                skapar en period som kallas HT, och väljer alla veckor som ska ingå. På träslöjdslektionen väljer du
                sedan perioden HT. Tränslöjdslektionen kommer nu finnas på alla periodens veckor.
            </p>
        </div>
    )
}
