export class AppError extends Error {
    constructor(
        msg: string,
        readonly details: any = {}
    ) {
        super(msg)
    }
}

// https://mariusschulz.com/blog/assertion-functions-in-typescript
export function assertNonNullish<T>(value: T, message: string): asserts value is NonNullable<T> {
    if (value === null || value === undefined) {
        throw new AppError(message)
    }
}
