import { Line } from '@ant-design/charts'
import styled from '@emotion/styled'
import { Flex } from 'antd'
import type { IWeekSelection } from 'common-api'
import { groupBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { WeekSelectionInput } from '../../components/lectures/WeekSelectionInput'
import { WeekSelectionAccessor } from '../../schedule-access/scheduleAccessWrappers'
import type { ApplicationState } from '../../store'
import { useLocalSchedule } from '../../store/schedule/hooks'
import { setWeekSelection } from '../../store/weekselector/actions'
import TypeScale from '../../styles/TypeScale'
import { isClassStudentGroup } from '../../utils/studentGroupUtil'
import { useNameOfDay } from '../../utils/weekUtil'
import { VISIBLE_DAYS } from '../schedule/components/util'
import { dayGraphs } from './utils'

const PageContent = styled('article')`
    display: flex;
    column-gap: 20px;
`

export const CanteenLoadIndexPage = () => {
    const schedule = useLocalSchedule()
    const weekSelection = useSelector<ApplicationState, IWeekSelection>((state) => state.weekSelection)
    const dispatch = useDispatch()
    const classStudentGroups = schedule.getStudentGroups().filter(isClassStudentGroup)
    const allScheduledLectures = schedule.getLectures().filter((lecture) => lecture.getDayAndTime() !== undefined)
    const lecturesByDay = groupBy(allScheduledLectures, (l) => l.getDayAndTime()!.day)
    const { t } = useTranslation()
    const { getDayName } = useNameOfDay()

    const selectedWeeks = new WeekSelectionAccessor(schedule, weekSelection).getWeeks().map((w) => w.valueOf())

    return (
        <PageContent>
            <div style={{ padding: '20px', width: '100%' }}>
                <Flex>
                    <h1 style={{ flex: 1 }} className={TypeScale.Heading_XL}>
                        {t('CanteenLoadDuringLunch')}
                    </h1>
                    <div style={{ flexShrink: 1 }}>
                        <WeekSelectionInput
                            value={weekSelection}
                            onChange={(newWeekSelection) => dispatch(setWeekSelection(newWeekSelection))}
                            layout="horizontal"
                        />
                    </div>
                </Flex>
                {VISIBLE_DAYS.map((day) => (
                    <div key={day} style={{ marginTop: '4em' }}>
                        <h2>{getDayName(day)}</h2>
                        <Line
                            data={dayGraphs(classStudentGroups, lecturesByDay[day] || [], selectedWeeks)}
                            autoFit
                            legend={false}
                            seriesField="week"
                            xField="hhmm"
                            yField="load"
                            smooth
                        />
                    </div>
                ))}
            </div>
        </PageContent>
    )
}
