import { useDispatch } from 'react-redux'
import type { EventGroupId } from '../../../commonTypes'
import { setLectureSelection } from '../../../store/lectureselector/actions'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { toTranslate } from '../../../utils/miscUtil'
import { NonInput } from '../../forms/NonInput'

type SinglePartialBlockInputMessageProps = {
    eventGroupId: EventGroupId
    numLecturesSelected: number
}

export const SinglePartialBlockInputMessage = ({
    eventGroupId,
    numLecturesSelected
}: SinglePartialBlockInputMessageProps) => {
    const schedule = useLocalSchedule()
    const dispatch = useDispatch()

    const eventGroup = schedule.findEventGroup(eventGroupId)
    const eventGroupLectureIds = eventGroup.getLectures().map((l) => l.getLectureId())

    return (
        <NonInput>
            {toTranslate(`${numLecturesSelected} av ${eventGroupLectureIds.length} lektioner i blocket valda.`)}{' '}
            <a onClick={() => dispatch(setLectureSelection(eventGroupLectureIds))}>
                {toTranslate('Välj blockets alla lektioner')}
            </a>{' '}
            {toTranslate('för att redigera dag och tid.')}
        </NonInput>
    )
}
