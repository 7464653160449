export const SubjectsImportHelpPanel = () => (
    <>
        <h2>Ämnen</h2>
        <p>
            Klicka på Ämnen i menyn och klicka på import-knappen längst upp till höger. I tabellen som visas klistrar du
            in datan för alla ämnen.
        </p>
        <p>Beskrivning av kolumnerna kommer inom kort.</p>
    </>
)
