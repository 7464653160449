import { Space, TimePicker } from 'antd'
import dayjs, { type Dayjs } from 'dayjs'
import { dayjsFromTimeOfDay, timeOfDayFromDayjs } from '../../../utils/DayAndTimeUtil'
import { toTranslate } from '../../../utils/miscUtil'
import type { LunchBreakSettingsInputProps } from './types'
import { MinutesInput } from '../../MinutesInput'

const { RangePicker } = TimePicker

const defaultLunchPeriod: [Dayjs, Dayjs] = [
    dayjs().set('hour', 11).set('minute', 0),
    dayjs().set('hour', 13).set('minute', 0)
]
const defaultMinLunchMinutes = 30

export const LunchBreakSettingsInput = ({ onChange, value }: LunchBreakSettingsInputProps) => {
    const lunchPeriod: [Dayjs, Dayjs] =
        value === undefined
            ? defaultLunchPeriod
            : [dayjsFromTimeOfDay(value.startOfLunchPeriod), dayjsFromTimeOfDay(value.endOfLunchPeriod)]
    const minLunchMinutes = value?.minLunchMinutes || defaultMinLunchMinutes

    const onChangeWrapper = (
        newMinLunchMinutes: number | undefined = minLunchMinutes,
        [newStartOfLunchPeriod, newEndOfLunchPeriod]: [Dayjs | null, Dayjs | null] | undefined = defaultLunchPeriod
    ) => {
        if (typeof onChange === 'function') {
            onChange({
                startOfLunchPeriod: timeOfDayFromDayjs(dayjs(newStartOfLunchPeriod)),
                endOfLunchPeriod: timeOfDayFromDayjs(dayjs(newEndOfLunchPeriod)),
                minLunchMinutes: newMinLunchMinutes
            })
        }
    }

    return (
        <Space direction="vertical">
            <Space>
                <div>{toTranslate('Minst')}</div>
                <MinutesInput
                    onChange={(newMinutes) => onChangeWrapper(newMinutes, lunchPeriod)}
                    value={minLunchMinutes}
                />
                <div>{toTranslate('minuter')}</div>
            </Space>
            <Space>
                <div>{toTranslate('i tidsperioden')}</div>
                <RangePicker
                    style={{ width: '13em' }}
                    format="HH:mm"
                    hideDisabledOptions
                    disabledTime={() => ({
                        disabledHours: () => [0, 1, 2, 3, 4, 5, 19, 20, 21, 22, 23]
                    })}
                    minuteStep={5}
                    value={lunchPeriod}
                    onChange={(newValue) => onChangeWrapper(undefined, newValue)}
                />
            </Space>
        </Space>
    )
}
