export enum AutoSchedulerActionTypes {
    SET_AUTO_SCHEDULER_STATE = 'autoScheduler/SET_STATE',
    HANDLE_LOCALLY_TRIGGERED_AUTO_SCHEDULER_STATE_UPDATE = 'autoScheduler/HANDLE_LOCALLY_TRIGGERED_AUTO_SCHEDULER_STATE_UPDATE',

    SUBSCRIBE = 'autoScheduler/subscribe',
    UNSUBSCRIBE = 'autoScheduler/unsubscribe'
}

export type AutoSchedulerState = {
    runState: AutoSchedulerRunningState
    taRunState: AutoSchedulerRunningState
    subscriptionCount: number
}

export enum AutoSchedulerRunningState {
    RUNNING = 'RUNNING',
    PAUSED = 'PAUSED',
    UNKNOWN = 'UNKNOWN'
}
