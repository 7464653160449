import { CloseOutlined } from '@ant-design/icons'
import { Collapse } from 'antd'
import { useState } from 'react'
import { CourseRoundAccessor } from '../../schedule-access/scheduleAccessWrappers'
import TypeScale from '../../styles/TypeScale'
import { toTranslate } from '../../utils/miscUtil'
import { CourseRoundDetails } from '../course-rounds/CourseRoundDetails'
import IconButton from '../IconButton'
import classes from './style.module.css'

type SelectedCourseRoundPanelProps = {
    selectedCourseRounds: CourseRoundAccessor[]
    onRemove?: () => void
}

function SelectedCourseRoundPanel({ selectedCourseRounds, onRemove }: SelectedCourseRoundPanelProps) {
    const [expanded, setExpanded] = useState<string[]>(['1', '2'])

    if (selectedCourseRounds.length === 0) {
        return null
    }

    return (
        <div className={classes.panelSlider}>
            <Collapse
                defaultActiveKey={expanded}
                bordered={false}
                style={{ height: '100%' }}
                onChange={(keys) => {
                    if (Array.isArray(keys)) {
                        setExpanded(keys)
                    }
                }}
            >
                <div className={classes.panelSlider__header}>
                    <h2 className={TypeScale.Heading_MD}>{toTranslate('Redigera kursomgång')}</h2>
                    <IconButton aria-label="close" pill variant="tertiary" onClick={onRemove}>
                        <CloseOutlined />
                    </IconButton>
                </div>
                <CourseRoundDetails
                    key={selectedCourseRounds.map((cr) => cr.getCourseRoundId()).join(',')}
                    courseRounds={selectedCourseRounds}
                />
            </Collapse>
        </div>
    )
}

export default SelectedCourseRoundPanel
