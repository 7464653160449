import { Popover } from 'antd'
import type { IProblemWithScore } from 'common-api'
import { ProblemsList } from './ProblemsList'
import { ProblemListProblem } from './ProblemsList/types'

type ProblemListPopoverProps = {
    problems: ProblemListProblem[]
    open?: boolean
}

export const ProblemListPopover = (props: React.PropsWithChildren<ProblemListPopoverProps>) => (
    <Popover
        placement="right"
        open={props.open}
        content={
            <div style={{ maxWidth: '400px' }}>
                <ProblemsList border={false} problems={props.problems} />
            </div>
        }
    >
        {props.children}
    </Popover>
)
