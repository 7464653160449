import type { WeekSelectionAction } from './actions'
import { WeekSelectionActionTypes } from './types'
import { IWeekSelection } from 'common-api'
import { initialState as initialScheduleState } from '../schedule/reducer'

export const initialState: IWeekSelection = {
    weekSelectionPresetId: initialScheduleState.schedule.getWeekSelectionPresets()[0].weekSelectionPresetId,
    includes: [],
    excludes: []
}

export const weekSelectionReducer = (state = initialState, action: WeekSelectionAction): IWeekSelection => {
    switch (action.type) {
        case WeekSelectionActionTypes.SET_WEEK_SELECTION:
            return action.payload
        default:
            return state
    }
}
