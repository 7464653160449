import { DeleteOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useState } from 'react'
import { TableHeader } from '../../styled'

type PresetHeaderProps = {
    displayName: string
    onDelete: () => void
}

export const PresetHeader = (props: PresetHeaderProps) => {
    const [deleteButtonVisible, setDeleteButtonVisible] = useState(false)

    return (
        <TableHeader>
            <div
                style={{
                    transform: 'rotate(-45deg)',
                    transformOrigin: 'center left',
                    whiteSpace: 'nowrap',
                    textAlign: 'left',
                    width: '20em'
                }}
                onMouseEnter={() => setDeleteButtonVisible(true)}
                onMouseLeave={() => setDeleteButtonVisible(false)}
            >
                <div style={{ display: 'inline-block' }}>{props.displayName}</div>
                <Button
                    danger
                    onClick={props.onDelete}
                    type="link"
                    style={{
                        padding: '.5em',
                        visibility: deleteButtonVisible ? 'visible' : 'hidden'
                    }}
                >
                    <DeleteOutlined />
                </Button>
            </div>
        </TableHeader>
    )
}
