import type { CourseRoundId, RoomId, StudentGroupId, TeacherId } from '../../../../commonTypes'
import type { StringColumnFilterItem } from '../../../../components/FilterableFilterDropdown/types'
import { comparing } from '../../../../utils/compareUtil'
import { isSubsequenceIgnoreCase } from './searchUtil'

export const teacherOptionValue = (teacherId: TeacherId) => `teacher:${teacherId}`

export const studentGroupOptionValue = (studentGroupId: StudentGroupId) => `studentGroup:${studentGroupId}`

export const courseRoundOptionValue = (courseRoundId: CourseRoundId) => `courseRound:${courseRoundId}`

export const roomOptionValue = (roomId: RoomId) => `room:${roomId}`

export const optionFilter = (input: string, option: any) => {
    const optionString = option.children ? option.children : option.label
    return isSubsequenceIgnoreCase(input, optionString)
}

export const columnFilterItemComparator = comparing((cfi: StringColumnFilterItem) => cfi.text)

export const allEqual = <T>(arr: T[]) => arr.every((e) => e === arr[0])
