/* eslint-disable prettier/prettier */
import styled from '@emotion/styled'
import { useRef } from 'react'
import { useDragLayer } from 'react-dnd'
import { useLocalSchedule } from '../../store/schedule/hooks'
import brandColors from '../../styles/colors/brandColors'
import { DndItemTypes } from '../../utils/DndItemTypes'
import { CourseRoundCard } from './CourseRoundCard'
import { Positioned } from './Positioned'
import { mousePositionToRow } from './util'

interface DragLayerProps {
    maxes: number[]
    maxVisibleHours: number
}

export const DragLayer = (props: DragLayerProps) => {
    const schedule = useLocalSchedule()

    const ref = useRef<HTMLDivElement>(null)

    const { itemType, item, currentOffset } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialClientOffset(),
        currentOffset: monitor.getClientOffset()
    }))

    const hoveredRow = mousePositionToRow(currentOffset, ref.current?.getBoundingClientRect())

    function renderItem() {
        switch (itemType) {
            case DndItemTypes.EVENT:
                return props.maxes.map((max, row) =>
                    row !== item.row ? (
                        <Positioned
                            key={row}
                            row={row}
                            startHour={max}
                            totalHours={schedule.findCourseRound(item.courseRoundId).getTotalHours()}
                            maxVisibleHours={props.maxVisibleHours}
                        >
                            {hoveredRow === row ? (
                                <CourseRoundCard isInFilter courseRoundId={item.courseRoundId} />
                            ) : (
                                <AddZone />
                            )}
                        </Positioned>
                    ) : undefined
                )
            default:
                return null
        }
    }

    return <DragLayerWrapper ref={ref}>{renderItem()}</DragLayerWrapper>
}

const DragLayerWrapper = styled('div')`
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    padding-right: 1px;
    padding-left: 1px;
`

const AddZone = styled('div')`
    background: ${brandColors.gray5};
    border: 2px dashed ${brandColors.gray75};
    overflow: hidden;

    height: 100%;
    font-size: 0.7em;
    padding: 0.2em;
`
