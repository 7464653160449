import type { FilterDropdownProps } from 'antd/es/table/interface'
import { useSchedule } from '../../../../../store/schedule/hooks'
import { FilterableFilterDropdown } from '../../../../../components/FilterableFilterDropdown'
import { toTranslate } from '../../../../../utils/miscUtil'

export const TeacherQualificationFilterDropdown = (props: FilterDropdownProps) => {
    const schedule = useSchedule()
    const allSubjects = schedule.getSubjects().map((s) => ({ value: s.getSubjectId(), text: s.getName() }))
    return (
        <FilterableFilterDropdown
            {...props}
            allFilters={allSubjects}
            noKeysAvailableDescription={toTranslate('Inga ämnen')}
        />
    )
}
