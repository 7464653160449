import type { ActionType } from 'typesafe-actions'
import { action } from 'typesafe-actions'
import type { LectureId, ReservedTimeId } from '../../commonTypes'
import type * as lectureSelectionActions from './actions'
import { LectureSelectionActionTypes } from './types'

export const toggleLectureSelection = (lectureId: LectureId) =>
    action(LectureSelectionActionTypes.TOGGLE_LECTURE_SELECTION, lectureId)

export const setLectureSelection = (lectureIds: LectureId[]) =>
    action(LectureSelectionActionTypes.SET_LECTURE_SELECTION, lectureIds)

export const clearLectureSelection = () => action(LectureSelectionActionTypes.SET_LECTURE_SELECTION, [])

export const toggleReservedTimeSelection = (reservedTimeId: ReservedTimeId) =>
    action(LectureSelectionActionTypes.TOGGLE_RESERVED_TIME_SELECTION, reservedTimeId)

export const setReservedTimeSelection = (reservedTimeIds: ReservedTimeId[]) =>
    action(LectureSelectionActionTypes.SET_RESERVED_TIME_SELECTION, reservedTimeIds)

export const clearReservedTimeSelection = () => action(LectureSelectionActionTypes.SET_RESERVED_TIME_SELECTION, [])

export type LectureSelectionAction = ActionType<typeof lectureSelectionActions>
