import type { FilterDropdownProps } from 'antd/es/table/interface'
import { useSchedule } from '../../../store/schedule/hooks'
import { toTranslate } from '../../../utils/miscUtil'
import { FilterableFilterDropdown } from '../../FilterableFilterDropdown'

export const StudentGroupLabelFilterDropdown = (props: FilterDropdownProps) => {
    const schedule = useSchedule()
    const allLabelFilters = schedule.getStudentGroupLabels().map((l) => ({ value: l, text: l }))
    return (
        <FilterableFilterDropdown
            {...props}
            allFilters={allLabelFilters}
            noKeysAvailableDescription={toTranslate('Inga etiketter')}
        />
    )
}
