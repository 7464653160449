import { useState } from 'react'
import type { CourseId } from '../../commonTypes'
import TablePage from '../../components/PageContent'
import { CourseDetails } from '../../components/courses/CourseDetails'
import { useLocalSchedule } from '../../store/schedule/hooks'
import { toTranslate } from '../../utils/miscUtil'
import { CourseActionsPanel } from './components/CourseActionsPanel'
import { CoursesTable } from './components/CoursesTable'

const CoursesIndexPage = () => {
    const schedule = useLocalSchedule()
    const [selectedRowKeys, setSelectedRowKeys] = useState<CourseId[]>([])

    // Some selected courses may no longer exists (for example if they were deleted in another browser tab).
    const validSelectedRowKeys = selectedRowKeys.filter((selectedCourseId) =>
        schedule.doesCourseIdExist(selectedCourseId)
    )

    const selectedCourses = validSelectedRowKeys.map((courseId) => schedule.findCourse(courseId))

    const detailsPanel = (
        <CourseDetails key={schedule.getVersion() + validSelectedRowKeys.join(',')} courses={selectedCourses} />
    )

    const actionsPanel = <CourseActionsPanel courses={selectedCourses} onDelete={() => setSelectedRowKeys([])} />

    return (
        <TablePage
            selectedRowKeys={validSelectedRowKeys}
            onEscape={() => setSelectedRowKeys([])}
            table={<CoursesTable selectedRowKeys={validSelectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} />}
            sidePanels={[
                { key: 'edit', header: toTranslate('Redigera'), panel: detailsPanel },
                {
                    key: 'actions',
                    header: toTranslate('Åtgärder'),
                    panel: actionsPanel
                }
            ]}
        />
    )
}

export default CoursesIndexPage
