export enum TableEditMode {
    VIEW_MODE = 'VIEW_MODE',
    EDIT_MODE = 'EDIT_MODE'
}

export type TableEditModeSwitchProps = {
    disabled?: boolean
    mode: TableEditMode
    setMode: (newMode: TableEditMode) => void
}
