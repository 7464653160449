import styled from '@emotion/styled'
import type { ReactNode } from 'react'
import { memo } from 'react'

const WeekHeaderWrapper = styled.div`
    text-align: center;
    font-weight: bold;
    grid-column-start: 2;
`

type WeekHeaderProps = {
    children: ReactNode
}

const WeekHeader = ({ children }: WeekHeaderProps) => (
    <WeekHeaderWrapper>
        <div
            style={{
                display: 'flex',
                height: '100%',
                alignItems: 'center',
                backgroundColor: 'white',
                borderRight: '1px solid #f0f0f0',
                borderLeft: '1px solid #f0f0f0'
            }}
        >
            <div style={{ textAlign: 'center', width: '100%' }}>{children}</div>
        </div>
    </WeekHeaderWrapper>
)

export default memo(WeekHeader)
