import { CSSProperties } from 'react'

export const tableCardHeaderStyle: CSSProperties = {
    padding: '0.7em',
    borderBottom: '1px solid #f0f0f0'
}

export const tableCardBodyStyle: CSSProperties = {
    padding: 0,
    overflowY: 'scroll',
    height: '100%'
}
