import { css } from '@emotion/core'

export const antTweaks = css`
    // In Ant Lists where there's no meta description, the margins cause the
    // items to look a bit off vertically. This snippet removes some margins.
    //
    // If this causes things to look weird if there _is_ a meta
    ul.ant-list-item-action {
        margin: 0;
    }

    h4.ant-list-item-meta-title {
        margin-bottom: 0px;
    }
`
