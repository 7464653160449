import { SimpleTeacherListDiffProps } from './types'
import { toTranslate } from '../../../../../../../../utils/miscUtil'
import { useTranslation } from 'react-i18next'
import { TeacherChangeList } from './TeacherChangeList'
import { teacherDisplayName } from '../../util'

const propertyDiffLi = (label: string, oldValue: string, newValue: string) =>
    oldValue !== newValue && (
        <li>
            <span style={{ textWrap: 'nowrap' }}>
                {label}: <del>{oldValue}</del> ⇒ {newValue}
            </span>
        </li>
    )

export const SimpleTeacherListDiff = ({ diff }: SimpleTeacherListDiffProps) => {
    const { t } = useTranslation()

    const hasDeletions = diff.deleted.length > 0
    const hasCreations = diff.created.length > 0
    const hasUpdates = diff.updated.length > 0

    // Show headers only if there's more than one section
    const showHeaders = (hasDeletions ? 1 : 0) + (hasCreations ? 1 : 0) + (hasUpdates ? 1 : 0) > 1

    return (
        <div>
            {hasCreations && (
                <TeacherChangeList showHeader={showHeaders} header={toTranslate('Nya lärare')}>
                    {diff.created.map((t) => (
                        <li key={t.teacherId}>{teacherDisplayName(t)}</li>
                    ))}
                </TeacherChangeList>
            )}
            {hasUpdates && (
                <TeacherChangeList showHeader={showHeaders} header={toTranslate('Uppdaterade lärare')}>
                    {diff.updated.map(([prev, niew]) => (
                        <li key={prev.teacherId}>
                            {teacherDisplayName(prev)}
                            <ul>
                                {propertyDiffLi(t('Signature'), prev.teacherSchoolId, niew.teacherSchoolId)}
                                {propertyDiffLi(t('FirstName'), prev.firstName, niew.firstName)}
                                {propertyDiffLi(t('LastName'), prev.lastName, niew.lastName)}
                                {propertyDiffLi(
                                    t('Employment'),
                                    `${prev.workPercentage} %`,
                                    `${niew.workPercentage} %`
                                )}
                            </ul>
                        </li>
                    ))}
                </TeacherChangeList>
            )}
            {hasDeletions && (
                <TeacherChangeList showHeader={showHeaders} header={toTranslate('Borttagna lärare')}>
                    {diff.deleted.map((t) => (
                        <li key={t.teacherId}>{teacherDisplayName(t)}</li>
                    ))}
                </TeacherChangeList>
            )}
        </div>
    )
}
