import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import type { ApplicationState } from '../../../../store'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { userAddedScheduleSelector, userRemovedScheduleSelector } from '../../../../store/scheduleselector/actions'
import type { ScheduleSelectionState } from '../../../../store/scheduleselector/types'
import { visitScheduleSelector } from '../../../../store/scheduleselector/types'
import { comparing } from '../../../../utils/compareUtil'
import { toTranslate } from '../../../../utils/miscUtil'
import { teacherDisplayName } from '../../../../utils/scheduleUtils'
import { isClassStudentGroup } from '../../../../utils/studentGroupUtil'
import {
    courseRoundOptionValue,
    optionFilter,
    roomOptionValue,
    studentGroupOptionValue,
    teacherOptionValue
} from './utils'
import { useState } from 'react'

type ScheduleSearchSelectorProps = {
    multiple?: boolean
}
type FindSelectedScheduleOptions = {
    selectedSchedules: string[]
    scheduleID: string
    multiple: boolean
}

function findSelectedSchedule({ selectedSchedules, scheduleID, multiple }: FindSelectedScheduleOptions) {
    if (multiple) {
        return true
    }

    return !selectedSchedules.includes(scheduleID)
}

export const ScheduleSearchSelector = ({ multiple = true }: ScheduleSearchSelectorProps) => {
    const dispatch = useDispatch()
    const schedule = useLocalSchedule()
    const [open, setOpen] = useState(false)

    const scheduleSelection = useSelector<ApplicationState, ScheduleSelectionState>((state) => state.scheduleSelection)

    const { t } = useTranslation()

    const currentlySelectedValues = scheduleSelection.selectedSchedules.map((ss) => {
        return visitScheduleSelector(ss, {
            studentGroupSelector: (s) => studentGroupOptionValue(s.studentGroupId),
            teacherSelector: (s) => teacherOptionValue(s.teacherId),
            courseRoundSelector: (s) => courseRoundOptionValue(s.courseRoundId),
            roomSelector: (s) => roomOptionValue(s.roomId),
            allSelector: () => 'all'
        })
    })

    const teacherOptions = schedule
        .getTeachers()
        .map((t) => ({
            value: teacherOptionValue(t.getTeacherId()),
            label: teacherDisplayName(t),
            selector: { teacherId: t.getTeacherId() }
        }))
        .filter((opt) =>
            findSelectedSchedule({
                scheduleID: `teacher:${opt.selector.teacherId}`,
                selectedSchedules: currentlySelectedValues,
                multiple
            })
        )
        .sort(comparing((opt) => opt.label))

    const studentGroupOptions = schedule
        .getStudentGroups()
        .filter(isClassStudentGroup)
        .map((sg) => ({
            value: studentGroupOptionValue(sg.getStudentGroupId()),
            label: sg.getDisplayName(),

            selector: { studentGroupId: sg.getStudentGroupId() }
        }))
        .filter((opt) =>
            findSelectedSchedule({
                scheduleID: `student:${opt.selector.studentGroupId}`,
                selectedSchedules: currentlySelectedValues,
                multiple
            })
        )
        .sort(comparing((opt) => opt.label))

    const courseRoundOptions = schedule
        .getCourseRounds()
        .map((cr) => ({
            value: courseRoundOptionValue(cr.getCourseRoundId()),
            label: cr.getDisplayName(),
            selector: { courseRoundId: cr.getCourseRoundId() }
        }))
        .filter((opt) =>
            findSelectedSchedule({
                scheduleID: `courseRound:${opt.selector.courseRoundId}`,
                selectedSchedules: currentlySelectedValues,
                multiple
            })
        )
        .sort(comparing((opt) => opt.label))

    const roomOptions = schedule
        .getRooms()
        .map((r) => ({
            value: roomOptionValue(r.getRoomId()),
            label: r.getName(),
            selector: { roomId: r.getRoomId() }
        }))
        .filter((opt) =>
            findSelectedSchedule({
                scheduleID: `room:${opt.selector.roomId}`,
                selectedSchedules: currentlySelectedValues,
                multiple
            })
        )
        .sort(comparing((opt) => opt.label))

    const allOption = {
        value: 'all',
        label: t('All'),
        selector: { markerField: true }
    }

    const onDeselect = (optionValue: string) => dispatch(userRemovedScheduleSelector(selectorsByOptId[optionValue]))
    const onSelect = (optionValue: string) => {
        setOpen(false)
        return dispatch(userAddedScheduleSelector(selectorsByOptId[optionValue]))
    }

    // Create selector lookup dict
    const allOptions = [...teacherOptions, ...studentGroupOptions, ...courseRoundOptions, ...roomOptions, allOption]

    const selectorsByOptId = Object.assign({}, ...allOptions.map((opt) => ({ [opt.value]: opt.selector })))

    const options = [
        { label: t('Teacher'), options: teacherOptions },
        { label: toTranslate('Klasser'), options: studentGroupOptions },
        { label: toTranslate('Kursomgångar'), options: courseRoundOptions },
        { label: toTranslate('Salar'), options: roomOptions },
        { label: t('Other'), options: [allOption] }
    ]

    return (
        <Select
            placeholder={t('SearchPlaceholders.SearchSchedule')}
            style={{ width: multiple ? '30em' : '100%', maxWidth: '30em' }}
            mode={multiple ? 'multiple' : undefined}
            filterOption={optionFilter}
            onDropdownVisibleChange={setOpen}
            open={open}
            onSelect={onSelect}
            onDeselect={onDeselect}
            value={multiple ? currentlySelectedValues : undefined}
            maxTagCount={5}
            showSearch
            options={options}
        />
    )
}
