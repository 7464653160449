import { Button } from 'antd'
import React from 'react'
import type { ResetTableContentButtonProps } from './types'
import { toTranslate } from '../../../../../../../../utils/miscUtil'
import { UndoOutlined } from '@ant-design/icons'

export const ResetTableContentButton = ({ onReset }: ResetTableContentButtonProps) => (
    <Button icon={<UndoOutlined />} onClick={onReset}>
        {toTranslate('Återställ innehåll')}
    </Button>
)
