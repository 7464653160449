import { useTranslation } from 'react-i18next'
import { StudentGroupLabelSelectorProps } from './types'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { ManyToManySelector } from '../../../../components/many-to-many-selector/ManyToManySelector'
import { StudentGroupId } from '../../../../commonTypes'
import { StudentGroupListTooltip } from '../StudentGroupListTooltip'

export const StudentGroupLabelSelector = ({ value, onChange }: StudentGroupLabelSelectorProps) => {
    const schedule = useLocalSchedule()
    const { t } = useTranslation()

    return (
        <ManyToManySelector<StudentGroupId, string>
            value={value}
            onChange={onChange}
            mode="tags"
            preexistingOptionValues={schedule.getStudentGroupLabels()}
            optionLabelFn={(label) => label}
            partialCoverSuffix={(studentGroupIds) => (
                <StudentGroupListTooltip
                    title={t('ThisLabelOnlyApplysToTheFollowingGroups')}
                    studentGroups={studentGroupIds.map((sgId) => schedule.findStudentGroup(sgId))}
                />
            )}
        />
    )
}
