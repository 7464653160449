import { StudentGroupId } from '../commonTypes'
import type { StudentGroupAccessor } from '../schedule-access/scheduleAccessWrappers'
import { IStudentGroup } from 'common-api'
import { containsAll, indexUniquelyBy } from './collections'
import { uniq } from 'lodash'

export const CLASS_SG_LABEL = 'Klass'

export function isClassStudentGroup(sg: StudentGroupAccessor) {
    return sg.getLabels().includes(CLASS_SG_LABEL)
}

type StudentGroupAndNonOverlappingStudentGroups = {
    sgId: StudentGroupId
    nonOverlappingSgIds: StudentGroupId[]
}

export const computeImplicitAndExplicitStudentGroupOverlaps = (
    sgId: StudentGroupId,
    studentGroups: IStudentGroup[]
): StudentGroupId[] => {
    const sgsBySgId = indexUniquelyBy(studentGroups, (sg) => sg.studentGroupId)
    return getAllImplicitlyAndExplicitlyOverlappingStudentGroups(sgId, sgsBySgId)
}

const getAllImplicitlyAndExplicitlyOverlappingStudentGroups = (
    sgId: StudentGroupId,
    sgsBySgId: Map<StudentGroupId, IStudentGroup>
): StudentGroupId[] => {
    const queue: StudentGroupAndNonOverlappingStudentGroups[] = []

    // Maps visited student groups to the, at the time of the visit, set of blocked nodes
    const visited = new Map<StudentGroupId, StudentGroupAndNonOverlappingStudentGroups>()

    queue.push({ sgId, nonOverlappingSgIds: sgsBySgId.get(sgId)!.nonOverlaps })

    while (queue.length > 0) {
        const visiting: StudentGroupAndNonOverlappingStudentGroups = queue.pop()!

        // Have we already visited this student group?
        if (visited.has(visiting.sgId)) {
            // We have been here, but do we have fewer blocked student groups this time around?
            const blockedLastVisit = visited.get(visiting.sgId)!.nonOverlappingSgIds
            const blockedThisVisit = visiting.nonOverlappingSgIds
            if (containsAll(blockedThisVisit, blockedLastVisit)) {
                continue
            }
        }

        visited.set(visiting.sgId, visiting)
        for (const overlappingSg of sgsBySgId.get(visiting.sgId)!.overlaps) {
            // Are we stepping into a blocked student group?
            //
            // The intuition here is that if we're on a path of overlapping student groups, A->B->C->D we're
            // "filtering down" the set of students in A by intersecting it with B, then with C, then with D. If we
            // encounter a non-overlapping group we're filtering down to the empty set, which means that the overlap
            // chain is terminated. So if B has a non-overlap with D, then A does not (when going through B) overlap
            // with D, which is what we're determining here.
            if (visiting.nonOverlappingSgIds.includes(overlappingSg)) {
                continue
            }

            // Put the (actually) overlapping neighbor on the queue
            queue.push({
                sgId: overlappingSg,
                nonOverlappingSgIds: uniq([
                    ...visiting.nonOverlappingSgIds,
                    ...sgsBySgId.get(visiting.sgId)!.nonOverlaps
                ])
            })
        }
    }
    return [...visited.keys()]
}
