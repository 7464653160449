import { DayOfWeek, ITimeOfDay } from 'common-api'
import type { XYCoord } from 'react-dnd'
import { ScheduleEvent } from '../ScheduleEvent'
import type { AllocatedLayoutSpec, LayoutSpec } from '../util'
import { positionToLayoutSpec } from '../util'

export type DragEventType = {
    event: ScheduleEvent
    layoutSpec: LayoutSpec
}

type LayoutSpecOptions = {
    currentOffset: XYCoord | null
    initialOffset: XYCoord | null
    dropBounds: DOMRect
    dragEvent: DragEventType
}

type LayoutSpecReturnType = AllocatedLayoutSpec | undefined

const getLayoutSpec = (
    startOfDay: ITimeOfDay,
    endOfDay: ITimeOfDay,
    { currentOffset, initialOffset, dropBounds, dragEvent }: LayoutSpecOptions,
    selectedWeekDays: DayOfWeek[]
): LayoutSpecReturnType => {
    if (!currentOffset || !initialOffset || !dropBounds || !dragEvent) {
        return undefined
    }

    const layoutSpec = positionToLayoutSpec({
        startOfDay,
        endOfDay,
        currentOffset,
        dropBounds,
        initialOffset,
        initialLayoutSpec: dragEvent.layoutSpec,
        eventDurationInMinutes: dragEvent.event.getDurationInMinutes(),
        selectedWeekDays
    })

    if (!layoutSpec) {
        return undefined
    }

    return layoutSpec
}

export { getLayoutSpec }
