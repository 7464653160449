import { createContext, useContext } from 'react'

type ModalContextState = {
    onClose: () => void
}

const ModalContext = createContext<ModalContextState>({
    onClose: () => {}
})

export const useModalContext = () => useContext(ModalContext)

export default ModalContext
