import type { ILecture, IReservedTime, ISchedule, IStudentGroup } from 'common-api'
import { IScheduleTransform } from 'common-api'
import { reject, without } from 'lodash'
import type { StudentGroupId, TeacherId } from '../commonTypes'
import { ScheduleAccessor } from '../schedule-access/scheduleAccessWrappers'
import type { ScheduleVersion } from '../store/schedule/types'
import { replaceOrThrow, togglePresence, updateOrThrow } from './collections'
import { AppError } from './errorutil'

export function upsert<E, K>(arr: E[], keyFn: (element: E) => K, newElement: E): E[] {
    const soughtKey = keyFn(newElement)
    const index = arr.findIndex((e) => keyFn(e) === soughtKey)
    const insertIndex = index === -1 ? arr.length : index
    return arr.toSpliced(insertIndex, 1, newElement)
}

export type VersionedScheduleTransform = IScheduleTransform & {
    transformId: string
    version?: ScheduleVersion
}

export const transformedEventIds = (transform: IScheduleTransform): string[] =>
    IScheduleTransform.visit(transform, {
        lectureTransform: (lu) => [lu.newLecture.lectureId],
        lectureDeleteTransform: (ldt) => [ldt.lectureId],
        roomTransform: () => [],
        roomDeleteTransform: () => [],
        studentGroupTransform: () => [],
        studentGroupDeleteTransform: () => [],
        teacherDeleteTransform: () => [],
        teacherTransform: () => [],
        subjectTransform: () => [],
        subjectDeleteTransform: () => [],
        courseTransform: () => [],
        courseDeleteTransform: () => [],
        courseRoundTransform: () => [],
        courseRoundDeleteTransform: () => [],
        scheduleSettingsTransform: () => [],
        weekSelectionPresetsTransform: () => [],
        weekSelectionPresetDeleteTransform: () => [],
        reservedTimeTransform: (t) => [t.newReservedTime.reservedTimeId],
        reservedTimeDeleteTransform: (t) => [t.reservedTimeId],
        eventGroupDeleteTransform: (t) => [t.eventGroupId],
        eventGroupTransform: (t) => t.newEventGroup.lectures.map((l) => l.lectureId),
        checklistStepsCompleted: (t) => [],
        bulkTransform: (transforms) => transforms.flatMap(transformedEventIds),
        unknown: (transform) => {
            throw new AppError('Unhandled transform', { transform })
        }
    })

export const applyAllTransforms = (schedule: ScheduleAccessor, transforms: IScheduleTransform[]): ScheduleAccessor =>
    new ScheduleAccessor(transforms.reduce(applyTransform, schedule.getConjureSchedule()))

function adjustOverlapsHelper(sgToAdjust: IStudentGroup, sgReference: IStudentGroup, prop: 'overlaps' | 'nonOverlaps') {
    // *Should* sgReference overlap with sgToAdjust?
    const refShouldBeIncluded =
        sgReference[prop].includes(sgToAdjust.studentGroupId) &&
        sgToAdjust.studentGroupId !== sgReference.studentGroupId

    // *Does* sgReference overlap with sgToAdjust?
    const refIsIncluded = sgToAdjust[prop].includes(sgReference.studentGroupId)

    // Return as-is or toggle overlap
    return refShouldBeIncluded === refIsIncluded
        ? sgToAdjust
        : {
              ...sgToAdjust,
              [prop]: togglePresence(sgToAdjust[prop], sgReference.studentGroupId)
          }
}

const adjustOverlaps = (sgToAdjust: IStudentGroup, sgReference: IStudentGroup) => {
    const adjustedForOverlaps = adjustOverlapsHelper(sgToAdjust, sgReference, 'overlaps')
    const adjustedForNonOverlaps = adjustOverlapsHelper(adjustedForOverlaps, sgReference, 'nonOverlaps')

    return adjustedForNonOverlaps
}

const removeFromOverlaps = (sgToAdjust: IStudentGroup, sgIdToRemove: StudentGroupId) => ({
    ...sgToAdjust,
    overlaps: without(sgToAdjust.overlaps, sgIdToRemove),
    nonOverlaps: without(sgToAdjust.nonOverlaps, sgIdToRemove)
})

const applyTransform = (schedule: ISchedule, transform: IScheduleTransform): ISchedule =>
    IScheduleTransform.visit(transform, {
        lectureTransform: ({ newLecture }) => ({
            ...schedule,
            eventGroups: updateOrThrow(
                schedule.eventGroups,
                (eg) => eg.lectures.find((l) => l.lectureId === newLecture.lectureId) !== undefined,
                (eg) => ({
                    ...eg,
                    lectures: replaceOrThrow(eg.lectures, (l) => l.lectureId, newLecture)
                })
            )
        }),
        lectureDeleteTransform: ({ lectureId }) => ({
            ...schedule,
            eventGroups: reject(
                schedule.eventGroups.map((eventGroup) => ({
                    ...eventGroup,
                    lectures: reject(eventGroup.lectures, (l) => l.lectureId === lectureId)
                })),
                (eg) => eg.lectures.length === 0
            )
        }),
        eventGroupTransform: ({ newEventGroup }) => ({
            ...schedule,
            eventGroups: upsert(schedule.eventGroups, (eg) => eg.eventGroupId, newEventGroup)
        }),
        courseDeleteTransform: ({ courseId }) => ({
            ...schedule,
            courses: reject(schedule.courses, (c) => c.courseId === courseId)
        }),
        courseTransform: ({ newCourse }) => ({
            ...schedule,
            courses: upsert(schedule.courses, (c) => c.courseId, newCourse)
        }),
        subjectDeleteTransform: ({ subjectId }) => ({
            ...schedule,
            subjects: reject(schedule.subjects, (s) => s.subjectId === subjectId)
        }),
        subjectTransform: ({ newSubject }) => ({
            ...schedule,
            subjects: upsert(schedule.subjects, (s) => s.subjectId, newSubject)
        }),
        roomTransform: ({ newRoom }) => ({
            ...schedule,
            rooms: upsert(schedule.rooms, (r) => r.roomId, newRoom)
        }),
        roomDeleteTransform: ({ roomId }) => ({
            ...schedule,
            rooms: reject(schedule.rooms, (r) => r.roomId === roomId)
        }),
        bulkTransform: (transforms) => transforms.reduce(applyTransform, schedule),
        courseRoundTransform: ({ newCourseRound }) => ({
            ...schedule,
            courseRounds: upsert(schedule.courseRounds, (cr) => cr.courseRoundId, newCourseRound)
        }),
        courseRoundDeleteTransform: ({ courseRoundId }) => ({
            ...schedule,
            courseRounds: reject(schedule.courseRounds, (cr) => cr.courseRoundId === courseRoundId)
        }),
        studentGroupTransform: ({ newStudentGroup }) => ({
            ...schedule,
            studentGroups: upsert(schedule.studentGroups, (sg) => sg.studentGroupId, newStudentGroup).map((sg) =>
                adjustOverlaps(sg, newStudentGroup)
            )
        }),
        studentGroupDeleteTransform: ({ studentGroupId }) => ({
            ...schedule,
            studentGroups: reject(schedule.studentGroups, (sg) => sg.studentGroupId === studentGroupId).map((sg) =>
                removeFromOverlaps(sg, studentGroupId)
            )
        }),
        teacherDeleteTransform: ({ teacherId }) => ({
            ...schedule,
            teachers: reject(schedule.teachers, (t) => t.teacherId === teacherId)
        }),
        teacherTransform: ({ newTeacher }) => ({
            ...schedule,
            teachers: upsert(schedule.teachers, (t) => t.teacherId, newTeacher)
        }),
        scheduleSettingsTransform: ({ newSettings }) => ({
            ...schedule,
            settings: newSettings
        }),
        weekSelectionPresetsTransform: ({ newWeekSelectionPresets }) => ({
            ...schedule,
            weekSelectionPresets: newWeekSelectionPresets
        }),
        weekSelectionPresetDeleteTransform: ({ weekSelectionPresetId }) => ({
            ...schedule,
            weekSelectionPresets: reject(
                schedule.weekSelectionPresets,
                (wsp) => wsp.weekSelectionPresetId === weekSelectionPresetId
            )
        }),
        reservedTimeTransform: ({ newReservedTime }) => ({
            ...schedule,
            reservedTimes: upsert(schedule.reservedTimes, (rt) => rt.reservedTimeId, newReservedTime)
        }),
        reservedTimeDeleteTransform: ({ reservedTimeId }) => ({
            ...schedule,
            reservedTimes: schedule.reservedTimes.filter((rt) => rt.reservedTimeId !== reservedTimeId)
        }),
        eventGroupDeleteTransform: ({ eventGroupId }) => ({
            ...schedule,
            eventGroups: schedule.eventGroups.filter((eg) => eg.eventGroupId !== eventGroupId)
        }),
        checklistStepsCompleted: ({ newChecklistStepsCompleted }) => ({
            ...schedule,
            checklistStepsCompleted: newChecklistStepsCompleted
        }),
        unknown: (transform) => {
            throw new AppError('Unhandled transform', { transform })
        }
    })
