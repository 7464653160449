import { Input } from 'antd'
import { AppError } from '../../utils/errorutil'

const { TextArea } = Input

type BulkInputProps = {
    value?: string[]
    onChange?: (newValue: string[]) => void
    multiplicityMessage: string
    useTextArea?: boolean
}

const BulkInput = (props: BulkInputProps) => {
    const onChangeWrapper = (newValues: string[]) => {
        if (typeof props.onChange === 'function') {
            props.onChange(newValues)
        }
    }

    if (props.value === undefined || props.value.length === 0) {
        throw new AppError('Expected at least one value to edit.')
    }

    const inputComponent = props.useTextArea ? (
        <TextArea
            value={props.value[0]}
            autoSize={{ minRows: 3, maxRows: 6 }}
            onChange={(newValue) => onChangeWrapper([newValue.target.value])}
        />
    ) : (
        <Input value={props.value[0]} onChange={(newValue) => onChangeWrapper([newValue.target.value])} />
    )

    return props.value.length === 1 ? (
        inputComponent
    ) : (
        <div style={{ paddingTop: '.3em', fontStyle: 'italic' }}>{props.multiplicityMessage}</div>
    )
}

export default BulkInput
