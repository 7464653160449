export const CourseRoundsImportHelpPanel = () => (
    <>
        <h2>Kursomgångar</h2>
        <p>
            Klicka på Tjänstefördelningen i menyn och klicka på import-knappen längst upp till höger. I tabellen som
            visas klistrar du in datan för alla kursomgångar.
        </p>
        <p>Beskrivning av kolumnerna kommer inom kort.</p>
    </>
)
