import { SseConnectionState } from '../../store/devmode/types'
import { CloudTwoTone } from '@ant-design/icons'
import { Space } from 'antd'
import { SseConnectionIndicatorPros } from './types'

export const SseConnectionIndicator = ({ connectionState, label, subscribersExist }: SseConnectionIndicatorPros) => {
    const color = !subscribersExist
        ? '#bbb'
        : connectionState === SseConnectionState.OPEN
          ? 'green'
          : connectionState === SseConnectionState.CLOSED
            ? 'orange'
            : connectionState === SseConnectionState.ERROR
              ? 'red'
              : 'grey'

    return (
        <Space>
            <CloudTwoTone twoToneColor={color} />
            <span style={{ color: subscribersExist ? 'inherit' : '#bbb' }}>{label}</span>
        </Space>
    )
}
