import type { BlockedTimeControlAction } from './actions'
import { BlockedTimeControlActionTypes, type BlockedTimeSelectionState } from './types'

export const initialState: BlockedTimeSelectionState = {
    selectionModeActive: false
}

export const blockedTimeSelectionReducer = (
    state = initialState,
    action: BlockedTimeControlAction
): BlockedTimeSelectionState => {
    switch (action.type) {
        case BlockedTimeControlActionTypes.START_BLOCKED_TIME_SELECTION: {
            return { ...state, selectionModeActive: true }
        }
        case BlockedTimeControlActionTypes.CANCEL_BLOCKED_TIME_SELECTION: {
            return {
                ...state,
                selectionModeActive: false
            }
        }
        default:
            return state
    }
}
