import { ComponentJoinerProps } from './types'

export const ComponentJoiner = ({ components, divider = ', ' }: ComponentJoinerProps) => (
    <>
        {components.map((c, i) => (
            <span key={i}>
                {i > 0 && divider}
                {c}
            </span>
        ))}
    </>
)
