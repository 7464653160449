import type { SelectProps } from 'antd'
import { Select } from 'antd'
import { AppError } from '../../utils/errorutil'
import { isSubsequenceIgnoreCase } from '../../pages/schedule/components/ScheduleSearchSelector/searchUtil'
import { optionFilter } from '../../pages/schedule/components/ScheduleSearchSelector/utils'

type BulkSelectProps = {
    value?: string[]
    onChange?: (newValue: string[]) => void
    options: SelectProps['options']
    multiplicityMessage: string
}

const BulkSelect = ({ multiplicityMessage, onChange, value, options }: BulkSelectProps) => {
    const onChangeWrapper = (newValue: string) => {
        if (typeof onChange === 'function') {
            onChange([newValue])
        }
    }

    if (value === undefined || value.length === 0) {
        throw new AppError('Expected at least one value to edit.')
    }

    return value.length === 1 ? (
        <Select showSearch filterOption={optionFilter} options={options} value={value[0]} onChange={onChangeWrapper} />
    ) : (
        <div style={{ paddingTop: '.3em', fontStyle: 'italic' }}>{multiplicityMessage}</div>
    )
}

export default BulkSelect
