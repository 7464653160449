import type { FilterDropdownProps } from 'antd/es/table/interface'
import { useSchedule } from '../../../store/schedule/hooks'
import { toTranslate } from '../../../utils/miscUtil'
import { FilterableFilterDropdown } from '../../FilterableFilterDropdown'

export const StudentGroupFilterDropdown = (props: FilterDropdownProps) => {
    const schedule = useSchedule()
    const allStudentGroupFilters = schedule.getStudentGroups().map((sg) => ({
        value: sg.getStudentGroupId(),
        text: sg.getDisplayName()
    }))
    return (
        <FilterableFilterDropdown
            {...props}
            allFilters={allStudentGroupFilters}
            noKeysAvailableDescription={toTranslate('Inga elevgrupper')}
        />
    )
}
