import { RoomAssignmentInputProps } from './types'
import { Flex } from 'antd'
import { useState } from 'react'
import { nullToUndefined } from '../../../utils/miscUtil'
import { TogglePin } from '../../../utils/TogglePin'
import { RoomAttributeSelector } from '../../RoomAttributeSelector'
import { RoomAssignmentMenuDropdown } from './RoomAssignmentMenuDropdown'
import { RoomSelector } from './RoomSelector'

export const RoomAssignmentInput = ({ value, onChange, onDelete }: RoomAssignmentInputProps) => {
    const [showRequiredAttributesInput, setShowRequiredAttributesInput] = useState(value.requiredAttributes.length > 0)

    return (
        <div style={{ display: 'grid', columnGap: 5, rowGap: 8, gridTemplateColumns: '1fr auto auto' }}>
            <RoomSelector
                value={nullToUndefined(value.roomId)}
                onChange={(newRoomId) =>
                    onChange({ ...value, roomId: newRoomId === 'undefined' ? undefined : newRoomId })
                }
            />
            <TogglePin
                isPinned={value.pinned}
                onChange={(newIsPinned) => onChange({ ...value, pinned: newIsPinned })}
            />
            <RoomAssignmentMenuDropdown
                setShowRequiredAttributesInput={setShowRequiredAttributesInput}
                onDelete={onDelete}
            />
            {showRequiredAttributesInput && (
                <Flex gap={5} align="center">
                    <span style={{ whiteSpace: 'nowrap' }}>Nödv. attrib.</span>
                    <RoomAttributeSelector
                        value={value.requiredAttributes}
                        onChange={(newAttributes) => onChange({ ...value, requiredAttributes: newAttributes })}
                    />
                </Flex>
            )}
        </div>
    )
}
