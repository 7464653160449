import type { ReservedTimeId, StudentGroupId } from '../../../commonTypes'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { toTranslate } from '../../../utils/miscUtil'
import { ManyToManySelector } from '../../many-to-many-selector/ManyToManySelector'
import { ReservedTimesListTooltip } from '../ReservedTimesListTooltip'
import type { ReservedTimeStudentGroupSelectorProps } from './types'

export const ReservedTimeStudentGroupSelector = ({ value, onChange }: ReservedTimeStudentGroupSelectorProps) => {
    const schedule = useLocalSchedule()
    const displayNamesBySgId = new Map(
        schedule.getStudentGroups().map((sg) => [sg.getStudentGroupId(), sg.getDisplayName()])
    )

    return (
        <ManyToManySelector<ReservedTimeId, StudentGroupId>
            value={value}
            onChange={onChange}
            mode="multiple"
            preexistingOptionValues={schedule.getStudentGroups().map((sg) => sg.getStudentGroupId())}
            optionLabelFn={(sgId) => displayNamesBySgId.get(sgId)!}
            partialCoverSuffix={(reservedTimeIds) => (
                <ReservedTimesListTooltip
                    title={toTranslate('Denna elevgrupp gäller bara följande reserverade tider')}
                    reservedTimes={reservedTimeIds.map((reservedTimeId) => schedule.findReservedTime(reservedTimeId))}
                />
            )}
        />
    )
}
