import { Button, Flex } from 'antd'
import { useNavigate } from 'react-router'
import assignRoomImage from './room-assignment.png'
import { ScheduleLecturesHelpPanelProps } from './types'
import { ExportOutlined } from '@ant-design/icons'
import { HelpPanelDivider } from '../HelpPanelDivider'
import { ScreenshotImage } from '../ScreenshotImage'

export const ScheduleLecturesHelpPanel = (props: ScheduleLecturesHelpPanelProps) => {
    const navigate = useNavigate()

    return (
        <div>
            <p>
                När lektioner skapas så dyker dom upp under schemat, vilket betyder att de inte fått någon tid
                tilldelad. Det är då upp till dig som schemaläggare att placera ut lektionerna där dom ska vara. Detta
                gör du enklast genom att klicka på Schema i menyn, välja lämpligt schema, och dra och släppa lektioner.
            </p>
            <Flex justify="space-around" style={{ marginTop: '2em' }}>
                <Button
                    icon={<ExportOutlined />}
                    type="primary"
                    onClick={() => {
                        props.onNavigateAway()
                        navigate('/schedule')
                    }}
                >
                    Gå till Schemavyn
                </Button>
            </Flex>
            <HelpPanelDivider />
            <p>
                Du behöver också tilldela salar till alla lektioner. Detta gör du genom att klicka på en lektion och
                välja sal i lektionens redigeringsmeny.
            </p>
            <ScreenshotImage src={assignRoomImage} />
            <HelpPanelDivider />
            <p>Både tid och sal kan tilldelas automatiskt av den automatiska schemaläggaren.</p>
        </div>
    )
}
