import { Button, Empty, Space } from 'antd'
import { IScheduleTransform } from 'common-api'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { locallyTriggeredScheduleTransform } from '../../store/schedule/actions'
import { useLocalSchedule } from '../../store/schedule/hooks'
import { toTranslate } from '../../utils/miscUtil'

type ReservedTimeActionsPanelProps = {
    reservedTimeIds: string[]
    onDeleteReservedTime: () => void
}

export const ReservedTimesActionsPanel = ({ reservedTimeIds, onDeleteReservedTime }: ReservedTimeActionsPanelProps) => {
    const schedule = useLocalSchedule()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    if (reservedTimeIds.length === 0) {
        return <Empty description={toTranslate('Ingen reserverad tid vald')} />
    }

    const reservedTimes = reservedTimeIds.map((rid) => schedule.findReservedTime(rid))

    const handleDeleteReservedTime = () => {
        dispatch(
            locallyTriggeredScheduleTransform(
                IScheduleTransform.bulkTransform(
                    reservedTimeIds.map((reservedTimeId) =>
                        IScheduleTransform.reservedTimeDeleteTransform({ reservedTimeId })
                    )
                )
            )
        )
        onDeleteReservedTime()
    }

    return (
        <Space direction="vertical">
            <Button onClick={handleDeleteReservedTime}>
                {toTranslate(reservedTimes.length === 1 ? 'Ta bort reserverad tid' : 'Ta bort reserverade tider')}
            </Button>
        </Space>
    )
}
