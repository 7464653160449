import styled from '@emotion/styled'

interface BlockedStyleProps {
    color: string
}

const Blocked = styled('div')<BlockedStyleProps>`
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-size: 6px 6px;
    border-top: 1px solid ${(props) => props.color};
    border-bottom: 1px solid ${(props) => props.color};
    background-image: repeating-linear-gradient(
        -45deg,
        ${(props) => props.color} 0,
        ${(props) => props.color} 1px,
        #f7e6e6 0,
        #f7e6e6 50%
    );
`

const DragLayerWrapper = styled('div')`
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    padding-right: 1px;
    padding-left: 1px;
`

export { Blocked, DragLayerWrapper }
