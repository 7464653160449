import { LecturesListTooltipProps } from './types'
import { EntityListTooltip } from '../../MultiRoomAttributeSelector/RoomsListTooltip/EntityListTooltip'
import { displayNameForLecture } from '../../../pages/lectures/utils'
import { toTranslate } from '../../../utils/miscUtil'

export const LecturesListTooltip = ({ title, lectures }: LecturesListTooltipProps) => (
    <EntityListTooltip
        title={title}
        entities={lectures}
        entityLabelFn={displayNameForLecture}
        entityKeyFn={(l) => l.getLectureId()}
        singularEntityLabel={toTranslate('lektion')}
        pluralEntityLabel={toTranslate('lektioner')}
    />
)
