import { Cell, ReactGrid, Row } from '@silevis/reactgrid'
import { TeacherGridContentColumn, TeacherGridMetaDataColumn, TeacherGridProps } from './types'
import {
    conjureSubjectsFromSubjects,
    conjureTeacherFromTeachers,
    sortedTeachersFromSchedule,
    useTeacherGridColumns
} from './hooks'
import { ITeacher } from 'common-api'
import { Flex } from 'antd'
import { anyDiff, diff, teacherCmp } from './diff'
import { useRef, useState } from 'react'
import { formatPercentInput, generateEmptyTeacherRows } from './util'
import { BackgroundChangesAlert } from './BackgroundChangesAlert'
import classes from './style.module.css'
import { handlePaste } from './handlePaste'
import { IndexedLocation } from '@silevis/reactgrid/dist/types/InternalModel'
import { handleCopy } from './handleCopy'
import { cellValidationErrors } from './validation'
import { DEFAULT_CONTENT_CELL, DEFAULT_HEADER_CELL, gridStyles, HEADER_ROW, MIN_ROWS_TO_SHOW } from './constants'
import { isEqual, merge } from 'lodash'
import { GridActionsFooter } from './GridActionsFooter'
import { parseTeacherRows } from './teacherData'
import { AddMoreRowsPanel } from './AddMoreRowsPanel'
import { ScrollGradientMaskWrapper } from './ScrollGradientMaskWrapper'
import { useLocalSchedule } from '../../../../../../store/schedule/hooks'

export const TeacherGrid = (props: TeacherGridProps) => {
    const reactGridWrapper = useRef<HTMLDivElement>(null)
    const columns = useTeacherGridColumns(reactGridWrapper)

    const schedule = useLocalSchedule()
    const liveSubjects = conjureSubjectsFromSubjects(schedule.getSubjects())
    const liveTeachers = conjureTeacherFromTeachers(sortedTeachersFromSchedule(schedule))

    const validationProblems = cellValidationErrors(schedule, props.teachers)

    // For the "manual" paste implementation. Hopefully this wil be part of ReactGrid at some point.
    const [focusedCell, setFocusedCell] = useState<IndexedLocation>({ rowIndex: 1, colIndex: 1 })

    const backgroundUpdates = diff<ITeacher>(
        props.teachersWhenEditingStarted,
        liveTeachers,
        (t) => t.teacherId,
        teacherCmp
    )

    const parsedTeachersFromCurrentEditTable = parseTeacherRows(liveTeachers, liveSubjects, props.teachers)
    const changesMade = anyDiff(diff(parsedTeachersFromCurrentEditTable, liveTeachers, (t) => t.teacherId, teacherCmp))

    const emptyRowsToAdd = Math.max(0, MIN_ROWS_TO_SHOW - props.teachers.length)
    const teacherGridData = [
        ...props.teachers,
        ...generateEmptyTeacherRows(props.teachers.length, props.teachers.length + emptyRowsToAdd)
    ]

    const rows = [
        HEADER_ROW,
        ...teacherGridData.map<Row>((_, index) => ({
            rowIndex: index + 1,
            height: 30
        }))
    ]

    const onAddMoreRows = (numRows: number) => {
        props.setTeachers([
            ...teacherGridData,
            ...generateEmptyTeacherRows(teacherGridData.length, teacherGridData.length + numRows)
        ])
    }

    const cells: Cell[] = columns
        .map((titledCol) => titledCol.title)
        .map((title, colIndex) =>
            merge({}, DEFAULT_HEADER_CELL, {
                rowIndex: 0,
                colIndex,
                props: {
                    value: title,
                    style: {
                        justifyContent: 'start'
                    }
                }
            })
        )

    teacherGridData.forEach((teacherRow, teacherIndex) => {
        const rowIndex = teacherIndex + 1 // + 1 to adjust for header row
        columns.forEach(({ colIndex, gridColumn }) => {
            if (gridColumn === TeacherGridMetaDataColumn.RowIndex) {
                cells.push(
                    merge({}, DEFAULT_HEADER_CELL, {
                        rowIndex,
                        colIndex,
                        props: {
                            value: `${rowIndex}`,
                            style: {
                                justifyContent: 'center'
                            }
                        }
                    })
                )
            } else {
                const overrides =
                    gridColumn == TeacherGridContentColumn.WorkPercentage
                        ? {
                              props: {
                                  onTextChanged: (newText: string) => {
                                      const newTeacherGridData = [...teacherGridData]
                                      newTeacherGridData[teacherIndex][TeacherGridContentColumn.WorkPercentage] =
                                          formatPercentInput(newText)
                                      props.setTeachers(newTeacherGridData)
                                  }
                              }
                          }
                        : {}
                cells.push(
                    merge(
                        {},
                        DEFAULT_CONTENT_CELL,
                        {
                            rowIndex,
                            colIndex,
                            props: {
                                text: teacherRow[gridColumn],
                                onTextChanged: (newText: string) => {
                                    const newTeacherGridData = [...teacherGridData]
                                    newTeacherGridData[teacherIndex][gridColumn] = newText
                                    props.setTeachers(newTeacherGridData)
                                },
                                validationProblems: validationProblems
                                    .filter((vp) => isEqual(vp.location, { rowIndex, colIndex }))
                                    .map((vp) => vp.problem)
                            }
                        },
                        overrides
                    )
                )
            }
        })
    })

    const handleClearTableContent = () => {
        props.setTeachers(generateEmptyTeacherRows(0, Math.max(props.teachers.length, MIN_ROWS_TO_SHOW)))
    }

    return (
        <Flex vertical style={{ height: '100%' }} ref={reactGridWrapper}>
            <ScrollGradientMaskWrapper
                className={classes.rgWrapper}
                onPaste={(e) => {
                    // Convert from "grid data coordinates" to "teacher data coorditates" (skip header row)
                    const targetCell = { rowIndex: focusedCell.rowIndex - 1, colIndex: focusedCell.colIndex }
                    const newTeachers = handlePaste(teacherGridData, e, targetCell)
                    props.setTeachers(newTeachers)
                }}
            >
                <ReactGrid
                    columns={columns}
                    rows={rows}
                    cells={cells}
                    styles={gridStyles}
                    onCopy={handleCopy}
                    onCellFocused={setFocusedCell}
                    enableRowSelectionOnFirstColumn
                    enableColumnSelectionOnFirstRow
                />
                <AddMoreRowsPanel onAddMoreRows={onAddMoreRows} />
            </ScrollGradientMaskWrapper>
            {anyDiff(backgroundUpdates) && <BackgroundChangesAlert diff={backgroundUpdates} />}
            <GridActionsFooter
                onReset={props.onReset}
                onSave={props.onSave}
                saveEnabled={changesMade}
                handleClearTableContent={handleClearTableContent}
            />
        </Flex>
    )
}
