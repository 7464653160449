import { TeacherSomethingListProps } from './types'

export const TeacherChangeList = ({ children, header, showHeader }: TeacherSomethingListProps) => {
    return (
        <div>
            {showHeader && <strong>{header}</strong>}
            <ul style={{ padding: '0 24px' }}>{children}</ul>
        </div>
    )
}
