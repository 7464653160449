import type { SVGProps } from 'react'
import { toTranslate } from '../utils/miscUtil'

const SvgTeacher = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" className="teacher_svg__icon" {...props}>
        <title>{toTranslate('teacher')}</title>
        <path d="M512 114c-137 0-248 111-248 248 0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.629 375.629 0 0 0-80.6 119.5A371.708 371.708 0 0 0 136 901.8a8 8 0 0 0 8 8.2h308.578l30.649-234.316h-32.07v-70.68h121.679v70.68h-32.072L571.414 910H880a8 8 0 0 0 8-8.2c-1-47.8-10.9-94.3-29.5-138.2a373.999 373.999 0 0 0-80.6-119.5 375.629 375.629 0 0 0-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248zm0 76c45.9 0 89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190z" />
    </svg>
)

export default SvgTeacher
