import {
    TableEditValidationProblem,
    TeacherGridContentColumn,
    TeacherGridMetaDataColumn,
    TeacherGridRow
} from './types'
import { isEmptyTeacherRow, isNonEmptyTeacherRow, isWellformedPercent, teacherDisplayName } from './util'
import { without } from 'lodash'
import { diff, teacherCmp } from './diff'
import { ProblemListProblem } from '../../../../../schedule/components/ProblemsList/types'
import { addToMultimap, newMultiMapFromKeyValuePairs, orThrow } from '../../../../../../utils/collections'
import { createBlocker } from '../../../../../schedule/components/ProblemsList/utils'
import { toTranslate } from '../../../../../../utils/miscUtil'
import { textualListing } from '../../../../../../textUtil'
import { CourseRoundAccessor, ScheduleAccessor } from '../../../../../../schedule-access/scheduleAccessWrappers'
import { parseCommaSepSubjectList, parseTeacherRows } from './teacherData'
import { conjureSubjectsFromSubjects, sortedConjureTeachersFromSchedule } from './hooks'

function signatureValidationProblems(
    rowsForSignatures: Map<string, number[]>,
    teacherRow: TeacherGridRow
): ProblemListProblem | undefined {
    const signature = teacherRow[TeacherGridContentColumn.Signature]
    const rowsWithSignature = orThrow(rowsForSignatures.get(signature))
    const otherRowsWithSameSignature = without(rowsWithSignature!, teacherRow[TeacherGridMetaDataColumn.RowIndex]).map(
        (row) => `${row + 1}`
    )
    if (otherRowsWithSameSignature.length === 0) {
        return undefined
    }
    return createBlocker(
        toTranslate('Signatur förekommer även på rad ' + textualListing(otherRowsWithSameSignature, 'rader'))
    )
}

function signatureRowsMap(teacherData: TeacherGridRow[]) {
    const rowsForSignatures = new Map<string, number[]>()
    for (const teacherRow of teacherData.filter(isNonEmptyTeacherRow)) {
        const rowIndex = teacherRow[TeacherGridMetaDataColumn.RowIndex]
        const signature = teacherRow[TeacherGridContentColumn.Signature]
        addToMultimap(rowsForSignatures, signature, rowIndex)
    }
    return rowsForSignatures
}

export const cellValidationErrors = (
    schedule: ScheduleAccessor,
    teacherData: TeacherGridRow[]
): TableEditValidationProblem[] => {
    // Signature -> Rows with that signature.
    // Example: { "DPE" -> [15, 17], "ASK" -> [8], ... }
    const rowsForSignatures = signatureRowsMap(teacherData)

    const result = []
    for (let teacherRow of teacherData) {
        // Skip validation for empty rows
        if (isEmptyTeacherRow(teacherRow)) {
            continue
        }

        // Signature validation
        const signatureProblem = signatureValidationProblems(rowsForSignatures, teacherRow)
        if (signatureProblem !== undefined) {
            result.push({
                location: {
                    rowIndex: teacherRow[TeacherGridMetaDataColumn.RowIndex] + 1,
                    colIndex: TeacherGridContentColumn.Signature
                },
                problem: signatureProblem
            })
        }

        // Missing subjects?
        const [_, missingSubjectNames] = parseCommaSepSubjectList(
            conjureSubjectsFromSubjects(schedule.getSubjects()),
            teacherRow[TeacherGridContentColumn.Qualifications]
        )
        result.push(
            ...missingSubjectNames.map((subjectName) => ({
                location: {
                    rowIndex: teacherRow[TeacherGridMetaDataColumn.RowIndex] + 1,
                    colIndex: TeacherGridContentColumn.Qualifications
                },
                problem: createBlocker(toTranslate(`Okänt ämne: ${subjectName}`))
            }))
        )

        // Work percentage validation
        if (!isWellformedPercent(teacherRow[TeacherGridContentColumn.WorkPercentage])) {
            result.push({
                location: {
                    rowIndex: teacherRow[TeacherGridMetaDataColumn.RowIndex] + 1,
                    colIndex: TeacherGridContentColumn.WorkPercentage
                },
                problem: createBlocker(toTranslate('Felaktigt formatterad procentsats'))
            })
        }
    }

    return result
}

export const globalValidationErrors = (
    currentSchedule: ScheduleAccessor,
    newTeacherData: TeacherGridRow[]
): ProblemListProblem[] => {
    const existingTeachers = sortedConjureTeachersFromSchedule(currentSchedule)
    const existingSubjects = conjureSubjectsFromSubjects(currentSchedule.getSubjects())
    const parsedTeachers = parseTeacherRows(existingTeachers, existingSubjects, newTeacherData)
    const diffToValidate = diff(existingTeachers, parsedTeachers, (t) => t.teacherId, teacherCmp)

    // Check if deleted teachers are referenced in course rounds.
    const courseRoundsByTeachers = newMultiMapFromKeyValuePairs(
        currentSchedule
            .getCourseRounds()
            .flatMap((cr) =>
                cr.getTeachers().map((teacher) => [teacher.getTeacherId(), cr] as [string, CourseRoundAccessor])
            )
    )

    const referencedTeacherIds = currentSchedule
        .getCourseRounds()
        .flatMap((cr) => cr.getTeachers())
        .map((t) => t.getTeacherId())

    const deletedAndReferencedTeachers = diffToValidate.deleted.filter((deletedTeacher) =>
        referencedTeacherIds.includes(deletedTeacher.teacherId)
    )

    const rowsForSignatures = signatureRowsMap(newTeacherData)
    const duplicateSignaturesErrors = [...rowsForSignatures.entries()]
        .filter(([, rows]) => rows.length > 1)
        .map(([teacherSchoolId, rows]) =>
            createBlocker(
                `Lärarsignaturen ${teacherSchoolId} förekommer på mer än en rad: ${textualListing(
                    rows.map((r) => `${r}`),
                    'rader'
                )}`
            )
        )

    const deletedAndReferencedTeacherErrors = deletedAndReferencedTeachers.map((deletedAndReferencedTeacher) => {
        const referencingCourseRounds = orThrow(courseRoundsByTeachers.get(deletedAndReferencedTeacher.teacherId))
            .map((cr) => cr.getDisplayName())
            .join(', ')
        return createBlocker(
            toTranslate(
                `Lärare ${teacherDisplayName(deletedAndReferencedTeacher)} är borttagen, men finns omnämnd i följande undervisningsgrupper: ${referencingCourseRounds}.`
            )
        )
    })

    const nonExistingSubjectErrors = newTeacherData.filter(isNonEmptyTeacherRow).flatMap((teacherRow) => {
        const [_, missingSubjectNames] = parseCommaSepSubjectList(
            conjureSubjectsFromSubjects(currentSchedule.getSubjects()),
            teacherRow[TeacherGridContentColumn.Qualifications]
        )
        return missingSubjectNames.map((missingSubjectName) =>
            createBlocker(
                toTranslate(
                    `Rad ${teacherRow[TeacherGridMetaDataColumn.RowIndex] + 1}: Okänt ämne: ${missingSubjectName}`
                )
            )
        )
    })

    const malformedPercentagesErrors = newTeacherData
        .filter(isNonEmptyTeacherRow)
        .filter((teacherRow) => !isWellformedPercent(teacherRow[TeacherGridContentColumn.WorkPercentage]))
        .map((teacherRow) =>
            createBlocker(
                toTranslate(
                    `Rad ${teacherRow[TeacherGridMetaDataColumn.RowIndex] + 1}: Felaktigt formatterad procentsats`
                )
            )
        )

    return [
        ...deletedAndReferencedTeacherErrors,
        ...duplicateSignaturesErrors,
        ...nonExistingSubjectErrors,
        ...malformedPercentagesErrors
    ]
}
