import { AutoSchedulingJobManagementService, ScheduleService } from 'conjure-ai-schema-server-api'
import type { IHttpApiBridge } from 'conjure-client'
import { DefaultHttpApiBridge } from 'conjure-client'
import { MeitnerApi } from 'meitner-api'
import { store } from '../index'
import { unauthorizedApiRequest } from '../store/auth/actions'

export class Endpoints {
    public static service: ScheduleService
    public static autoSchedulingService: AutoSchedulingJobManagementService
    public static meitnerApi: MeitnerApi

    public static API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:3006/api'

    public static init = () => {
        const commonBridgeParams = {
            token: '',
            userAgent: {
                productName: 'ScheduleApp',
                productVersion: '0.1.0'
            },
            fetch: (url: string | Request, init?: RequestInit) => {
                // Make sure the token cookie is passed along in the request
                const responsePromise = fetch(url, {
                    ...init,
                    credentials: 'include'
                }).then((result) => {
                    if (result.status === 302 || result.status === 401) {
                        console.log(`[auth debug] Received a ${result.status} response.`)
                        store.dispatch(unauthorizedApiRequest())
                    }
                    return result
                })
                return responsePromise
            }
        }

        // Schedule server bridge
        const scheduleServerBridge: IHttpApiBridge = new DefaultHttpApiBridge({
            ...commonBridgeParams,
            baseUrl: Endpoints.API_URL
        })
        Endpoints.service = new ScheduleService(scheduleServerBridge)
        Endpoints.autoSchedulingService = new AutoSchedulingJobManagementService(scheduleServerBridge)

        // Meitner bridge
        const meitnerBridge: IHttpApiBridge = new DefaultHttpApiBridge({
            ...commonBridgeParams,
            baseUrl: Endpoints.API_URL.replace('/api', '/meitner-api-proxy')
        })

        Endpoints.meitnerApi = new MeitnerApi(meitnerBridge)
    }
}
