export const CoursesImportHelpPanel = () => (
    <>
        <h2>Kurser</h2>
        <p>
            Klicka på Kurser i menyn och klicka på import-knappen längst upp till höger. I tabellen som visas klistrar
            du in datan för alla kurser.
        </p>
        <p>Beskrivning av kolumnerna kommer inom kort.</p>
    </>
)
