import { toTranslate } from '../../../../../utils/miscUtil'
import { EditModeDropdown } from '../../../../EditModeDropdown'
import type { OverrideToggleButtonProps } from './types'

export const OverrideToggleButton = ({ onChange, value = false }: OverrideToggleButtonProps) => {
    const items = [
        {
            label: toTranslate('Ärv från kursomgång'),
            isSelected: !value,
            onClick: () => onChange(false)
        },
        {
            label: toTranslate('Sätt specifikt för lektion'),
            isSelected: value,
            onClick: () => onChange(true)
        }
    ]

    return <EditModeDropdown isEditActive={value} items={items} />
}
