import { Space, message } from 'antd'
import { IScheduleTransform } from 'common-api'
import { useDispatch } from 'react-redux'
import type { CourseId } from '../../../../commonTypes'
import Button from '../../../../components/Button'
import type { CourseAccessor } from '../../../../schedule-access/scheduleAccessWrappers'
import { locallyTriggeredScheduleTransform } from '../../../../store/schedule/actions'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { toTranslate } from '../../../../utils/miscUtil'

type CourseActionsPanelProps = {
    courses: CourseAccessor[]
    onDelete: () => void
}

export const CourseActionsPanel = (props: CourseActionsPanelProps) => {
    const schedule = useLocalSchedule()
    const dispatch = useDispatch()

    const getCourseRoundsReferencingCourse = (cId: CourseId) =>
        schedule.getCourseRounds().filter((cr) => cr.getCourse()?.getCourseId() === cId)

    const handleDeleteCourse = () => {
        const problematicCourseRounds = props.courses
            .map((cr) => cr.getCourseId())
            .flatMap(getCourseRoundsReferencingCourse)
        if (problematicCourseRounds.length > 0) {
            message.error(toTranslate(`${problematicCourseRounds.length} kursomgång(ar) refererar till valda kurser`))
            return
        }
        props.onDelete()
        dispatch(
            locallyTriggeredScheduleTransform(
                IScheduleTransform.bulkTransform(
                    props.courses.map((cr) =>
                        IScheduleTransform.courseDeleteTransform({
                            courseId: cr.getCourseId()
                        })
                    )
                )
            )
        )
        message.success(toTranslate('Kurs borttagen'))
    }

    return (
        <Space direction="vertical">
            <Button variant="secondary" onClick={handleDeleteCourse}>
                {toTranslate(props.courses.length === 1 ? 'Ta bort kurs' : 'Ta bort kurser')}
            </Button>
        </Space>
    )
}
