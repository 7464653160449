import type { IProblem } from 'common-api'
import type { StudentGroupId } from '../commonTypes'
import type {
    CourseRoundAccessor,
    ScheduleAccessor,
    StudentGroupAccessor,
    TeacherAccessor
} from '../schedule-access/scheduleAccessWrappers'
import type { ScheduleSelector } from '../store/scheduleselector/types'
import { visitScheduleSelector } from '../store/scheduleselector/types'
import { isProblemRelatedToStudentGroup, isProblemRelatedToTeacher } from './problems'

export const teacherDisplayName = (t: TeacherAccessor) => {
    const firstName = t.getFirstName()
    const lastName = t.getLastName()
    const schoolId = t.getTeacherSchoolId()
    if (firstName !== undefined && lastName !== undefined) {
        return `${firstName} ${lastName} (${schoolId})`
    }

    return schoolId
}

// Pattern COURSE_NAME [med TEACHER NAME] [och STUDENT_GROUPS]
export const courseRoundDisplayName = (cr: CourseRoundAccessor, withStudentsPart: boolean) => {
    return cr.getDisplayName()
}

export function overlapPath(sg1: StudentGroupAccessor, sg2: StudentGroupAccessor) {
    return doesOverlapHelper(sg1, sg2, [])
}

function doesOverlapHelper(
    sg1: StudentGroupAccessor,
    sg2: StudentGroupAccessor,
    blockedSgIds: string[]
): StudentGroupAccessor[] | undefined {
    // Have we stepped into a "forbidden" student group?
    if (blockedSgIds.includes(sg1.getStudentGroupId()) || blockedSgIds.includes(sg2.getStudentGroupId())) {
        return undefined
    }

    // Have we reached our destination?
    if (sg1.getStudentGroupId() === sg2.getStudentGroupId()) {
        return [sg1]
    }

    // Is there any path going from here to our destination?
    for (const overlappingGroup of sg1.getOverlaps()) {
        const newOverlaps = [
            ...blockedSgIds,
            ...sg1.getNonOverlaps().map((sg) => sg.getStudentGroupId()),
            sg1.getStudentGroupId()
        ]

        const pathFromOverlapSgToSg2 = doesOverlapHelper(overlappingGroup, sg2, newOverlaps)

        if (pathFromOverlapSgToSg2 !== undefined) {
            return [sg1, ...pathFromOverlapSgToSg2]
        }
    }

    return undefined
}

export const isNonLectureProblemAmongSelectedSchedules = (
    schedule: ScheduleAccessor,
    problem: IProblem,
    selectors: ScheduleSelector[]
) => selectors.some((selector) => isNonLectureProblemInSelectedSchedule(schedule, problem, selector))

const isNonLectureProblemInSelectedSchedule = (schedule: ScheduleAccessor, p: IProblem, selector: ScheduleSelector) =>
    visitScheduleSelector(selector, {
        studentGroupSelector: (sgss) =>
            isProblemRelatedToStudentGroup(schedule, p, schedule.findStudentGroup(sgss.studentGroupId)),
        teacherSelector: (ts) => isProblemRelatedToTeacher(schedule, p, ts.teacherId),
        courseRoundSelector: (s) => false,
        roomSelector: (s) => false,
        allSelector: () => true
    })

export const isScheduleSelectorValid = (schedule: ScheduleAccessor, scheduleSelector: ScheduleSelector) =>
    visitScheduleSelector<boolean>(scheduleSelector, {
        allSelector: () => true,
        courseRoundSelector: ({ courseRoundId }) => schedule.doesCourseRoundIdExist(courseRoundId),
        roomSelector: ({ roomId }) => schedule.doesRoomIdExist(roomId),
        studentGroupSelector: ({ studentGroupId }) => schedule.doesStudentGroupIdExist(studentGroupId),
        teacherSelector: ({ teacherId }) => schedule.doesTeacherIdExist(teacherId)
    })
