import { HelpPanelDivider } from '../HelpPanelDivider'
import { DataImportHelpPanelProps } from './types'
import { InlineToggleExplicitlyDoneButton } from './InlineToggleCompletionButton'

export const DataImportHelpPanel = (props: DataImportHelpPanelProps) => (
    <div>
        <p>
            Du kan importera lärare, elevgrupper, salar etc. till Meitner Schema om du har det tillgängligt i tabellform
            i till exempel Excel.
        </p>

        {props.dataImportSubsteps.map((substep) => (
            <>
                <HelpPanelDivider />
                {substep.helpContent}
                <InlineToggleExplicitlyDoneButton stepUiItem={substep} />
            </>
        ))}
    </div>
)
