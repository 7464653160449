import { Button } from 'antd'
import React from 'react'
import type { ClearTableContentButtonProps } from './types'
import { toTranslate } from '../../../../../../../../utils/miscUtil'
import { DeleteOutlined } from '@ant-design/icons'

export const ClearTableContentButton = ({ onClear }: ClearTableContentButtonProps) => (
    <Button icon={<DeleteOutlined />} onClick={onClear}>
        {toTranslate('Töm innehåll')}
    </Button>
)
