import { Flex, Table } from 'antd'
import type { SubjectId } from '../../../../commonTypes'
import { VisibleColumnsSelector } from '../../../../components/VisibleColumnsSelector'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { comparing } from '../../../../utils/compareUtil'
import { rowFilter } from '../../../../utils/filtering'
import { FilterInput } from '../../../schedule/components/FilterInput'
import { useFiltering } from '../../../schedule/components/FilterInput/hooks'
import { NewSubjectButton } from '../NewSubjectButton'
import { useSubjectColumns } from '../columns'
import type { SubjectRow, SubjectsTableProps } from './types'

export const SubjectsTable = ({ selectedRowKeys, setSelectedRowKeys }: SubjectsTableProps) => {
    const filtering = useFiltering()
    const schedule = useLocalSchedule()
    const { columns, setVisibleColumns } = useSubjectColumns(filtering)

    const dataSourceUnfiltered = schedule.getSubjects().map((subject) => ({ subject }))
    const dataSource = dataSourceUnfiltered
        .filter(rowFilter(filtering, columns))
        .toSorted(comparing(({ subject }) => subject.getName()))

    return (
        <Table<SubjectRow>
            title={() => (
                <Flex gap={8}>
                    <FilterInput {...filtering} />
                    <VisibleColumnsSelector columns={columns} setVisibleColumns={setVisibleColumns} />
                    <NewSubjectButton onNewSubject={(newSubjectId) => setSelectedRowKeys([newSubjectId])} />
                </Flex>
            )}
            bordered
            rowSelection={{
                selectedRowKeys,
                onChange: (newSelectedRows) => setSelectedRowKeys(newSelectedRows as SubjectId[])
            }}
            dataSource={dataSource}
            columns={columns}
            showSorterTooltip={false}
            size="small"
            rowKey={({ subject }) => subject.getSubjectId()}
            sticky
            onRow={({ subject }) => ({
                onClick: () => setSelectedRowKeys([subject.getSubjectId()])
            })}
            style={{ height: '100%' }}
            pagination={false}
        />
    )
}
