import { toTranslate } from '../../../utils/miscUtil'
import { ReservedTimesListTooltipProps } from './types'
import { EntityListTooltip } from '../../MultiRoomAttributeSelector/RoomsListTooltip/EntityListTooltip'

export const ReservedTimesListTooltip = ({ title, reservedTimes }: ReservedTimesListTooltipProps) => (
    <EntityListTooltip
        title={title}
        entities={reservedTimes}
        entityLabelFn={(rt) => rt.getTitle()}
        entityKeyFn={(rt) => rt.getReservedTimeId()}
        singularEntityLabel={toTranslate('reserverad tid')}
        pluralEntityLabel={toTranslate('reserverade tider')}
    />
)
