import type { FilterDropdownProps } from 'antd/es/table/interface'
import { Terms } from 'common-api'
import { termsText } from '../../../pages/course-rounds/components/CourseRoundsTable/columns'
import { EnumFilterDropdown } from '../../YesNoFilterDropdown'

export const TermFilterDropdown = (props: FilterDropdownProps) => {
    return (
        <EnumFilterDropdown
            {...props}
            allFilters={[Terms.YEAR, Terms.FALL, Terms.SPRING].map((term) => ({
                value: term,
                text: termsText(term)
            }))}
        />
    )
}
