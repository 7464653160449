import { TeacherListTooltipProps } from './types'
import { toTranslate } from '../../../../utils/miscUtil'
import { teacherDisplayName } from '../../../../utils/scheduleUtils'
import { EntityListTooltip } from '../../../MultiRoomAttributeSelector/RoomsListTooltip/EntityListTooltip'

export const TeacherListTooltip = ({ title, teachers }: TeacherListTooltipProps) => (
    <EntityListTooltip
        title={title}
        entities={teachers}
        entityLabelFn={teacherDisplayName}
        entityKeyFn={(t) => t.getTeacherId()}
        singularEntityLabel={toTranslate('lärare')}
        pluralEntityLabel={toTranslate('lärare')}
    />
)
