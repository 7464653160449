import { Layout } from 'antd'
import { useEffect } from 'react'
import { Outlet } from 'react-router'
import { AuthenticatedOnly } from '../AuthenticatedOnly'
import MenuSider from './MenuSider'

const { Content } = Layout

export const Root = () => {
    useEffect(() => {
        console.log(`Version: ${process.env.REACT_APP_VERSION || 'unknown'}`)
    }, [])

    return (
        <AuthenticatedOnly>
            <Layout style={{ minHeight: '100vh' }}>
                <MenuSider />
                <Content style={{ backgroundColor: '#fdfdfd' }}>
                    <Outlet />
                </Content>
            </Layout>
        </AuthenticatedOnly>
    )
}
