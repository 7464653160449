import { DayOfWeek } from 'common-api'
import { useTranslation } from 'react-i18next'
import { WeekSelectionAccessor } from '../schedule-access/scheduleAccessWrappers'

const useWeekDayNames = () => {
    const { t } = useTranslation()

    const SWE_DAY_NAMES = {
        [DayOfWeek.MONDAY]: t('WeekDays.Monday'),
        [DayOfWeek.TUESDAY]: t('WeekDays.Tuesday'),
        [DayOfWeek.WEDNESDAY]: t('WeekDays.Wednesday'),
        [DayOfWeek.THURSDAY]: t('WeekDays.Thursday'),
        [DayOfWeek.FRIDAY]: t('WeekDays.Friday'),
        [DayOfWeek.SATURDAY]: t('WeekDays.Saturday'),
        [DayOfWeek.SUNDAY]: t('WeekDays.Sunday')
    }
    const SWE_SHORT_DAY_NAMES = {
        [DayOfWeek.MONDAY]: t('WeekDaysShort.Monday'),
        [DayOfWeek.TUESDAY]: t('WeekDaysShort.Tuesday'),
        [DayOfWeek.WEDNESDAY]: t('WeekDaysShort.Wednesday'),
        [DayOfWeek.THURSDAY]: t('WeekDaysShort.Thursday'),
        [DayOfWeek.FRIDAY]: t('WeekDaysShort.Friday'),
        [DayOfWeek.SATURDAY]: t('WeekDaysShort.Saturday'),
        [DayOfWeek.SUNDAY]: t('WeekDaysShort.Sunday')
    }

    return { SWE_DAY_NAMES, SWE_SHORT_DAY_NAMES }
}

export function useNameOfDay() {
    const { SWE_DAY_NAMES, SWE_SHORT_DAY_NAMES } = useWeekDayNames()

    const getDayName = (day: DayOfWeek) => {
        return SWE_DAY_NAMES[day]
    }
    const getShortDayName = (day: DayOfWeek) => {
        return SWE_SHORT_DAY_NAMES[day]
    }
    return { getDayName, getShortDayName }
}

export const weekSelectionDescription = (ws: WeekSelectionAccessor) => {
    const parts = []
    parts.push(ws.getWeekSelectionPreset().displayName)
    for (let include of ws.getIncludes()) {
        parts.push(`+${include.valueOf()}`)
    }
    for (let exclude of ws.getExcludes()) {
        parts.push(`-${exclude.valueOf()}`)
    }
    return parts.join(', ')
}
