import { memo } from 'react'

type MonthHeaderProps = {
    month: number
    rowSpan: number
}

const MonthHeader = ({ month, rowSpan }: MonthHeaderProps) => (
    <div
        style={{
            gridRow: `span ${rowSpan}`,
            writingMode: 'vertical-rl',
            textAlign: 'center',
            transform: 'rotate(180deg)',
            background: month % 2 === 0 ? '#f5f5f5' : 'white',
            border: '1px solid #f0f0f0'
        }}
    >
        {new Intl.DateTimeFormat('sv-SE', { year: undefined, month: 'long' }).format(new Date(2023, month))}
    </div>
)

export default memo(MonthHeader)
