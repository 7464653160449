export const GenerateLecturesHelpPanel = () => {
    return (
        <div>
            <p>
                När kursomgångarna är importerade och önskat antal undervisningstimmar för varje kursomgång är satt så
                kan programmet generera lektioner. För att generera lektioner följer du stegen nedan:
                <ol>
                    <li>Välj Tjänstefördelning i menyn till vänster.</li>
                    <li>
                        Välj vilka kursomgångar du vill generera lektioner för. Du kan markera alla kursomgångar om du
                        vill generera alla lektioner på en gång.
                    </li>
                    <li>
                        Gå sedan till panelen Åtgärder och klicka på <em>Generera lektioner</em>.
                    </li>
                </ol>
            </p>
        </div>
    )
}
