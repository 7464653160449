export var ChecklistStep;
(function (ChecklistStep) {
    ChecklistStep.REVIEW_SETTINGS = "REVIEW_SETTINGS";
    ChecklistStep.CREATE_PERIODS = "CREATE_PERIODS";
    ChecklistStep.IMPORT_TEACHERS = "IMPORT_TEACHERS";
    ChecklistStep.IMPORT_ROOMS = "IMPORT_ROOMS";
    ChecklistStep.IMPORT_SUBJECTS = "IMPORT_SUBJECTS";
    ChecklistStep.IMPORT_COURSES = "IMPORT_COURSES";
    ChecklistStep.IMPORT_COURSE_ROUNDS = "IMPORT_COURSE_ROUNDS";
    ChecklistStep.ASSIGN_TEACHERS = "ASSIGN_TEACHERS";
    ChecklistStep.GENERATE_LECTURES = "GENERATE_LECTURES";
    ChecklistStep.SCHEDULE_LECTURES = "SCHEDULE_LECTURES";
    ChecklistStep.PUBLISH = "PUBLISH";
})(ChecklistStep || (ChecklistStep = {}));
