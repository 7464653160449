import type { IReservedTime } from 'common-api'
import { IScheduleTransform } from 'common-api'
import { uniq } from 'lodash'
import type { MouseEvent } from 'react'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'
import type { ApplicationState } from '../../../../store'
import { cancelBlockedTimeSelection } from '../../../../store/blockedtime/actions'
import { locallyTriggeredScheduleTransform } from '../../../../store/schedule/actions'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import type { ScheduleSelector } from '../../../../store/scheduleselector/types'
import { visitScheduleSelector } from '../../../../store/scheduleselector/types'
import { WeekDaySelectorState } from '../../../../store/weekDaySelector/types'
import brandColors from '../../../../styles/colors/brandColors'
import { addMinutes } from '../../../../utils/DayAndTimeUtil'
import { Blocked, Positioned } from '../../styled'
import { TimestampCorner } from '../TimestampCorner'
import type { AllocatedLayoutSpec } from '../util'
import { positionToLayoutSpec, positioningTop } from '../util'
import { CreateLayerWrapper } from './styled'
import { minutesBetween } from './utils'

export const CreateBlockLayer = () => {
    const dispatch = useDispatch()
    const ref = useRef<HTMLDivElement>(null)
    const schedule = useLocalSchedule()
    const { t } = useTranslation()
    const selectedSchedules = useSelector<ApplicationState, ScheduleSelector[]>(
        (state) => state.scheduleSelection.selectedSchedules
    )

    const selectedWeekDays = useSelector<ApplicationState, WeekDaySelectorState['selectedWeekDays']>(
        (state) => state.weekDaySelector.selectedWeekDays
    )

    const [startLayoutSpec, setStartLayoutSpec] = useState<AllocatedLayoutSpec | null>(null)
    const [endLayoutSpec, setEndLayoutSpec] = useState<AllocatedLayoutSpec | null>(null)

    const startOfDay = schedule.getSettings().startOfDay!
    const endOfDay = schedule.getSettings().endOfDay!

    const createLayoutSpec = (e: MouseEvent<HTMLDivElement>): AllocatedLayoutSpec | null =>
        ref && ref.current
            ? positionToLayoutSpec({
                  startOfDay,
                  endOfDay,
                  currentOffset: { x: e.clientX, y: e.clientY },
                  dropBounds: ref.current.getBoundingClientRect(),
                  selectedWeekDays
              })
            : null

    const onMouseDown = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        e.preventDefault()
        setStartLayoutSpec(createLayoutSpec(e))
    }

    const onMouseMove = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        e.preventDefault()
        setEndLayoutSpec(createLayoutSpec(e))
    }

    const [mergedLayoutSpec, mergedDurationInMinutes] = ((): [AllocatedLayoutSpec | null, number] => {
        if (!startLayoutSpec || !endLayoutSpec) {
            return [null, 0]
        }

        const top =
            positioningTop(startOfDay, endOfDay, startLayoutSpec) < positioningTop(startOfDay, endOfDay, endLayoutSpec)
                ? startLayoutSpec
                : endLayoutSpec
        const durationInMinutes = Math.abs(minutesBetween(startLayoutSpec.dayAndTime, endLayoutSpec.dayAndTime))

        if (durationInMinutes === 0) {
            return [null, 0]
        }

        return [
            {
                ...top,
                dayAndTime: { ...top.dayAndTime, day: startLayoutSpec.dayAndTime.day }
            },
            durationInMinutes
        ]
    })()

    const onMouseUp = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        e.preventDefault()
        setStartLayoutSpec(null)

        if (!mergedLayoutSpec) {
            console.log('Cancelling reserved time creation.')
            return
        }

        if (mergedDurationInMinutes <= 0) {
            return
        }

        const teacherIds = uniq(
            selectedSchedules.flatMap((ss) =>
                visitScheduleSelector(ss, {
                    teacherSelector: (s) => [s.teacherId],
                    studentGroupSelector: () => [],
                    courseRoundSelector: () => [],
                    roomSelector: () => [],
                    allSelector: (s) => schedule.getTeachers().map((t) => t.getTeacherId())
                })
            )
        )

        const roomIds = uniq(
            selectedSchedules.flatMap((ss) =>
                visitScheduleSelector(ss, {
                    teacherSelector: () => [],
                    studentGroupSelector: () => [],
                    courseRoundSelector: () => [],
                    roomSelector: (s) => [s.roomId],
                    allSelector: () => schedule.getRooms().map((r) => r.getRoomId())
                })
            )
        )

        const studentGroupIds = uniq(
            selectedSchedules.flatMap((ss) =>
                visitScheduleSelector(ss, {
                    teacherSelector: () => [],
                    studentGroupSelector: (s) => [s.studentGroupId],
                    courseRoundSelector: () => [],
                    roomSelector: () => [],
                    allSelector: () => schedule.getStudentGroups().map((sg) => sg.getStudentGroupId())
                })
            )
        )

        const newReservedTime: IReservedTime = {
            reservedTimeId: uuid(),
            title: t('BlockedTime'),
            teacherIds,
            studentGroupIds,
            roomIds,
            weekSelection: {
                weekSelectionPresetId: schedule.getWeekSelectionPresets()[0].weekSelectionPresetId,
                includes: [],
                excludes: []
            },
            dayAndTime: mergedLayoutSpec.dayAndTime,
            durationInMinutes: mergedDurationInMinutes
        }
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.reservedTimeTransform({ newReservedTime })))
        dispatch(cancelBlockedTimeSelection())
    }

    const startTime = mergedLayoutSpec?.dayAndTime
    const endTime = mergedLayoutSpec?.dayAndTime && addMinutes(mergedLayoutSpec?.dayAndTime, mergedDurationInMinutes)

    return (
        <CreateLayerWrapper ref={ref} onMouseDown={onMouseDown} onMouseMove={onMouseMove} onMouseUp={onMouseUp}>
            {mergedLayoutSpec && (
                <Positioned
                    startOfDay={startOfDay}
                    endOfDay={endOfDay}
                    opacity={1}
                    zIndex={'auto'}
                    transition={'none'}
                    layoutSpec={mergedLayoutSpec}
                    duration={mergedDurationInMinutes}
                    selectedWeekDays={selectedWeekDays}
                >
                    <Blocked>
                        {startTime && (
                            <TimestampCorner pos="topleft" dayAndTime={startTime} bgColor={brandColors.blocker} />
                        )}
                        {endTime && (
                            <TimestampCorner pos="bottomright" dayAndTime={endTime} bgColor={brandColors.blocker} />
                        )}
                    </Blocked>
                </Positioned>
            )}
        </CreateLayerWrapper>
    )
}
