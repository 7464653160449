import { TeacherListDiffProps } from './types'
import { Flex } from 'antd'
import { useTranslation } from 'react-i18next'
import { EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { TeacherDiffCard } from './TeacherDiffCard'
import { toTranslate } from '../../../../../../../utils/miscUtil'
import { teacherDisplayName } from '../../TeacherGrid/util'
import { useSchedule } from '../../../../../../../store/schedule/hooks'
import { diff } from '../../TeacherGrid/diff'
import { stringCmp } from '../../../../../../../utils/compareUtil'
import { ComponentJoiner } from '../../../../../../../components/ComponentJoiner'

export const TeacherListDiff = ({ diff: diffToShow }: TeacherListDiffProps) => {
    const schedule = useSchedule()
    const { t } = useTranslation()

    const propertyDiffListItem = (label: string, oldValue: string, newValue: string) =>
        oldValue !== newValue && (
            <li>
                {label}: <del>{oldValue}</del> ⇒ {newValue}
            </li>
        )

    return (
        <Flex gap={8}>
            <TeacherDiffCard
                title={toTranslate('Nya lärare')}
                icon={<PlusOutlined />}
                noChangesText={toTranslate('Inga nya lärare')}
                changes={diffToShow.created}
                renderChange={(t) => <li key={t.teacherId}>{teacherDisplayName(t)}</li>}
            />
            <TeacherDiffCard
                title={toTranslate('Uppdaterade lärare')}
                icon={<EditOutlined />}
                noChangesText={toTranslate('Inga uppdaterade lärare')}
                changes={diffToShow.updated}
                renderChange={([prev, niew]) => {
                    const subjectDiffList = diff(
                        prev.qualifications,
                        niew.qualifications,
                        (subjectId) => subjectId,
                        stringCmp
                    )
                    return (
                        <li key={prev.teacherId}>
                            {teacherDisplayName(prev)}
                            <ul>
                                {propertyDiffListItem(t('Signature'), prev.teacherSchoolId, niew.teacherSchoolId)}
                                {propertyDiffListItem(t('FirstName'), prev.firstName, niew.firstName)}
                                {propertyDiffListItem(t('LastName'), prev.lastName, niew.lastName)}
                                {subjectDiffList.deleted.length > 0 && (
                                    <li>
                                        {toTranslate('Borttagna behörigheter')}:{' '}
                                        <ComponentJoiner
                                            components={subjectDiffList.deleted.map((subjectId) => (
                                                <del>{schedule.findSubject(subjectId)?.getName()}</del>
                                            ))}
                                        />
                                    </li>
                                )}
                                {subjectDiffList.created.length > 0 && (
                                    <li>
                                        {toTranslate('Tillagda behörigheter')}:{' '}
                                        <ComponentJoiner
                                            components={subjectDiffList.created.map((subjectId) =>
                                                schedule.findSubject(subjectId)?.getName()
                                            )}
                                        />
                                    </li>
                                )}
                                {propertyDiffListItem(
                                    t('Employment'),
                                    `${prev.workPercentage} %`,
                                    `${niew.workPercentage} %`
                                )}
                            </ul>
                        </li>
                    )
                }}
            />
            <TeacherDiffCard
                title={toTranslate('Borttagna lärare')}
                icon={<MinusOutlined />}
                noChangesText={toTranslate('Inga borttagna lärare')}
                changes={diffToShow.deleted}
                renderChange={(t) => <li key={t.teacherId}>{teacherDisplayName(t)}</li>}
            />
        </Flex>
    )
}
