import { togglePresence } from '../../utils/collections'
import type { LectureSelectionAction } from './actions'
import { LectureSelectionActionTypes, type LectureSelectionState } from './types'

export const initialState: LectureSelectionState = {
    selectedLectures: [],
    selectedReservedTimes: []
}

export const lectureSelectionReducer = (
    state = initialState,
    action: LectureSelectionAction
): LectureSelectionState => {
    switch (action.type) {
        case LectureSelectionActionTypes.TOGGLE_LECTURE_SELECTION: {
            const lectureIdToToggle = action.payload
            return {
                ...state,
                selectedLectures: togglePresence(state.selectedLectures, lectureIdToToggle),
                selectedReservedTimes: []
            }
        }
        case LectureSelectionActionTypes.SET_LECTURE_SELECTION: {
            const lectureIdsToSet = action.payload
            return {
                ...state,
                selectedLectures: lectureIdsToSet,
                selectedReservedTimes: []
            }
        }

        case LectureSelectionActionTypes.TOGGLE_RESERVED_TIME_SELECTION: {
            const reservedTimeIdToToggle = action.payload
            return {
                ...state,
                selectedLectures: [],
                selectedReservedTimes: togglePresence(state.selectedReservedTimes, reservedTimeIdToToggle)
            }
        }
        case LectureSelectionActionTypes.SET_RESERVED_TIME_SELECTION: {
            const reservedTimeIdsToSet = action.payload
            return {
                ...state,
                selectedLectures: [],
                selectedReservedTimes: reservedTimeIdsToSet
            }
        }

        default:
            return state
    }
}
