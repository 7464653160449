import { Alert, Tooltip } from 'antd'
import { BackgroundChangesAlertProps } from './types'
import { shortTeacherDisplayName } from '../util'
import classes from './style.module.css'
import { MAX_LISTING_CLAUSES, textualListing } from '../../../../../../../textUtil'
import { comparing } from '../../../../../../../utils/compareUtil'
import { toTranslate } from '../../../../../../../utils/miscUtil'
import { SimpleTeacherListDiff } from './SimpleTeacherListDiff'

export const BackgroundChangesAlert = (props: BackgroundChangesAlertProps) => {
    const changedTeachers = [...props.diff.updated.map(([prev]) => prev), ...props.diff.created, ...props.diff.deleted]

    const teacherDisplayNamesToShow = textualListing(
        changedTeachers.map(shortTeacherDisplayName).toSorted(comparing((dn) => dn.length)),
        'lärare'
    )

    const changeParts = [
        ...(props.diff.updated.length !== 0 ? [toTranslate('uppdaterats')] : []),
        ...(props.diff.created.length !== 0 ? [toTranslate('lagts till')] : []),
        ...(props.diff.deleted.length !== 0 ? [toTranslate('tagits bort')] : [])
    ]
    const changeVerbsStr = textualListing(changeParts, '', toTranslate('eller'))

    // We only want to show a tooltip that describes the details of the changes if there's a need for it. If the entire
    // list of teachers is spelled out, and there are only additions or only deletions, the sentence is sufficiently
    // descriptive on its own.
    const showDescriptionTooltip =
        changeParts.length >= 2 || props.diff.updated.length !== 0 || changedTeachers.length > MAX_LISTING_CLAUSES

    const changeVerbs = showDescriptionTooltip ? (
        <Tooltip title={<SimpleTeacherListDiff diff={props.diff} />} rootClassName={classes.updateDescriptionTooltip}>
            <span style={{ textDecoration: 'underline black dashed' }}>{changeVerbsStr}</span>
        </Tooltip>
    ) : (
        changeVerbsStr
    )

    const description = (
        <>
            {`${teacherDisplayNamesToShow} ${toTranslate('har')} `}
            {changeVerbs}
            {toTranslate(` sedan redigeringsläget öppnades. Var extra uppmärksam vid förhandsgranskningen.`)}
        </>
    )

    return (
        <Alert
            type="warning"
            showIcon
            banner
            style={props.style}
            message={toTranslate('Uppdateringar har gjorts i bakgrunden')}
            description={description}
        />
    )
}
