import { Flex, Table } from 'antd'
import type { StudentGroupId } from '../../../../commonTypes'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { comparing } from '../../../../utils/compareUtil'
import { NewStudentGroupButton } from '../NewStudentGroupButton'
import { useStudentGroupColumns } from './columns'
import type { StudentGroupRow, StudentGroupsTableProps } from './types'
import { useFiltering } from '../../../schedule/components/FilterInput/hooks'
import { FilterInput } from '../../../schedule/components/FilterInput'
import { VisibleColumnsSelector } from '../../../../components/VisibleColumnsSelector'
import { rowFilter } from '../../../../utils/filtering'

const StudentGroupTable = ({
    selectedRowKeys,
    setSelectedRowKeys,
    showOverlappingGroupsColumn = true,
    showAddButton = true
}: StudentGroupsTableProps) => {
    const filtering = useFiltering()
    const schedule = useLocalSchedule()
    const { columns, setVisibleColumns } = useStudentGroupColumns(showOverlappingGroupsColumn, filtering)

    const dataSourceUnfiltered = schedule.getStudentGroups().map((studentGroup) => ({ studentGroup }))
    const dataSource = dataSourceUnfiltered
        .filter(rowFilter(filtering, columns))
        .toSorted(comparing(({ studentGroup }) => studentGroup.getDisplayName()))

    return (
        <Table<StudentGroupRow>
            title={() => (
                <Flex gap={8}>
                    <FilterInput {...filtering} />
                    {showAddButton && (
                        <VisibleColumnsSelector columns={columns} setVisibleColumns={setVisibleColumns} />
                    )}
                    {showAddButton && (
                        <NewStudentGroupButton
                            onStudentGroupCreated={(studentGroupId) => setSelectedRowKeys([studentGroupId])}
                        />
                    )}
                </Flex>
            )}
            bordered
            rowSelection={{
                selectedRowKeys,
                onChange: (newSelectedRowKeys) => setSelectedRowKeys(newSelectedRowKeys as StudentGroupId[])
            }}
            dataSource={dataSource}
            columns={columns}
            showSorterTooltip={false}
            size="small"
            rowKey={({ studentGroup }) => studentGroup.getStudentGroupId()}
            sticky
            onRow={({ studentGroup }) => ({ onClick: () => setSelectedRowKeys([studentGroup.getStudentGroupId()]) })}
            style={{ height: '100vh' }}
            pagination={false}
        />
    )
}

export default StudentGroupTable
