import { EditModeDropdown } from '../../EditModeDropdown'
import { LectureDurationOverrideDropdownProps } from './types'
import { toTranslate } from '../../../utils/miscUtil'

export const LectureDurationOverrideDropdown = ({
    onChange = () => {},
    value = false,
    inheritLabels
}: LectureDurationOverrideDropdownProps) => (
    <EditModeDropdown
        isEditActive={!value}
        items={[
            {
                label: toTranslate('Ärv inställningar från ' + inheritLabels.inheritEntityTypeName),
                isSelected: value,
                onClick: () => onChange(true)
            },
            {
                label: toTranslate('Sätt specifikt för ' + inheritLabels.currentEntityTypeName),
                isSelected: !value,
                onClick: () => onChange(false)
            }
        ]}
    />
)
