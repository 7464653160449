import { EmptyTablePlaceholder } from '../../../../../components/EmptyTablePlaceholder/indext'
import { toTranslate } from '../../../../../utils/miscUtil'
import type { EmptyLectureTablePlaceholderProps } from './types'

export const EmptyLectureTablePlaceholder = (props: EmptyLectureTablePlaceholderProps) => {
    return (
        <EmptyTablePlaceholder
            description={
                props.nonEmptyDataSource
                    ? toTranslate('Inga lektioner matchar valda filter')
                    : toTranslate('Inga lektioner')
            }
        />
    )
}
