import type { IStudentGroup } from 'common-api'
import type { IScheduleServiceGroup } from 'meitner-api'
import { Endpoints } from '../../services/Endpoints'
import { CLASS_SG_LABEL } from '../../utils/studentGroupUtil'

export const listDstGroups = async (targetScheduleId: string) =>
    Endpoints.meitnerApi
        .scheduleServiceListGroups({
            schedule_id: targetScheduleId,
            pagination: { page: 0, size: 0 }
        })
        .then((response) => response.schedule_groups)

// Translate fields common to both create and update requests
const translateCommonFields = (srcStudentGroup: IStudentGroup) => ({
    title: srcStudentGroup.displayName,
    description: srcStudentGroup.description,
    external_id: srcStudentGroup.studentGroupId,
    is_class: srcStudentGroup.labels.includes(CLASS_SG_LABEL)
})

export const createDstGroup = async (targetScheduleId: string, srcStudentGroup: IStudentGroup) =>
    Endpoints.meitnerApi
        .scheduleServiceCreateGroup({
            ...translateCommonFields(srcStudentGroup),
            schedule_id: targetScheduleId,
            is_mentor: false,
            student_ids: [],
            teacher_ids: []
        })
        .then((response) => response.created.id!)

export const deleteDstGroup = async (groupId: string) =>
    Endpoints.meitnerApi.scheduleServiceDeleteGroup({
        id: groupId
    })

export const updateDstGroup = async (srcStudentGroup: IStudentGroup, dstGroup: IScheduleServiceGroup) =>
    Endpoints.meitnerApi
        .scheduleServiceUpdateGroup({
            ...dstGroup,
            ...translateCommonFields(srcStudentGroup)
        })
        .then((response) => response.updated.id!)
