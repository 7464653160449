import styled from '@emotion/styled'

const CalendarGrid = styled.div<{ numPresets: number }>`
    display: grid;
    grid-template-columns: 2em 5em repeat(${(props) => 7}, 2.4em) 2em repeat(${(props) => props.numPresets}, 2.4em);
    border-bottom: 1px solid #f0f0f0;
`

const TableHeader = styled.div`
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #f0f0f0;
`

export { CalendarGrid, TableHeader }
