export var DayOfWeek;
(function (DayOfWeek) {
    DayOfWeek.MONDAY = "MONDAY";
    DayOfWeek.TUESDAY = "TUESDAY";
    DayOfWeek.WEDNESDAY = "WEDNESDAY";
    DayOfWeek.THURSDAY = "THURSDAY";
    DayOfWeek.FRIDAY = "FRIDAY";
    DayOfWeek.SATURDAY = "SATURDAY";
    DayOfWeek.SUNDAY = "SUNDAY";
})(DayOfWeek || (DayOfWeek = {}));
