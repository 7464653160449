import type { ActionType } from 'typesafe-actions'
import { action } from 'typesafe-actions'
import type * as scheduleSelectionActions from './actions'
import type { ScheduleSelector } from './types'
import { ScheduleSelectionActionTypes } from './types'
import { ScheduleId } from '../../commonTypes'

export const userAddedScheduleSelector = (selector: ScheduleSelector) =>
    action(ScheduleSelectionActionTypes.USER_ADDED_SCHEDULE_SELECTOR, selector)

export const userRemovedScheduleSelector = (selector: ScheduleSelector) =>
    action(ScheduleSelectionActionTypes.USER_REMOVED_SCHEDULE_SELECTOR, selector)

export const newScheduleSelectorsLoadedDueToScheduleUpdate = (
    scheduleId: ScheduleId,
    loadedScheduleSelectors: ScheduleSelector[]
) =>
    action(ScheduleSelectionActionTypes.NEW_SCHEDULE_SELECTORS_LOADED_DUE_TO_SCHEDULE_UPDATE, {
        scheduleId,
        loadedScheduleSelectors
    })

export type ScheduleSelectionAction = ActionType<typeof scheduleSelectionActions>
