import type { IncludeExcludeMenuButtonProps } from './types'
import { toTranslate } from '../../../utils/miscUtil'
import { EditModeDropdown } from '../../EditModeDropdown'

export const IncludeExcludeMenuButton = ({ onChange, value }: IncludeExcludeMenuButtonProps) => {
    const items = [
        {
            label: toTranslate('Med specifika veckor...'),
            isSelected: value.includesVisible,
            onClick: () => onChange({ ...value, includesVisible: !value?.includesVisible })
        },
        {
            label: toTranslate('Utom specifika veckor...'),
            isSelected: value.excludesVisible,
            onClick: () => onChange({ ...value, excludesVisible: !value?.excludesVisible })
        }
    ]

    return <EditModeDropdown isEditActive={false} items={items} />
}
