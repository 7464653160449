import { message } from 'antd'
import { ILecture, IReservedTime, IScheduleTransform } from 'common-api'
import { useDispatch } from 'react-redux'
import Button from '../../../../../components/Button'
import type { TeacherAccessor } from '../../../../../schedule-access/scheduleAccessWrappers'
import { locallyTriggeredScheduleTransform } from '../../../../../store/schedule/actions'
import { useLocalSchedule } from '../../../../../store/schedule/hooks'
import { toTranslate } from '../../../../../utils/miscUtil'
import type { DeleteTeachersButtonProps } from './types'
import { TeacherId } from '../../../../../commonTypes'
import { without } from 'lodash'
import { hasNonEmptyIntersection } from '../../../../schedule/components/Schedule/utils'

const lectureWithoutTeachers = (l: ILecture, teacherIds: TeacherId[]) => ({
    ...l,
    teacherIds: l.teacherIds === undefined ? undefined : without(l.teacherIds, ...teacherIds)
})

const reservedTimeWithoutTeacher = (rt: IReservedTime, teacherIds: TeacherId[]) => ({
    ...rt,
    teacherIds: without(rt.teacherIds, ...teacherIds)
})

export const DeleteTeachersButton = (props: DeleteTeachersButtonProps) => {
    const dispatch = useDispatch()
    const schedule = useLocalSchedule()
    const teachers = props.teacherIds.map((tid) => schedule.findTeacher(tid))

    const getCourseRoundsReferencingTeacher = (t: TeacherAccessor) =>
        schedule
            .getCourseRounds()
            .filter((cr) => cr.getTeachers().findIndex((t2) => t.getTeacherId() === t2.getTeacherId()) !== -1)

    // Remove references to teachers from lectures
    const removeTeachersFromLectures = schedule
        .getLectures()
        .map((l) => l.getConjureLecture())
        .filter((l) => hasNonEmptyIntersection(l.teacherIds || [], props.teacherIds))
        .map((l) => IScheduleTransform.lectureTransform({ newLecture: lectureWithoutTeachers(l, props.teacherIds) }))

    // Remove references to teachers from reserved times
    const removeTeachersFromReservedTimes = schedule
        .getReservedTimes()
        .map((rt) => rt.getConjureObject())
        .filter((rt) => hasNonEmptyIntersection(rt.teacherIds, props.teacherIds))
        .map((rt) =>
            IScheduleTransform.reservedTimeTransform({
                newReservedTime: reservedTimeWithoutTeacher(rt, props.teacherIds)
            })
        )

    // Remove actual teachers
    const removeTeachers = props.teacherIds.map((teacherId) => IScheduleTransform.teacherDeleteTransform({ teacherId }))

    const handleDeleteTeacher = () => {
        const problematicCrs = teachers.flatMap(getCourseRoundsReferencingTeacher)
        if (problematicCrs.length > 0) {
            message.error(
                toTranslate(`Lärare refereras till i tjänstefördelningen (${problematicCrs[0].getDisplayName()})`)
            )
            return
        }
        props.onDelete()
        dispatch(
            locallyTriggeredScheduleTransform(
                IScheduleTransform.bulkTransform([
                    ...removeTeachersFromLectures,
                    ...removeTeachersFromReservedTimes,
                    ...removeTeachers
                ])
            )
        )
    }

    return (
        <Button variant="secondary" onClick={handleDeleteTeacher}>
            {toTranslate('Ta bort lärare')}
        </Button>
    )
}
