import type { AuthAction } from './actions'
import {
    AUTHENTICATED_WITHOUT_ORGANIZATION,
    AUTHENTICATED_WITHOUT_SCHEDULER_ROLE,
    UNAUTHENTICATED,
    USER_KICKED_OUT
} from './constants'
import type { AuthState, Authenticated } from './types'
import { AuthActionTypes, isAuthenticated } from './types'

export const initialState: AuthState = UNAUTHENTICATED

export const authReducer = (state = initialState, action: AuthAction): AuthState => {
    switch (action.type) {
        case AuthActionTypes.USER_AUTHENTICATED_WITHOUT_SCHEDULER_ROLE: {
            return AUTHENTICATED_WITHOUT_SCHEDULER_ROLE
        }

        case AuthActionTypes.USER_AUTHENTICATED_WITHOUT_ORGANIZATION: {
            return AUTHENTICATED_WITHOUT_ORGANIZATION
        }

        case AuthActionTypes.USER_AUTHENTICATED: {
            const authenticatedState: Authenticated = {
                activeOrgId: (action as any).payload.activeOrgId
            }
            return authenticatedState
        }

        case AuthActionTypes.UNAUTHORIZED_API_REQUEST: {
            // If user is not authenticated yet, this action is expected. It is most likely due to a claims query
            // request, and it will be dealt with by the login procedure code.
            if (!isAuthenticated(state)) {
                return state
            }

            return USER_KICKED_OUT
        }

        default: {
            return state
        }
    }
}
