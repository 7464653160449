import { Alert, Radio, Space } from 'antd'
import { ScheduleType } from 'common-api'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { toTranslate } from '../../../utils/miscUtil'
import type { ScheduleTypeInputProps } from './types'

export const ScheduleTypeInput = ({ onChange, value }: ScheduleTypeInputProps) => {
    const schedule = useLocalSchedule()

    const onChangeWrapper = (newScheduleType: ScheduleType) => {
        if (typeof onChange === 'function') {
            onChange(newScheduleType)
        }
    }

    const options = [
        { value: ScheduleType.GR, label: toTranslate('Grundskola') },
        { value: ScheduleType.GY, label: toTranslate('Gymnasium') }
    ]
    return (
        <Space direction="vertical">
            <Radio.Group
                options={options}
                onChange={(e) => onChangeWrapper(e.target.value as ScheduleType)}
                value={value}
                optionType="button"
            />
            {schedule.getCourses().length > 0 && value === ScheduleType.GR && (
                <Alert
                    showIcon
                    type="warning"
                    message={toTranslate(
                        'Det finns kurser registrerade i schemat. I Grundskoleläge är kurstabellen dold och du kan inte se eller redigera kurserna utan att först växla tillbaka till Gymnasieläge.'
                    )}
                />
            )}
        </Space>
    )
}
