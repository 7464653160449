import { FocusRing } from '@meitner/react-focus-rings'
import type { ReactElement } from 'react'

interface FocusableChildProps extends React.HTMLAttributes<Element> {
    onFocus: (event: React.FocusEvent) => unknown
    onBlur: (event: React.FocusEvent) => unknown
}

export type FocusableOptions = {
    offset?: { left?: number; right?: number; top?: number; bottom?: number } | number
}

type FocusableProps = {
    children: ReactElement<FocusableChildProps>
} & FocusableOptions

const Focusable = ({ children, ...props }: FocusableProps) => {
    return <FocusRing {...props}>{children}</FocusRing>
}

export default Focusable
