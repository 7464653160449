import { ChecklistStep } from 'common-api'

export enum VirtualChecklistStep {
    IMPORT_DATA = 'IMPORT_DATA'
}

export type ScheduleChecklistPanelProps = {
    onNavigateAway: () => void
}

export const isVirtualChecklistStep = (step: ChecklistStep | VirtualChecklistStep): step is VirtualChecklistStep =>
    step === VirtualChecklistStep.IMPORT_DATA

export const isNonVirtualStep = (step: ChecklistStep | VirtualChecklistStep): step is ChecklistStep =>
    !isVirtualChecklistStep(step)

export type ChecklistStepUiItem = {
    step: ChecklistStep | VirtualChecklistStep
    title: string
    desc?: string
    isStartDetected: boolean
    isMarkedAsCompleted: boolean
    substeps?: ChecklistStepUiItem[]
    helpContent: React.ReactNode
}

export type HelpPanelProps = {
    onNavigateAway: () => void
}
