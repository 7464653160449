import { CopyOutlined } from '@ant-design/icons'
import { IScheduleTransform } from 'common-api'
import React from 'react'
import { useDispatch } from 'react-redux'
import { locallyTriggeredScheduleTransform } from '../../../store/schedule/actions'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { toTranslate } from '../../../utils/miscUtil'
import type { DuplicateLectureButtonProps } from './types'
import { duplicateLecture } from '../utils'
import { Button } from 'antd'

export const DuplicateLectureButton = ({ lectureId, disabled = false }: DuplicateLectureButtonProps) => {
    const dispatch = useDispatch()
    const schedule = useLocalSchedule()

    const lecture = schedule.findLecture(lectureId)

    const handleDuplicate = () => {
        const splitTransforms = duplicateLecture(lecture)
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(splitTransforms)))
    }

    return (
        <Button disabled={disabled} icon={<CopyOutlined />} onClick={handleDuplicate}>
            {toTranslate('Duplicera lektion')}
        </Button>
    )
}
