import type { FilterDropdownProps } from 'antd/es/table/interface'
import { useSchedule } from '../../../store/schedule/hooks'
import { toTranslate } from '../../../utils/miscUtil'
import { FilterableFilterDropdown } from '../../FilterableFilterDropdown'

export const TeacherFilterDropdown = (props: FilterDropdownProps) => {
    const schedule = useSchedule()
    const allTeacherFilters = schedule.getTeachers().map((s) => ({
        value: s.getTeacherId(),
        text: s.getTeacherSchoolId()
    }))
    return (
        <FilterableFilterDropdown
            {...props}
            allFilters={allTeacherFilters}
            noKeysAvailableDescription={toTranslate('Inga lärare')}
        />
    )
}
