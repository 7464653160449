import { LoadingOutlined } from '@ant-design/icons'
import { Space, message } from 'antd'
import type { IStudentGroup, ITeacher, IWeekSelectionPreset } from 'common-api'
import { trim, uniqBy } from 'lodash'
import type {
    IScheduleServiceCourse,
    IScheduleServiceGroup,
    IScheduleServiceLesson,
    IScheduleServicePeriod,
    IScheduleServiceTeacher
} from 'meitner-api'
import { useState } from 'react'
import Button from '../../components/Button'
import { undefinedToNull } from '../../components/event-groups/utils'
import { Endpoints } from '../../services/Endpoints'
import { useLocalSchedule } from '../../store/schedule/hooks'
import { orThrow } from '../../utils/collections'
import { AppError } from '../../utils/errorutil'
import { toTranslate } from '../../utils/miscUtil'
import styled from '../../utils/styled'
import { PersistedSchedulesTable } from './PersistedSchedulesTable'
import { TripleDotsMessage } from './TripleDotsMessage'
import {
    courseRoundToCourseRoundWithDependencies,
    createDstCourse,
    deleteDstCourse,
    idForCourseRound,
    listDstCourses,
    nameForCourseRound,
    updateDstCourse,
    type CourseRoundWithDependencies
} from './courses'
import { ExportError, fullJoin } from './export-util'
import { createDstGroup, deleteDstGroup, listDstGroups, updateDstGroup } from './groups'
import {
    createDstLesson,
    deleteDstLesson,
    lectureToLectureWithDependencies,
    listDstLessons,
    updateDstLesson,
    type LectureWithDependencies
} from './lessons'
import { createDstPeriod, createUpdateDstPeriod, deleteDstPeriod, listDstPeriods } from './periods'
import { createDstRoom, deleteDstRoom, listDstRooms, updateDstRoom } from './rooms'
import { createDstTeacher, deleteDstTeacher, listDstTeachers, updateDstTeacher } from './teachers'

export class SrcToDstIdMap {
    public idMap: Map<string, string> = new Map<string, string>()
    addMapping(srcId: string, dstId: string) {
        if (this.idMap.has(srcId)) {
            throw new AppError('Duplicate source id mapping', { srcId, dstId })
        }
        this.idMap.set(srcId, dstId)
    }
    getDstId(srcId: string): string {
        return orThrow(this.idMap.get(srcId))
    }
}

// Returns a map from src id to dst id
const exportEntities = async <SRC, DST>(
    dstScheduleId: string,
    srcIdFn: (e: SRC) => string,
    dstIdFn: (e: DST) => string | null,
    dstInternalIdFn: (e: DST) => string,
    listSrcEntities: () => SRC[],
    listDstEntities: (scheduleId: string) => Promise<DST[]>,
    createDstEntity: (scheduleId: string, e: SRC) => Promise<string>,
    deleteDstEntity: (dstInternalId: string) => void,
    updateDstEntity: (src: SRC, dst: DST) => Promise<string>
): Promise<SrcToDstIdMap> => {
    const idsMap = new SrcToDstIdMap()
    const promises = []
    const entitiesInSource = listSrcEntities()
    const entitiesInDestination = await listDstEntities(dstScheduleId)
    const pairedEntities = fullJoin(entitiesInSource, srcIdFn, entitiesInDestination, dstIdFn)

    // First: Delete existing entities before moving on to updating and creating new entities to mitigate the risk of
    // violating constraints such as uniqueness of certain names.
    for (const [srcEntity, dstEntity] of pairedEntities) {
        if (srcEntity === undefined && dstEntity !== undefined) {
            deleteDstEntity(dstInternalIdFn(dstEntity))
        }
    }

    // Next: Update existing entities in case any entity (prior to being updated) have the same name as an entity that
    // is to be created.
    //
    // There can still be constraint violations in this phase, such as if two entities have swapped names. Leaving this
    // as a todo for the time being.
    for (const [srcEntity, dstEntity] of pairedEntities) {
        if (srcEntity !== undefined && dstEntity !== undefined) {
            promises.push(
                updateDstEntity(srcEntity, dstEntity).then((dstEntityId) => {
                    idsMap.addMapping(srcIdFn(srcEntity), dstEntityId)
                })
            )
        }
    }

    // Last: Create new entities
    for (const [srcEntity, dstEntity] of pairedEntities) {
        if (srcEntity !== undefined && dstEntity === undefined) {
            promises.push(
                createDstEntity(dstScheduleId, srcEntity).then((dstEntityId) =>
                    idsMap.addMapping(srcIdFn(srcEntity), dstEntityId)
                )
            )
        }
    }

    await Promise.allSettled(promises)

    return idsMap
}

const ImportExportIndexPage = () => {
    const [selectedDstScheduleId, setSelectedDstScheduleId] = useState<string | undefined>(undefined)

    // To be used if dstScheduleId is set to ''
    const [newScheduleTitle, setNewScheduleTitle] = useState<string>('')

    const [exportInProgress, setExportInProgress] = useState(false)
    const [exportMessage, setExportMessage] = useState('')
    const schedule = useLocalSchedule()

    const settings = schedule.getSettings()

    const createNewSchedule = async () => {
        // Find default event type
        const defaultEventType = await Endpoints.meitnerApi
            .scheduleServiceListEventTypes({ query: {} })
            .then((response) => {
                console.log('Found these event types: ', response.event_types)
                return response.event_types.find((et) => et.is_default)
            })

        if (defaultEventType === undefined) {
            throw new ExportError(toTranslate('Kunde inte hitta lämplig händelsetyp'))
        }

        return await Endpoints.meitnerApi
            .scheduleServiceCreate({
                event_type_id: defaultEventType.id,
                start_date: `${schedule.getSettings().schoolYear}-05-31`,
                end_date: `${schedule.getSettings().schoolYear + 1}-05-31`,
                source: '',
                title: newScheduleTitle
            })
            .then((response) => response.created.id!)
    }

    const exportSchedule = async () => {
        // No schedule selected? (Shouldn't happen.)
        if (selectedDstScheduleId === undefined) {
            throw new AppError('Expected dstScheduleId to be set.')
        }

        // Create new schedule?
        const dstScheduleId = selectedDstScheduleId !== '' ? selectedDstScheduleId : await createNewSchedule()

        const courseRoundsWithDeps = uniqBy(
            schedule.getCourseRounds().map(courseRoundToCourseRoundWithDependencies),
            idForCourseRound
        )

        // Check uniqueness constraints: Subject / Course names should be unique
        const seenNames = new Set<string>()
        const duplicateNames = []
        for (const name of courseRoundsWithDeps.map(nameForCourseRound).map(trim)) {
            if (seenNames.has(name)) {
                duplicateNames.push(name)
            } else {
                seenNames.add(name)
            }
        }

        if (duplicateNames.length !== 0) {
            throw new ExportError(
                (
                    <div>
                        {toTranslate('Namndubbletter bland ämnen/kurser:')}
                        <ul>
                            {duplicateNames.map((dup, index) => (
                                <li key={index} style={{ textAlign: 'left' }}>
                                    {dup}
                                </li>
                            ))}
                        </ul>
                    </div>
                )
            )
        }

        // Rooms
        setExportMessage(toTranslate('Överför salar'))
        const roomIdsMap = await exportEntities(
            dstScheduleId,
            (srcRoom) => srcRoom.roomId,
            (dstRoom) => undefinedToNull(dstRoom.external_id),
            (dstRoom) => dstRoom.id!,
            () => schedule.getRooms().map((r) => r.getConjureObject()),
            listDstRooms,
            createDstRoom,
            deleteDstRoom,
            updateDstRoom
        )

        // Teachers
        setExportMessage(toTranslate('Överför lärare'))
        const teacherIdsMap = await exportEntities<ITeacher, IScheduleServiceTeacher>(
            dstScheduleId,
            (srcTeacher) => srcTeacher.teacherId,
            (dstTeacher) => undefinedToNull(dstTeacher.external_id),
            (dstTeacher) => dstTeacher.id!,
            () => schedule.getTeachers().map((t) => t.getConjureObject()),
            listDstTeachers,
            createDstTeacher,
            deleteDstTeacher,
            updateDstTeacher
        )

        // Periods
        setExportMessage(toTranslate('Överför perioder'))
        const periodIdsMap = await exportEntities<IWeekSelectionPreset, IScheduleServicePeriod>(
            dstScheduleId,
            (srcWsp) => srcWsp.weekSelectionPresetId,
            (dstPeriod) => undefinedToNull(dstPeriod.external_id),
            (dstPeriod) => dstPeriod.id!,
            () => schedule.getWeekSelectionPresets(),
            listDstPeriods,
            createDstPeriod(settings.schoolYear, settings.schoolDays),
            deleteDstPeriod,
            createUpdateDstPeriod(settings.schoolYear, settings.schoolDays)
        )

        // Groups
        setExportMessage(toTranslate('Överför elevgrupper'))
        const groupIdsMap = await exportEntities<IStudentGroup, IScheduleServiceGroup>(
            dstScheduleId,
            (srcSg) => srcSg.studentGroupId,
            (dstGroup) => undefinedToNull(dstGroup.external_id),
            (dstGroup) => dstGroup.id!,
            () => schedule.getStudentGroups().map((sg) => sg.getConjureObject()),
            listDstGroups,
            createDstGroup,
            deleteDstGroup,
            updateDstGroup
        )

        // Courses
        setExportMessage(toTranslate('Överför kurser'))
        const courseIdsMap = await exportEntities<CourseRoundWithDependencies, IScheduleServiceCourse>(
            dstScheduleId,
            (srcCrWithDeps) => idForCourseRound(srcCrWithDeps),
            (dstCourse) => undefinedToNull(dstCourse.external_id),
            (dstCourse) => dstCourse.id!,
            () => courseRoundsWithDeps,
            listDstCourses,
            createDstCourse,
            deleteDstCourse,
            updateDstCourse
        )

        // Lessons
        setExportMessage(toTranslate('Överför lektioner'))
        await exportEntities<LectureWithDependencies, IScheduleServiceLesson>(
            dstScheduleId,
            (srcLectureWithDeps) => srcLectureWithDeps.lecture.lectureId,
            (dstLesson) => undefinedToNull(dstLesson.external_id),
            (dstLesson) => dstLesson.id!,
            () => schedule.getLectures().map(lectureToLectureWithDependencies),
            listDstLessons,
            createDstLesson(roomIdsMap, groupIdsMap, periodIdsMap, courseIdsMap, teacherIdsMap),
            deleteDstLesson,
            updateDstLesson(roomIdsMap, groupIdsMap, periodIdsMap, courseIdsMap, teacherIdsMap)
        )
    }

    const exportScheduleWithProgressWrapper = () => {
        setExportInProgress(true)
        exportSchedule()
            .then(() => {
                setExportInProgress(false)
                setExportMessage('')
                setNewScheduleTitle('')
                return message.success(toTranslate('Överföring klar'))
            })
            .catch((exportError) => message.error(exportError.content))
            .finally(() => {
                setExportMessage('')
                setExportInProgress(false)
            })
    }

    return (
        <PageContent style={{ height: '100vh', padding: '20px' }}>
            <Space direction="vertical" size="large">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h1>{toTranslate('Överför till Admin')}</h1>
                </div>
                <div>{toTranslate('Välj vilket schema det aktuella schemat ska överföras till:')}</div>
                <PersistedSchedulesTable
                    onSelectDstScheduleId={setSelectedDstScheduleId}
                    onNewScheduleTitle={setNewScheduleTitle}
                    newScheduleTitle={newScheduleTitle}
                    selectedDstScheduleId={selectedDstScheduleId}
                    exportInProgress={exportInProgress}
                />
                <Space>
                    <Button
                        startIcon={exportInProgress ? <LoadingOutlined /> : undefined}
                        disabled={selectedDstScheduleId === undefined || exportInProgress}
                        variant="primary"
                        onClick={exportScheduleWithProgressWrapper}
                    >
                        {toTranslate('Exportera till valt schema')}
                    </Button>
                    {exportMessage && <TripleDotsMessage>{exportMessage}</TripleDotsMessage>}
                </Space>
            </Space>
        </PageContent>
    )
}

const PageContent = styled('article')`
    display: flex;
    column-gap: 20px;
`

export default ImportExportIndexPage
