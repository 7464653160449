import { Button } from 'antd'
import { memo } from 'react'

type WspWeekToggleButtonProps = {
    week: number
    isSelected: boolean
    onClick: () => void
    isEnabled: boolean
}

const WspWeekToggleButton = ({ week, isSelected, onClick, isEnabled }: WspWeekToggleButtonProps) => {
    return (
        <div style={{ borderLeft: '1px solid #f0f0f0' }}>
            <Button type={isSelected ? 'primary' : 'text'} shape="circle" onClick={onClick} disabled={!isEnabled}>
                {week}
            </Button>
        </div>
    )
}

export default memo(WspWeekToggleButton)
