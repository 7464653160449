import { Form, Modal, Radio } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useTranslation } from 'react-i18next'
import { MinutesInput } from '../../MinutesInput'
import type { BreakRuleFormValues } from './types'

type AddBreakRuleDialogProps = {
    open: boolean
    handleOk: (values: BreakRuleFormValues) => void
    handleCancel: () => void
}

export const AddBreakRuleDialog = ({
    open,

    handleOk,
    handleCancel
}: AddBreakRuleDialogProps) => {
    const [form] = useForm<BreakRuleFormValues>()
    const { t } = useTranslation()

    const resetForm = () => {
        form.resetFields()
    }

    const onOkWrapper = () => {
        handleOk(form.getFieldsValue())
    }

    return (
        <Modal
            width="45em"
            title={t('AddBreakSetting')}
            open={open}
            afterClose={resetForm}
            forceRender // avoids an error message: https://stackoverflow.com/a/62082074
            onCancel={handleCancel}
            onOk={onOkWrapper}
        >
            <Form form={form} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} initialValues={{}}>
                <Form.Item label={t('ValidFor')} name="studentsOrTeachers" rules={[{ required: true }]}>
                    <Radio.Group
                        options={[
                            { label: t('Students'), value: 'students' },
                            { label: t('Teachers'), value: 'teachers' }
                        ]}
                        optionType="button"
                    />
                </Form.Item>
                <Form.Item label={t('InRelationToLecture')} name="beforeOrAfter" rules={[{ required: true }]}>
                    <Radio.Group
                        options={[
                            { label: t('BeforeLecture'), value: 'before' },
                            { label: t('AfterLecture'), value: 'after' }
                        ]}
                        optionType="button"
                    />
                </Form.Item>
                <Form.Item label={t('Condition')} name="condition" rules={[{ required: true }]}>
                    <Radio.Group
                        options={[
                            { label: t('NoCondition'), value: 'default' },
                            { label: t('RoomChange'), value: 'roomChange' },
                            { label: t('SubjectChange'), value: 'subjectChange' }
                        ]}
                        optionType="button"
                    />
                </Form.Item>
                <Form.Item label={t('BreakHasToBe')} name="hardThreshold" rules={[{ required: true }]}>
                    <MinutesInput />
                </Form.Item>
                <Form.Item label={t('BreakShouldPreferablyBe')} name="softThreshold">
                    <MinutesInput />
                </Form.Item>
            </Form>
        </Modal>
    )
}
