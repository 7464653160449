import styled from '@emotion/styled'

const CreateLayerWrapper = styled('div')`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-right: 1px;
    padding-left: 1px;
`

export { CreateLayerWrapper }
