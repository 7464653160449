function isLectureTransform(obj) {
    return (obj.type === "lectureTransform");
}
function lectureTransform(obj) {
    return {
        lectureTransform: obj,
        type: "lectureTransform",
    };
}
function isLectureDeleteTransform(obj) {
    return (obj.type === "lectureDeleteTransform");
}
function lectureDeleteTransform(obj) {
    return {
        lectureDeleteTransform: obj,
        type: "lectureDeleteTransform",
    };
}
function isEventGroupTransform(obj) {
    return (obj.type === "eventGroupTransform");
}
function eventGroupTransform(obj) {
    return {
        eventGroupTransform: obj,
        type: "eventGroupTransform",
    };
}
function isStudentGroupTransform(obj) {
    return (obj.type === "studentGroupTransform");
}
function studentGroupTransform(obj) {
    return {
        studentGroupTransform: obj,
        type: "studentGroupTransform",
    };
}
function isStudentGroupDeleteTransform(obj) {
    return (obj.type === "studentGroupDeleteTransform");
}
function studentGroupDeleteTransform(obj) {
    return {
        studentGroupDeleteTransform: obj,
        type: "studentGroupDeleteTransform",
    };
}
function isTeacherTransform(obj) {
    return (obj.type === "teacherTransform");
}
function teacherTransform(obj) {
    return {
        teacherTransform: obj,
        type: "teacherTransform",
    };
}
function isTeacherDeleteTransform(obj) {
    return (obj.type === "teacherDeleteTransform");
}
function teacherDeleteTransform(obj) {
    return {
        teacherDeleteTransform: obj,
        type: "teacherDeleteTransform",
    };
}
function isRoomTransform(obj) {
    return (obj.type === "roomTransform");
}
function roomTransform(obj) {
    return {
        roomTransform: obj,
        type: "roomTransform",
    };
}
function isRoomDeleteTransform(obj) {
    return (obj.type === "roomDeleteTransform");
}
function roomDeleteTransform(obj) {
    return {
        roomDeleteTransform: obj,
        type: "roomDeleteTransform",
    };
}
function isCourseRoundTransform(obj) {
    return (obj.type === "courseRoundTransform");
}
function courseRoundTransform(obj) {
    return {
        courseRoundTransform: obj,
        type: "courseRoundTransform",
    };
}
function isCourseRoundDeleteTransform(obj) {
    return (obj.type === "courseRoundDeleteTransform");
}
function courseRoundDeleteTransform(obj) {
    return {
        courseRoundDeleteTransform: obj,
        type: "courseRoundDeleteTransform",
    };
}
function isSubjectTransform(obj) {
    return (obj.type === "subjectTransform");
}
function subjectTransform(obj) {
    return {
        subjectTransform: obj,
        type: "subjectTransform",
    };
}
function isSubjectDeleteTransform(obj) {
    return (obj.type === "subjectDeleteTransform");
}
function subjectDeleteTransform(obj) {
    return {
        subjectDeleteTransform: obj,
        type: "subjectDeleteTransform",
    };
}
function isCourseTransform(obj) {
    return (obj.type === "courseTransform");
}
function courseTransform(obj) {
    return {
        courseTransform: obj,
        type: "courseTransform",
    };
}
function isCourseDeleteTransform(obj) {
    return (obj.type === "courseDeleteTransform");
}
function courseDeleteTransform(obj) {
    return {
        courseDeleteTransform: obj,
        type: "courseDeleteTransform",
    };
}
function isBulkTransform(obj) {
    return (obj.type === "bulkTransform");
}
function bulkTransform(obj) {
    return {
        bulkTransform: obj,
        type: "bulkTransform",
    };
}
function isScheduleSettingsTransform(obj) {
    return (obj.type === "scheduleSettingsTransform");
}
function scheduleSettingsTransform(obj) {
    return {
        scheduleSettingsTransform: obj,
        type: "scheduleSettingsTransform",
    };
}
function isWeekSelectionPresetsTransform(obj) {
    return (obj.type === "weekSelectionPresetsTransform");
}
function weekSelectionPresetsTransform(obj) {
    return {
        weekSelectionPresetsTransform: obj,
        type: "weekSelectionPresetsTransform",
    };
}
function isWeekSelectionPresetDeleteTransform(obj) {
    return (obj.type === "weekSelectionPresetDeleteTransform");
}
function weekSelectionPresetDeleteTransform(obj) {
    return {
        weekSelectionPresetDeleteTransform: obj,
        type: "weekSelectionPresetDeleteTransform",
    };
}
function isReservedTimeTransform(obj) {
    return (obj.type === "reservedTimeTransform");
}
function reservedTimeTransform(obj) {
    return {
        reservedTimeTransform: obj,
        type: "reservedTimeTransform",
    };
}
function isReservedTimeDeleteTransform(obj) {
    return (obj.type === "reservedTimeDeleteTransform");
}
function reservedTimeDeleteTransform(obj) {
    return {
        reservedTimeDeleteTransform: obj,
        type: "reservedTimeDeleteTransform",
    };
}
function isEventGroupDeleteTransform(obj) {
    return (obj.type === "eventGroupDeleteTransform");
}
function eventGroupDeleteTransform(obj) {
    return {
        eventGroupDeleteTransform: obj,
        type: "eventGroupDeleteTransform",
    };
}
function isChecklistStepsCompleted(obj) {
    return (obj.type === "checklistStepsCompleted");
}
function checklistStepsCompleted(obj) {
    return {
        checklistStepsCompleted: obj,
        type: "checklistStepsCompleted",
    };
}
function visit(obj, visitor) {
    if (isLectureTransform(obj)) {
        return visitor.lectureTransform(obj.lectureTransform);
    }
    if (isLectureDeleteTransform(obj)) {
        return visitor.lectureDeleteTransform(obj.lectureDeleteTransform);
    }
    if (isEventGroupTransform(obj)) {
        return visitor.eventGroupTransform(obj.eventGroupTransform);
    }
    if (isStudentGroupTransform(obj)) {
        return visitor.studentGroupTransform(obj.studentGroupTransform);
    }
    if (isStudentGroupDeleteTransform(obj)) {
        return visitor.studentGroupDeleteTransform(obj.studentGroupDeleteTransform);
    }
    if (isTeacherTransform(obj)) {
        return visitor.teacherTransform(obj.teacherTransform);
    }
    if (isTeacherDeleteTransform(obj)) {
        return visitor.teacherDeleteTransform(obj.teacherDeleteTransform);
    }
    if (isRoomTransform(obj)) {
        return visitor.roomTransform(obj.roomTransform);
    }
    if (isRoomDeleteTransform(obj)) {
        return visitor.roomDeleteTransform(obj.roomDeleteTransform);
    }
    if (isCourseRoundTransform(obj)) {
        return visitor.courseRoundTransform(obj.courseRoundTransform);
    }
    if (isCourseRoundDeleteTransform(obj)) {
        return visitor.courseRoundDeleteTransform(obj.courseRoundDeleteTransform);
    }
    if (isSubjectTransform(obj)) {
        return visitor.subjectTransform(obj.subjectTransform);
    }
    if (isSubjectDeleteTransform(obj)) {
        return visitor.subjectDeleteTransform(obj.subjectDeleteTransform);
    }
    if (isCourseTransform(obj)) {
        return visitor.courseTransform(obj.courseTransform);
    }
    if (isCourseDeleteTransform(obj)) {
        return visitor.courseDeleteTransform(obj.courseDeleteTransform);
    }
    if (isBulkTransform(obj)) {
        return visitor.bulkTransform(obj.bulkTransform);
    }
    if (isScheduleSettingsTransform(obj)) {
        return visitor.scheduleSettingsTransform(obj.scheduleSettingsTransform);
    }
    if (isWeekSelectionPresetsTransform(obj)) {
        return visitor.weekSelectionPresetsTransform(obj.weekSelectionPresetsTransform);
    }
    if (isWeekSelectionPresetDeleteTransform(obj)) {
        return visitor.weekSelectionPresetDeleteTransform(obj.weekSelectionPresetDeleteTransform);
    }
    if (isReservedTimeTransform(obj)) {
        return visitor.reservedTimeTransform(obj.reservedTimeTransform);
    }
    if (isReservedTimeDeleteTransform(obj)) {
        return visitor.reservedTimeDeleteTransform(obj.reservedTimeDeleteTransform);
    }
    if (isEventGroupDeleteTransform(obj)) {
        return visitor.eventGroupDeleteTransform(obj.eventGroupDeleteTransform);
    }
    if (isChecklistStepsCompleted(obj)) {
        return visitor.checklistStepsCompleted(obj.checklistStepsCompleted);
    }
    return visitor.unknown(obj);
}
export var IScheduleTransform = {
    isLectureTransform: isLectureTransform,
    lectureTransform: lectureTransform,
    isLectureDeleteTransform: isLectureDeleteTransform,
    lectureDeleteTransform: lectureDeleteTransform,
    isEventGroupTransform: isEventGroupTransform,
    eventGroupTransform: eventGroupTransform,
    isStudentGroupTransform: isStudentGroupTransform,
    studentGroupTransform: studentGroupTransform,
    isStudentGroupDeleteTransform: isStudentGroupDeleteTransform,
    studentGroupDeleteTransform: studentGroupDeleteTransform,
    isTeacherTransform: isTeacherTransform,
    teacherTransform: teacherTransform,
    isTeacherDeleteTransform: isTeacherDeleteTransform,
    teacherDeleteTransform: teacherDeleteTransform,
    isRoomTransform: isRoomTransform,
    roomTransform: roomTransform,
    isRoomDeleteTransform: isRoomDeleteTransform,
    roomDeleteTransform: roomDeleteTransform,
    isCourseRoundTransform: isCourseRoundTransform,
    courseRoundTransform: courseRoundTransform,
    isCourseRoundDeleteTransform: isCourseRoundDeleteTransform,
    courseRoundDeleteTransform: courseRoundDeleteTransform,
    isSubjectTransform: isSubjectTransform,
    subjectTransform: subjectTransform,
    isSubjectDeleteTransform: isSubjectDeleteTransform,
    subjectDeleteTransform: subjectDeleteTransform,
    isCourseTransform: isCourseTransform,
    courseTransform: courseTransform,
    isCourseDeleteTransform: isCourseDeleteTransform,
    courseDeleteTransform: courseDeleteTransform,
    isBulkTransform: isBulkTransform,
    bulkTransform: bulkTransform,
    isScheduleSettingsTransform: isScheduleSettingsTransform,
    scheduleSettingsTransform: scheduleSettingsTransform,
    isWeekSelectionPresetsTransform: isWeekSelectionPresetsTransform,
    weekSelectionPresetsTransform: weekSelectionPresetsTransform,
    isWeekSelectionPresetDeleteTransform: isWeekSelectionPresetDeleteTransform,
    weekSelectionPresetDeleteTransform: weekSelectionPresetDeleteTransform,
    isReservedTimeTransform: isReservedTimeTransform,
    reservedTimeTransform: reservedTimeTransform,
    isReservedTimeDeleteTransform: isReservedTimeDeleteTransform,
    reservedTimeDeleteTransform: reservedTimeDeleteTransform,
    isEventGroupDeleteTransform: isEventGroupDeleteTransform,
    eventGroupDeleteTransform: eventGroupDeleteTransform,
    isChecklistStepsCompleted: isChecklistStepsCompleted,
    checklistStepsCompleted: checklistStepsCompleted,
    visit: visit
};
