import { Button, Result, Spin } from 'antd'
import type { ReactNode } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { ApplicationState } from '../store'
import { initLogin } from '../store/auth/actions'
import type { AuthState } from '../store/auth/types'
import {
    isAuthenticated,
    isAuthenticatedWithoutOrganization,
    isAuthenticatedWithoutSchedulerRole,
    isUnauthenticated,
    isUserKickedOut
} from '../store/auth/types'
import { AppError } from '../utils/errorutil'
import { toTranslate } from '../utils/miscUtil'

export const AuthenticatedOnly = ({ children }: { children: ReactNode }) => {
    const dispatch = useDispatch()
    const authState = useSelector<ApplicationState, AuthState>((state) => state.authState)

    useEffect(() => {
        dispatch(initLogin())
    }, [dispatch])

    // Authenticated
    if (isUnauthenticated(authState)) {
        return <Result status="warning" icon={<Spin />} title={toTranslate('Autentiserar...')} />
    }

    // Authenticated without organization?
    if (isAuthenticatedWithoutOrganization(authState)) {
        return <Result status="warning" title={toTranslate('Du har inte valt profil.')} />
    }

    // No SCHEDULER role?
    if (isAuthenticatedWithoutSchedulerRole(authState)) {
        return (
            <Result
                status="warning"
                title={toTranslate('Du har inte behörighet till Meitner Schema. Kontakta din administratör.')}
            />
        )
    }

    // Authenticated
    if (isAuthenticated(authState)) {
        return children
    }

    // Session expired
    if (isUserKickedOut(authState)) {
        return (
            <Result
                status="warning"
                title={toTranslate('Du har blivit utloggad.')}
                extra={
                    <Button type="primary" onClick={() => dispatch(initLogin())}>
                        {toTranslate('Logga in')}
                    </Button>
                }
            />
        )
    }

    // Apparently we've missed some case
    throw new AppError('Unexpected auth state.', { authState })
}
