import { isNonVirtualStep, ScheduleChecklistPanelProps, VirtualChecklistStep } from './types'
import styled from '../../../utils/styled'
import { ChecklistItem } from './ChecklistItem'
import { useState } from 'react'
import { Flex } from 'antd'
import { ChecklistStep } from 'common-api'
import { firstNonCompletedTopLevelStep } from '../util'
import classNames from 'classnames'
import { ToggleExplicitlyDoneButton } from './ToggleExplicitlyDoneButton'
import { useChecklistUiItems } from '../hooks'

export const ScheduleChecklistPanel = (props: ScheduleChecklistPanelProps) => {
    const checklistItems = useChecklistUiItems(props.onNavigateAway)
    const [selectedStep, setSelectedStep] = useState<ChecklistStep | VirtualChecklistStep>(
        firstNonCompletedTopLevelStep(checklistItems)
    )
    const selectedItem = checklistItems.find((item) => item.step === selectedStep)!

    return (
        <Flex align="stretch">
            <Flex vertical>
                {checklistItems.map((item, i) => (
                    <ChecklistItemTab
                        key={item.step}
                        className={classNames({ selected: selectedItem === item })}
                        onClick={() => setSelectedStep(item.step)}
                    >
                        <ChecklistItem {...item} stepNumber={i + 1} />
                        {item.substeps && (
                            <ChecklistSubItemsContainer selected={selectedItem === item}>
                                {item.substeps.map((subItem, j) => (
                                    <ChecklistItem
                                        key={subItem.step}
                                        {...subItem}
                                        small
                                        stepNumber={j + 1}
                                        style={{ margin: '1em 0em 1em 4em' }}
                                    />
                                ))}
                            </ChecklistSubItemsContainer>
                        )}
                    </ChecklistItemTab>
                ))}
                <ChecklistItemTab className="filler-tab" style={{ flexGrow: 1 }} />
            </Flex>
            <ChecklistContentArea>
                {selectedItem.substeps === undefined && isNonVirtualStep(selectedItem.step) && (
                    <div style={{ position: 'absolute', bottom: '3em', right: '3em' }}>
                        <ToggleExplicitlyDoneButton stepUiItem={selectedItem} />
                    </div>
                )}
                {selectedItem.helpContent}
            </ChecklistContentArea>
        </Flex>
    )
}

const ChecklistItemTab = styled.div`
    padding-right: 1em;

    &:not(.filler-tab) {
        cursor: pointer;
        padding: 1em 2em;
        &:hover {
            background-color: #fafafa;
        }
    }

    &:not(.selected) {
        background-color: #f5f5f5;
    }

    // Rounded bottom right corner above selected tab
    &:has(+ .selected) {
        border-bottom-right-radius: 16px;
    }

    // Rounded top right corner below selected tab
    .selected + &:not(.selected) {
        border-top-right-radius: 16px;
    }

    &.selected:hover {
        background-color: transparent;
    }

    &:first-of-type {
        border-top-left-radius: 8px;
        padding-top: 2em;
    }

    &:last-child {
        border-bottom-left-radius: 8px;
        padding-bottom: 2em;
    }
`

const ChecklistSubItemsContainer = styled.div<{ selected: boolean }>`
    transition: max-height 0.25s ease-out;
    max-height: ${(props) => (props.selected ? '20em' : '0px')};
    overflow: hidden;
`

const ChecklistContentArea = styled.div`
    max-height: 85vh;
    width: 60%;
    overflow-y: scroll;
    padding: 3em;

    /* Make room for the "Klarmarkera" button */
    padding-bottom: 7em;
`
