import type { FilterDropdownProps } from 'antd/es/table/interface'
import { useSchedule } from '../../../store/schedule/hooks'
import { toTranslate } from '../../../utils/miscUtil'
import { FilterableFilterDropdown } from '../../FilterableFilterDropdown'

export const RoomFilterDropdown = (props: FilterDropdownProps) => {
    const schedule = useSchedule()
    const allRoomFilters = schedule.getRooms().map((r) => ({
        value: r.getRoomId(),
        text: r.getName()
    }))
    return (
        <FilterableFilterDropdown
            {...props}
            allFilters={allRoomFilters}
            noKeysAvailableDescription={toTranslate('Inga salar')}
        />
    )
}
