import { message, Space } from 'antd'
import { ICourseOrSubjectId, IScheduleTransform } from 'common-api'
import { useDispatch } from 'react-redux'
import type { CourseRoundId } from '../../../../commonTypes'
import Button from '../../../../components/Button'
import { generateLectureEventGroups } from '../../../../components/course-rounds/LectureGeneration'
import { CourseRoundAccessor, isCourseAccessor } from '../../../../schedule-access/scheduleAccessWrappers'
import { locallyTriggeredScheduleTransform } from '../../../../store/schedule/actions'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { toTranslate } from '../../../../utils/miscUtil'

type CourseRoundActionsPanelProps = {
    courseRounds: CourseRoundAccessor[]
    onDelete: () => void
}

export const CourseRoundActionsPanel = (props: CourseRoundActionsPanelProps) => {
    const schedule = useLocalSchedule()
    const dispatch = useDispatch()

    const getLecturesReferencingCourseRound = (crId: CourseRoundId) =>
        schedule.getLectures().filter((l) => l.getCourseRound().getCourseRoundId() === crId)

    const handleDeleteCourseRound = () => {
        const problematicLectures = props.courseRounds
            .map((cr) => cr.getCourseRoundId())
            .flatMap(getLecturesReferencingCourseRound)
        if (problematicLectures.length > 0) {
            message.error(toTranslate(`${problematicLectures.length} lektion(er) refererar till i valda kursomgångar`))
            return
        }
        props.onDelete()
        dispatch(
            locallyTriggeredScheduleTransform(
                IScheduleTransform.bulkTransform(
                    props.courseRounds.map((cr) =>
                        IScheduleTransform.courseRoundDeleteTransform({
                            courseRoundId: cr.getCourseRoundId()
                        })
                    )
                )
            )
        )
    }

    const handleGenerateLectures = () => {
        if (props.courseRounds.flatMap((cr) => cr.getLectures()).length > 0) {
            message.error(toTranslate('Det finns redan lektioner för någon av de valda kursomgångarna'))
            return
        }

        const wspYear = schedule.getWeekSelectionPresets().find((wsp) => wsp.displayName.toLowerCase() === 'läsår')
        if (wspYear === undefined) {
            message.error(toTranslate('Kunde inte hitta fördefinerad period för läsåret.'))
            return
        }

        const wspSpring = schedule.getWeekSelectionPresets().find((wsp) => wsp.displayName.toLowerCase() === 'vt')
        if (wspSpring === undefined) {
            message.error(toTranslate('Kunde inte hitta fördefinerad period för vårterminen.'))
            return
        }

        const wspFall = schedule.getWeekSelectionPresets().find((wsp) => wsp.displayName.toLowerCase() === 'ht')
        if (wspFall === undefined) {
            message.error(toTranslate('Kunde inte hitta fördefinerad period för höstterminen.'))
            return
        }

        const newEventGroups = props.courseRounds.flatMap((cr) =>
            generateLectureEventGroups(cr, wspYear, wspSpring, wspFall)
        )
        const addLectureTransforms = newEventGroups.map((newEventGroup) =>
            IScheduleTransform.eventGroupTransform({ newEventGroup })
        )
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(addLectureTransforms)))
        message.success(toTranslate(`${newEventGroups.length} skapade`))
    }

    const handleReferDirectlyToSubject = () => {
        const updatedCourseRounds = props.courseRounds
            .filter((cr) => isCourseAccessor(cr.getCourseOrSubject()))
            .map((cr) => ({
                ...cr.getConjureObject(),
                courseOrSubjectId: ICourseOrSubjectId.subjectId(cr.getSubject().getSubjectId())
            }))
            .map((cr) => IScheduleTransform.courseRoundTransform({ newCourseRound: cr }))
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(updatedCourseRounds)))
    }

    return (
        <Space direction="vertical">
            <Button variant="secondary" onClick={handleDeleteCourseRound}>
                {toTranslate('Ta bort kursomgång')}
            </Button>
            <Button variant="secondary" onClick={handleGenerateLectures}>
                {toTranslate('Generera lektioner')}
            </Button>
            <Button variant="secondary" onClick={handleReferDirectlyToSubject}>
                {toTranslate('Referera till ämne istället för kurs')}
            </Button>
        </Space>
    )
}
