import { Select } from 'antd'
import { range } from 'lodash'
import { formatDuration } from '../../utils/DayAndTimeUtil'
import { MinutesInputProps } from './types'

export const MinutesInput = ({ value, onChange, readOnly = false }: MinutesInputProps) => (
    <Select
        options={range(5, 60 * 10, 5).map((minutes) => ({
            label: formatDuration(minutes),
            value: minutes
        }))}
        value={value}
        style={{ minWidth: '8em' }}
        showSearch
        disabled={readOnly}
        onChange={onChange}
    />
)
