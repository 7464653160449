import type { ColumnType } from 'antd/es/table'
import type { FilteringState } from '../../pages/schedule/components/FilterInput/types'
import { arrayStringCmp } from '../../utils/compareUtil'
import { FilterHighlight } from '../dev-util/FilterHighlight'
import type { FilterableColumn } from './types'

export const hideNonSelectedColumns = <Row, ColumnKey>(columns: ColumnType<Row>[], visibleColumns: ColumnKey[]) => {
    for (const column of columns) {
        column.hidden = !visibleColumns.includes(column.key as ColumnKey)
    }
}

export const deriveRenderPropertyFromFilterTexts = <Row,>(
    filtering: FilteringState,
    columns: FilterableColumn<Row>[]
) => {
    for (const column of columns) {
        if (column.render !== undefined || column.filterTexts === undefined) {
            continue
        }
        column.render = (_, row) => (
            <>
                {column.filterTexts!(row).map((text, i) => (
                    <span key={i}>
                        {i > 0 && ', '}
                        <FilterHighlight text={text} filtering={filtering} />
                    </span>
                ))}
            </>
        )
    }
}

export const deriveSorterPropertyFromFilterTexts = <Row,>(columns: FilterableColumn<Row>[]) => {
    for (const column of columns) {
        if (column.sorter !== undefined || column.filterTexts === undefined) {
            continue
        }
        column.sorter = (row1, row2) => arrayStringCmp(column.filterTexts!(row1), column.filterTexts!(row2))
    }
}
