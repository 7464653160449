import type { XYCoord } from 'react-dnd'

export const TEACHER_FULL_TIME_HOURS = 700 // originally: 520, 700 is for Järva

export const HEADER_WIDTH = 75
export const ROW_HEIGHT = 55

export function mousePositionToRow(currentOffset: XYCoord | null, dropBounds: DOMRect | undefined) {
    return dropBounds && currentOffset ? Math.floor((currentOffset.y - dropBounds.top) / ROW_HEIGHT) : undefined
}

export function hoursToWidth(hours: number) {
    return hours
}

export function indexToHeight(index: number) {
    return ROW_HEIGHT * index
}
