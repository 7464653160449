import { Form, Input, message } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { IScheduleTransform } from 'common-api'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import type { RoomId } from '../../commonTypes'
import type { RoomAccessor } from '../../schedule-access/scheduleAccessWrappers'
import { locallyTriggeredScheduleTransform } from '../../store/schedule/actions'
import { comparing } from '../../utils/compareUtil'
import { toTranslate } from '../../utils/miscUtil'
import Button from '../Button'
import { MultiRoomAttributeSelector } from '../MultiRoomAttributeSelector'

type RoomDetailsProps = {
    rooms: RoomAccessor[]
}

type RoomFormValues = {
    roomIds: RoomId[]
    names: string[]
    attributes: Map<RoomId, string[]>
}

const formValuesFromAccessors = (rooms: RoomAccessor[]): RoomFormValues => ({
    roomIds: rooms.map((r) => r.getRoomId()),
    names: rooms.map((r) => r.getName()),
    attributes: new Map(rooms.map((r) => [r.getRoomId(), r.getAttributes()]))
})

export const RoomDetails = ({ rooms }: RoomDetailsProps) => {
    const dispatch = useDispatch()
    const [form] = useForm()
    const { t } = useTranslation()

    rooms.sort(comparing((r) => r.getName()))

    const saveRooms = (formValues: RoomFormValues) => {
        const roomTransforms = formValues.roomIds.map((roomId, index) =>
            IScheduleTransform.roomTransform({
                newRoom: {
                    roomId,
                    name: formValues.names[index],
                    attributes: formValues.attributes.get(roomId)!
                }
            })
        )
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(roomTransforms)))
        message.success(toTranslate(rooms.length === 1 ? 'Sal sparad' : 'Salar sparade'))
    }

    return (
        <div style={{ overflow: 'auto', maxHeight: '100%', padding: '20px' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Form
                    form={form}
                    name="teacherForm"
                    initialValues={formValuesFromAccessors(rooms)}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: '700px', width: '100%' }}
                    onFinish={saveRooms}
                    labelWrap
                    colon={false}
                >
                    <Form.List name="roomIds">
                        {(fields) =>
                            fields.map((field) => (
                                // biome-ignore lint/correctness/useJsxKeyInIterable: can be removed when we switch form components
                                <Form.Item hidden {...field}>
                                    <Input />
                                </Form.Item>
                            ))
                        }
                    </Form.List>
                    <Form.List name="names">
                        {(fields) =>
                            fields.map((field, index) => (
                                // biome-ignore lint/correctness/useJsxKeyInIterable: can be removed when we switch form components
                                <Form.Item
                                    label={index === 0 ? t('Names') : ' '}
                                    style={{
                                        marginBottom: index === rooms.length - 1 ? undefined : '5px'
                                    }}
                                    {...field}
                                >
                                    <Input />
                                </Form.Item>
                            ))
                        }
                    </Form.List>
                    <Form.Item label={t('Attribute')} name="attributes">
                        <MultiRoomAttributeSelector />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8 }}>
                        <Button variant="primary" type="submit">
                            {t('Save')}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}
