import { useState } from 'react'
import type { RoomId } from '../../commonTypes'
import TablePage from '../../components/PageContent'
import { RoomDetails } from '../../components/rooms/RoomDetails'
import { useLocalSchedule } from '../../store/schedule/hooks'
import { RoomActionsPanel } from './RoomActionsPanel'
import RoomsTable from './RoomsTable'

export const RoomsIndexPage = () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<RoomId[]>([])
    const schedule = useLocalSchedule()

    // Some selected rooms may no longer exists (for example if they were deleted in another browser tab).
    const validSelectedRowKeys = selectedRowKeys.filter((selectedRoomId) => schedule.doesRoomIdExist(selectedRoomId))

    const selectedRooms = validSelectedRowKeys.map((roomId) => schedule.findRoom(roomId))

    const detailsPanel = (
        <RoomDetails key={schedule.getVersion() + validSelectedRowKeys.join(',')} rooms={selectedRooms} />
    )
    const actionsPanel = <RoomActionsPanel rooms={selectedRooms} onDelete={() => setSelectedRowKeys([])} />

    return (
        <TablePage
            selectedRowKeys={validSelectedRowKeys}
            onEscape={() => setSelectedRowKeys([])}
            sidePanels={[
                { key: 'edit', header: 'Redigera', panel: detailsPanel },
                { key: 'actions', header: 'Åtgärder', panel: actionsPanel }
            ]}
            table={<RoomsTable selectedRowKeys={validSelectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} />}
        />
    )
}
