import { Space, message } from 'antd'
import { IScheduleTransform } from 'common-api'
import { useDispatch } from 'react-redux'
import Button from '../../components/Button'
import type { StudentGroupAccessor } from '../../schedule-access/scheduleAccessWrappers'
import { locallyTriggeredScheduleTransform } from '../../store/schedule/actions'
import { useLocalSchedule } from '../../store/schedule/hooks'
import { toTranslate } from '../../utils/miscUtil'

type StudentGroupActionsPanelProps = {
    studentGroups: StudentGroupAccessor[]
    onDelete: () => void
}

export const StudentGroupActionsPanel = (props: StudentGroupActionsPanelProps) => {
    const schedule = useLocalSchedule()
    const dispatch = useDispatch()

    const getCourseRoundsReferencingStudentGroup = (sg: StudentGroupAccessor) =>
        schedule.getCourseRounds().filter((cr) => cr.getStudentGroup().getStudentGroupId() === sg.getStudentGroupId())

    const handleDeleteStudentGroup = () => {
        const problematicCrs = props.studentGroups.flatMap(getCourseRoundsReferencingStudentGroup)
        if (problematicCrs.length > 0) {
            message.error(
                toTranslate(`Elevgrupp refereras till i tjänstefördelningen (${problematicCrs[0].getDisplayName()})`)
            )
            return
        }
        props.onDelete()
        dispatch(
            locallyTriggeredScheduleTransform(
                IScheduleTransform.bulkTransform(
                    props.studentGroups.map((sg) =>
                        IScheduleTransform.studentGroupDeleteTransform({
                            studentGroupId: sg.getStudentGroupId()
                        })
                    )
                )
            )
        )
    }

    return (
        <Space direction="vertical">
            <Button variant="secondary" onClick={handleDeleteStudentGroup}>
                {toTranslate(props.studentGroups.length === 1 ? 'Ta bort elevgrupp' : 'Ta bort elevgrupper')}
            </Button>
        </Space>
    )
}
