import { RefObject, useState } from 'react'
import {
    ALL_CONTENT_COLUMNS,
    GridColumnSpec,
    TeacherGridContentColumn,
    TeacherGridMetaDataColumn,
    TeacherGridRow
} from './types'
import { ISubject, ITeacher } from 'common-api'
import useSize from '@react-hook/size'
import { useTranslation } from 'react-i18next'
import {
    ScheduleAccessor,
    SubjectAccessor,
    TeacherAccessor
} from '../../../../../../schedule-access/scheduleAccessWrappers'
import { combinedCmpFn, comparing } from '../../../../../../utils/compareUtil'
import { useLocalSchedule } from '../../../../../../store/schedule/hooks'
import { toTranslate } from '../../../../../../utils/miscUtil'

export function useTeacherGridColumns(target: RefObject<HTMLDivElement> | null): GridColumnSpec[] {
    const { t } = useTranslation()
    const [width] = useSize(target)

    const ROW_INDEX_COLUMN_WIDTH = 40
    const CONTENT_COLUMN_WIDTH = (width - ROW_INDEX_COLUMN_WIDTH) / ALL_CONTENT_COLUMNS.length - 1

    const columns: GridColumnSpec[] = [
        {
            title: '',
            gridColumn: TeacherGridMetaDataColumn.RowIndex,
            colIndex: 0,
            width: ROW_INDEX_COLUMN_WIDTH,
            minWidth: ROW_INDEX_COLUMN_WIDTH
        },
        {
            title: t('Signature'),
            gridColumn: TeacherGridContentColumn.Signature,
            colIndex: 1,
            width: CONTENT_COLUMN_WIDTH,
            minWidth: CONTENT_COLUMN_WIDTH
        },
        {
            title: t('FirstName'),
            gridColumn: TeacherGridContentColumn.FirstName,
            colIndex: 2,
            width: CONTENT_COLUMN_WIDTH,
            minWidth: CONTENT_COLUMN_WIDTH
        },
        {
            title: t('LastName'),
            gridColumn: TeacherGridContentColumn.LastName,
            colIndex: 3,
            width: CONTENT_COLUMN_WIDTH,
            minWidth: CONTENT_COLUMN_WIDTH
        },
        {
            title: toTranslate('Behörigheter'),
            gridColumn: TeacherGridContentColumn.Qualifications,
            colIndex: 4,
            width: CONTENT_COLUMN_WIDTH,
            minWidth: CONTENT_COLUMN_WIDTH
        },
        {
            title: t('Employment'),
            gridColumn: TeacherGridContentColumn.WorkPercentage,
            colIndex: 5,
            width: CONTENT_COLUMN_WIDTH,
            minWidth: CONTENT_COLUMN_WIDTH
        }
    ]

    return columns
}

export function sortedTeachersFromSchedule(schedule: ScheduleAccessor) {
    return schedule.getTeachers().toSorted(
        combinedCmpFn(
            comparing((t) => t.getTeacherSchoolId()),
            comparing((t) => t.getFirstName()),
            comparing((t) => t.getLastName())
        )
    )
}

export const useSortedTeachersFromSchedule = () => {
    const schedule = useLocalSchedule()
    return sortedTeachersFromSchedule(schedule)
}

export const sortedConjureTeachersFromSchedule = (schedule: ScheduleAccessor): ITeacher[] => {
    return conjureTeacherFromTeachers(sortedTeachersFromSchedule(schedule))
}

export function conjureTeacherFromTeachers(teachers: TeacherAccessor[]): ITeacher[] {
    return teachers.map((t) => t.getConjureObject())
}

export function conjureSubjectsFromSubjects(subjects: SubjectAccessor[]): ISubject[] {
    return subjects.map((s) => s.getConjureObject())
}

export const useLocalScheduleTeachers = () => {
    const sortedTeachers = useSortedTeachersFromSchedule()
    return conjureTeacherFromTeachers(sortedTeachers)
}

const teacherToTeacherRow = (teacher: TeacherAccessor, rowIndex: number): TeacherGridRow => ({
    [TeacherGridMetaDataColumn.RowIndex]: rowIndex,
    [TeacherGridContentColumn.Signature]: teacher.getTeacherSchoolId(),
    [TeacherGridContentColumn.FirstName]: teacher.getFirstName(),
    [TeacherGridContentColumn.LastName]: teacher.getLastName(),
    [TeacherGridContentColumn.Qualifications]: teacher
        .getQualifications()
        .map((s) => s.getName())
        .toSorted()
        .join(', '),
    [TeacherGridContentColumn.WorkPercentage]: `${teacher.getWorkPercentage()} %`
})

export const useLiveTeacherTableData = () => {
    const teachers = useSortedTeachersFromSchedule()
    return teachers.map((teacher, index) => teacherToTeacherRow(teacher, index))
}

export const useTeacherTableDataState = () => {
    const teacherTableData = useLiveTeacherTableData()
    const [teacherGridData, setTeachers] = useState<TeacherGridRow[]>(teacherTableData)
    const resetTeachers = () => setTeachers(teacherTableData)
    return { teacherGridData, setTeachers, resetTeachers }
}
