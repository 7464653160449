import { useLocalSchedule } from '../../store/schedule/hooks'
import { RoomAttributeSelectorProps } from './types'
import { Select } from 'antd'

export const RoomAttributeSelector = ({ value, onChange }: RoomAttributeSelectorProps) => {
    const schedule = useLocalSchedule()

    return (
        <Select
            value={value}
            onChange={onChange}
            mode="tags"
            options={schedule.getRoomAttributes().map((attribute: string) => ({
                label: attribute,
                value: attribute
            }))}
        />
    )
}
