import type { LectureId, ReservedTimeId } from '../../commonTypes'

export enum LectureSelectionActionTypes {
    TOGGLE_LECTURE_SELECTION = 'lectureSelection/TOGGLE_LECTURE_SELECTION',
    SET_LECTURE_SELECTION = 'lectureSelection/SET_LECTURE_SELECTION',
    TOGGLE_RESERVED_TIME_SELECTION = 'lectureSelection/TOGGLE_RESERVED_TIME_SELECTION',
    SET_RESERVED_TIME_SELECTION = 'lectureSelection/SET_RESERVED_TIME_SELECTION'
}

export interface LectureSelectionState {
    readonly selectedLectures: LectureId[]
    readonly selectedReservedTimes: ReservedTimeId[]
}
