import { CheckOutlined } from '@ant-design/icons'
import { CheckmarkProps } from './types'

export const Checkmark = ({ selected, style = {} }: CheckmarkProps) => (
    <CheckOutlined
        style={{
            ...(!selected ? { visibility: 'hidden' } : {}),
            ...style
        }}
    />
)
