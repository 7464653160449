import { ALL_CONTENT_COLUMNS, NUM_TEACHER_COLUMNS, TeacherGridContentColumn, TeacherGridRow } from './types'
import { IndexedLocation } from '@silevis/reactgrid/dist/types/InternalModel'
import { formatPercentInput, generateEmptyTeacherRow } from './util'

export const clipboardToTabularData = (event: React.ClipboardEvent<HTMLElement>): string[][] => {
    const html = event.clipboardData.getData('text/html')
    if (html !== '') {
        const result: string[][] = []
        const parser = new DOMParser()
        const doc = parser.parseFromString(html, 'text/html')

        const rows = doc.querySelectorAll('tr')
        rows.forEach((row) => {
            const currentRow: string[] = []
            const cells = row.querySelectorAll('td')
            cells.forEach((cell) => currentRow.push(cell.textContent || ''))
            result.push(currentRow)
        })
        return result
    } else {
        const text = event.clipboardData.getData('text/plain')
        if (text !== '') {
            return [[text]]
        }
    }
    return []
}

export const handlePaste = (
    currentTeacherRows: TeacherGridRow[],
    event: React.ClipboardEvent<HTMLElement>,
    { rowIndex, colIndex }: IndexedLocation
): TeacherGridRow[] => {
    const tabularData = clipboardToTabularData(event)

    if (tabularData.length === 0) {
        return currentTeacherRows
    }

    const result = [...currentTeacherRows]
    for (let srcRowIndex = 0; srcRowIndex < tabularData.length; srcRowIndex++) {
        const srcRow = tabularData[srcRowIndex]
        const dstRowIndex = rowIndex + srcRowIndex

        // Do we need to extend the destination matrix to fit the pasted rows?
        if (dstRowIndex >= result.length) {
            result.push(generateEmptyTeacherRow(dstRowIndex, '100 %'))
        }

        const dstRow = result[dstRowIndex]

        for (let srcColIndex = 0; srcColIndex < srcRow.length; srcColIndex++) {
            const dstColIndex = colIndex + srcColIndex
            if (dstColIndex >= NUM_TEACHER_COLUMNS) {
                break
            }
            let toPaste = tabularData[srcRowIndex][srcColIndex]
            if (dstColIndex === TeacherGridContentColumn.WorkPercentage) {
                toPaste = formatPercentInput(toPaste)
            }
            const dstCol = ALL_CONTENT_COLUMNS[dstColIndex - 1]
            dstRow[dstCol] = toPaste
        }
    }

    return result
}
