import { all, fork, select, takeLatest } from 'redux-saga/effects'
import { ApplicationState } from '../index'
import { DevInfoActionTypes } from './types'
import { DEV_MODE_KEY, DEV_MODE_VALUE } from './constants'

// Flush current mode to local storage
function* flushDevModeFlagToLocalStorage(): any {
    const devMode: boolean = yield select((state: ApplicationState) => state.devInfoState.devMode)
    if (devMode) {
        window.localStorage.setItem(DEV_MODE_KEY, DEV_MODE_VALUE)
    } else {
        window.localStorage.removeItem(DEV_MODE_KEY)
    }
}

// Make sure user updates are flushed out to local storage
function* watchDevModeChange() {
    yield takeLatest(
        [DevInfoActionTypes.ACTIVATE_DEV_MODE, DevInfoActionTypes.DEACTIVATE_DEV_MODE],
        flushDevModeFlagToLocalStorage
    )
}

function* devModeSaga() {
    yield all([fork(watchDevModeChange)])
}

export default devModeSaga
