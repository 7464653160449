function isCourseId(obj) {
    return (obj.type === "courseId");
}
function courseId(obj) {
    return {
        courseId: obj,
        type: "courseId",
    };
}
function isSubjectId(obj) {
    return (obj.type === "subjectId");
}
function subjectId(obj) {
    return {
        subjectId: obj,
        type: "subjectId",
    };
}
function visit(obj, visitor) {
    if (isCourseId(obj)) {
        return visitor.courseId(obj.courseId);
    }
    if (isSubjectId(obj)) {
        return visitor.subjectId(obj.subjectId);
    }
    return visitor.unknown(obj);
}
export var ICourseOrSubjectId = {
    isCourseId: isCourseId,
    courseId: courseId,
    isSubjectId: isSubjectId,
    subjectId: subjectId,
    visit: visit
};
