import { toTranslate } from '../../../utils/miscUtil'
import { RoomsListTooltipProps } from './types'
import { EntityListTooltip } from './EntityListTooltip'

export const RoomsListTooltip = ({ title, rooms }: RoomsListTooltipProps) => (
    <EntityListTooltip
        title={title}
        entities={rooms}
        entityLabelFn={(r) => r.getName()}
        entityKeyFn={(r) => r.getRoomId()}
        singularEntityLabel={toTranslate('sal')}
        pluralEntityLabel={toTranslate('salar')}
    />
)
