import classes from './style.module.css'
import { ROW_HEIGHT, indexToHeight } from './util'

interface PositioningProps {
    maxVisibleHours: number
    row?: number
    startHour: number
    totalHours: number
    children: React.ReactNode
}

export const Positioned: React.FC<PositioningProps> = ({ maxVisibleHours, row, startHour, totalHours, children }) => {
    const style = {
        '--top': `${indexToHeight(row || 0)}px`,
        '--left': `${(100 * startHour) / maxVisibleHours}%`,
        '--min-width': `${(100 * totalHours) / maxVisibleHours}%`,
        '--width': `${(100 * totalHours) / maxVisibleHours}%`,
        '--height': `${ROW_HEIGHT}px`
    } as React.CSSProperties

    return (
        <div className={classes.positioned} style={style}>
            {children}
        </div>
    )
}
