import type { ScheduleEvent } from './ScheduleEvent'
import type { LayoutSpec } from './util'

export interface EventAndLayoutSpec {
    event: ScheduleEvent
    layoutSpec: LayoutSpec
}

export enum BlockedTimeSelectionZIndex {
    MASK,
    WHITE_BACKGDROP,
    SCHEDULE_CONTENT
}
