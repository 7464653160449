import { DevInfoActionTypes, SseConnectionState } from './types'
import { action, ActionType } from 'typesafe-actions'
import type * as devInfoActions from './actions'

export const activateDevMode = () => action(DevInfoActionTypes.ACTIVATE_DEV_MODE)
export const deactivateDevMode = () => action(DevInfoActionTypes.DEACTIVATE_DEV_MODE)

export const setScheduleSseConnectionState = (connectionState: SseConnectionState) =>
    action(DevInfoActionTypes.SCHEDULE_SSE_CONNECTION_STATE_UPDATE, connectionState)

export const setAutoSchedulerSseConnectionState = (connectionState: SseConnectionState) =>
    action(DevInfoActionTypes.AUTO_SCHEDULER_SSE_CONNECTION_STATE_UPDATE, connectionState)

export type DevInfoAction = ActionType<typeof devInfoActions>
