import Button from 'antd/es/button'
import { CheckOutlined } from '@ant-design/icons'
import { ToggleExplicitlyDoneButtonProps } from './types'
import { COMPLETED_COLOR } from '../../constants'
import { toTranslate } from '../../../../utils/miscUtil'
import { useCompletionStateToggler } from '../../hooks'
import { celebrateClick, isCelebrationStep } from '../../util'

export const ToggleExplicitlyDoneButton = (props: ToggleExplicitlyDoneButtonProps) => {
    const toggleState = useCompletionStateToggler(props.stepUiItem.step)
    const isMarkedAsCompleted = props.stepUiItem.isMarkedAsCompleted

    const handleClick = (e: React.MouseEvent<HTMLSpanElement>) => {
        toggleState()
        if (isCelebrationStep(props.stepUiItem.step) && !isMarkedAsCompleted) {
            celebrateClick(e)
        }
    }

    return (
        <Button
            size="large"
            icon={isMarkedAsCompleted ? undefined : <CheckOutlined />}
            type="primary"
            onClick={handleClick}
            style={{
                backgroundColor: isMarkedAsCompleted ? undefined : COMPLETED_COLOR,
                border: 'none',
                boxShadow: '.2em .2em .3em #aaa'
            }}
            shape="round"
        >
            {isMarkedAsCompleted ? toTranslate('Markera som ej klar') : toTranslate('Markera som klart')}
        </Button>
    )
}
