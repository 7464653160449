import { PlusOutlined } from '@ant-design/icons'
import { IScheduleTransform } from 'common-api'
import { useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'
import type { CourseId } from '../../../../commonTypes'
import Button from '../../../../components/Button'
import { locallyTriggeredScheduleTransform } from '../../../../store/schedule/actions'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { generateFreshName, toTranslate } from '../../../../utils/miscUtil'

type NewCourseButtonProps = {
    onNewCourse: (newCourseId: CourseId) => void
}

export const NewCourseButton = (props: NewCourseButtonProps) => {
    const schedule = useLocalSchedule()
    const dispatch = useDispatch()

    const freshCourseDisplayName = () =>
        generateFreshName(
            'Ny kurs',
            schedule.getCourses().map((c) => c.getName())
        )

    const freshCourseCodeName = () =>
        generateFreshName(
            'NYKURS',
            schedule.getCourses().map((c) => c.getCode())
        )

    const handleNewCourse = () => {
        const newCourseId = uuid()
        dispatch(
            locallyTriggeredScheduleTransform(
                IScheduleTransform.courseTransform({
                    newCourse: {
                        courseId: newCourseId,
                        subjectId: schedule.getSubjects()[0].getSubjectId(), // TODO: Show error toast if no subjects
                        code: freshCourseCodeName(),
                        name: freshCourseDisplayName()
                    }
                })
            )
        )
        props.onNewCourse(newCourseId)
    }

    return (
        <Button variant="primary" size="sm" startIcon={<PlusOutlined />} onClick={handleNewCourse}>
            {toTranslate('Lägg till kurs')}
        </Button>
    )
}
