import { Checkbox } from 'antd'
import { setPresence } from '../../utils/collections'
import type { ValueSelectionCheckboxProps } from './types'

export const ValueSelectionCheckbox = <T,>({
    children,
    selectedValues,
    setSelectedValues,
    value,
    ...checkboxProps
}: ValueSelectionCheckboxProps<T>) => (
    <Checkbox
        {...checkboxProps}
        checked={selectedValues.includes(value)}
        onChange={(e) => setSelectedValues(setPresence(selectedValues, e.target.checked, [value]))}
    >
        {children}
    </Checkbox>
)
