import { Button, Empty, Form, Input } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { IScheduleTransform, type IWeekSelection } from 'common-api'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import type { ReservedTimeAccessor } from '../../../schedule-access/scheduleAccessWrappers'
import { locallyTriggeredScheduleTransform } from '../../../store/schedule/actions'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { comparing } from '../../../utils/compareUtil'
import { toTranslate } from '../../../utils/miscUtil'
import BulkGeneralInput from '../../BulkGeneralInput'
import BulkInput from '../../BulkInput'
import { WeekSelectionInput } from '../../lectures/WeekSelectionInput'
import { ReservedTimeRoomSelector } from '../ReservedTimeRoomSelector'
import { ReservedTimeStudentGroupSelector } from '../ReservedTimeStudentGroupSelector'
import { ReservedTimeTeacherSelector } from '../ReservedTimeTeacherSelector'
import type { ReservedTimeDetailsProps, ReservedTimeFormValues } from './types'
import { MinutesInput } from '../../MinutesInput'

export const ReservedTimeDetails = ({ reservedTimeIds }: ReservedTimeDetailsProps) => {
    const dispatch = useDispatch()
    const schedule = useLocalSchedule()
    const [form] = useForm()
    const { t } = useTranslation()

    if (reservedTimeIds.length === 0) {
        return <Empty description={toTranslate('Ingen händelse vald')} />
    }

    const reservedTimes = reservedTimeIds.map((rid) => schedule.findReservedTime(rid))

    reservedTimes.sort(comparing((rt) => rt.getTitle()))

    const formValuesFromAccessors = (reservedTimes: ReservedTimeAccessor[]): ReservedTimeFormValues => ({
        title: reservedTimes.map((rt) => rt.getTitle()),
        teacherIds: new Map(
            reservedTimes.map((rt) => [rt.getReservedTimeId(), rt.getTeachers().map((t) => t.getTeacherId())])
        ),
        studentGroupIds: new Map(
            reservedTimes.map((rt) => [
                rt.getReservedTimeId(),
                rt.getStudentGroups().map((sg) => sg.getStudentGroupId())
            ])
        ),
        roomIds: new Map(reservedTimes.map((rt) => [rt.getReservedTimeId(), rt.getRooms().map((r) => r.getRoomId())])),
        reservedTimeIds: reservedTimes.map((rt) => rt.getReservedTimeId()),
        weekSelections: reservedTimes.map((rt) => rt.getWeekSelection().getConjureWeekSelection()),
        durationsInMinutes: reservedTimes.map((rt) => rt.getDurationInMinutes())
    })

    const saveReservedTimes = (formValues: ReservedTimeFormValues) => {
        const reservedTimeTransforms = formValues.reservedTimeIds.map((reservedTimeId, index) =>
            IScheduleTransform.reservedTimeTransform({
                newReservedTime: {
                    ...reservedTimes[index].getConjureObject(),
                    title: formValues.title[index],
                    teacherIds: formValues.teacherIds.get(reservedTimeId)!,
                    studentGroupIds: formValues.studentGroupIds.get(reservedTimeId)!,
                    roomIds: formValues.roomIds.get(reservedTimeId)!,
                    weekSelection: formValues.weekSelections[index],
                    durationInMinutes: formValues.durationsInMinutes[index]
                }
            })
        )
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(reservedTimeTransforms)))
    }

    return (
        <div style={{ overflow: 'auto', maxHeight: '100%', padding: '20px' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Form
                    form={form}
                    initialValues={formValuesFromAccessors(reservedTimes)}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: '700px', width: '100%' }}
                    onFinish={saveReservedTimes}
                    labelWrap
                    colon={false}
                >
                    <Form.List name="reservedTimeIds">
                        {(fields) =>
                            fields.map((field) => (
                                // biome-ignore lint/correctness/useJsxKeyInIterable: can be removed when we switch form components
                                <Form.Item hidden {...field}>
                                    <Input />
                                </Form.Item>
                            ))
                        }
                    </Form.List>
                    <Form.Item name="title" label={toTranslate('Titel')}>
                        <BulkInput
                            multiplicityMessage={toTranslate('Välj en enskild reserverad tid för att ändra title')}
                        />
                    </Form.Item>
                    <Form.Item name="teacherIds" label={toTranslate('Lärare')}>
                        <ReservedTimeTeacherSelector />
                    </Form.Item>
                    <Form.Item name="studentGroupIds" label={toTranslate('Elevgrupper')}>
                        <ReservedTimeStudentGroupSelector />
                    </Form.Item>
                    <Form.Item name="roomIds" label={toTranslate('Salar')}>
                        <ReservedTimeRoomSelector />
                    </Form.Item>
                    <Form.Item name="weekSelections" label={t('Weeks')}>
                        <BulkGeneralInput<IWeekSelection>
                            renderInput={(value, onChange) => (
                                <WeekSelectionInput value={value} onChange={onChange} layout="vertical" />
                            )}
                            multiplicityMessage={toTranslate(
                                'Välj en enskild reserverad tid för att ändra veckoinställningar'
                            )}
                        />
                    </Form.Item>
                    <Form.Item name="durationsInMinutes" label={t('Duration')}>
                        <BulkGeneralInput<number>
                            renderInput={(value, onChange) => <MinutesInput value={value} onChange={onChange} />}
                            multiplicityMessage={toTranslate('Välj en enskild reserverad tid för att ändra längd.')}
                        />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8 }}>
                        <Button type="primary" htmlType="submit">
                            {t('Save')}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}
