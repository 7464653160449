import { all, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { ApplicationState } from '../index'
import { ScheduleSelectionActionTypes, ScheduleSelectionState, ScheduleSelector } from './types'
import { isScheduleSelectorValid } from '../../utils/scheduleUtils'
import { ScheduleState } from '../schedule/types'
import { applyAllTransforms } from '../../utils/scheduleTransforms'
import { ScheduleAccessor } from '../../schedule-access/scheduleAccessWrappers'
import { newScheduleSelectorsLoadedDueToScheduleUpdate } from './actions'
import { ScheduleId } from '../../commonTypes'

const scheduleSelectorKeyForScheduleId = (scheduleId: ScheduleId) => `${scheduleId}/scheduleSelectors`

// Remove schedule selectors that are no longer valid
const withoutInvalidSchedules = (scheduleSelectors: ScheduleSelector[], localSchedule: ScheduleAccessor) =>
    scheduleSelectors.filter((ss) => isScheduleSelectorValid(localSchedule, ss))

// Maybe do one of two things:
// 1. If new schedule was loaded: Replace schedule selection with last known schedule selection for that schedule from
//    local storage
// 2. Remove schedule selectors that has become invalid after the schedule update.
function* adjustScheduleSelectionAfterScheduleUpdate(): any {
    const scheduleSelection: ScheduleSelectionState = yield select((state: ApplicationState) => state.scheduleSelection)
    const scheduleState: ScheduleState = yield select((state: ApplicationState) => state.schedule)
    const localSchedule = applyAllTransforms(scheduleState.schedule, scheduleState.pendingTransforms)
    const scheduleId = localSchedule.getScheduleId()

    // Has schedule id changed?
    if (scheduleSelection.scheduleId !== scheduleId) {
        // Load new schedule state from local storage
        console.log('[schedule selectors] Schedule id updated loading last used schedule selectors from local storage')
        // Load schedule selection from local storage
        const lastUsedScheduleSelectorsJson = window.localStorage.getItem(scheduleSelectorKeyForScheduleId(scheduleId))
        const lastUsedScheduleSelectors =
            lastUsedScheduleSelectorsJson === null ? [] : JSON.parse(lastUsedScheduleSelectorsJson)

        // Remove invalid schedule selectors
        const loadedScheduleSelection = withoutInvalidSchedules(lastUsedScheduleSelectors, localSchedule)

        // Set schedule selectors
        yield put(newScheduleSelectorsLoadedDueToScheduleUpdate(scheduleId, loadedScheduleSelection))
    } else {
        const filteredScheduleSelection = withoutInvalidSchedules(scheduleSelection.selectedSchedules, localSchedule)
        if (filteredScheduleSelection.length !== scheduleSelection.selectedSchedules.length) {
            console.log('[schedule selectors] removing some invalid schedule selector(s)')
            yield put(newScheduleSelectorsLoadedDueToScheduleUpdate(scheduleId, filteredScheduleSelection))
        }
    }
}

// Flush out current schedule selection to local storage
function* flushCurrentScheduleSelectionToLocalStorage(): any {
    const scheduleSelection: ScheduleSelectionState = yield select((state: ApplicationState) => state.scheduleSelection)
    const selectedSchedulesJson = JSON.stringify(scheduleSelection.selectedSchedules)
    window.localStorage.setItem(scheduleSelectorKeyForScheduleId(scheduleSelection.scheduleId), selectedSchedulesJson)
    console.log(`[schedule selectors] updated local storage with ${selectedSchedulesJson}`)
}

// Make sure schedule selectors are up-to-date with current schedule
function* watchUpdatesSchedule() {
    yield takeEvery((action: any) => action.type.startsWith('schedule/'), adjustScheduleSelectionAfterScheduleUpdate)
}

// Make sure user updates are flushed out to local storage
function* watchUserAdditionsAndRemovals() {
    yield takeLatest(
        [
            ScheduleSelectionActionTypes.USER_ADDED_SCHEDULE_SELECTOR,
            ScheduleSelectionActionTypes.USER_REMOVED_SCHEDULE_SELECTOR
        ],
        flushCurrentScheduleSelectionToLocalStorage
    )
}

function* scheduleSelectionSaga() {
    yield all([fork(watchUpdatesSchedule), fork(watchUserAdditionsAndRemovals)])
}

export default scheduleSelectionSaga
